export enum ArticlesProperty {
  id = '_id',
  title = 'title',
  subtitle = 'subtitle',
  text = 'text',
  plainText = 'plainText',
  category = 'category',
  videoPost = 'video_post',
  textPost = 'text_post',
  photoPost = 'photo_post',
  surveyPost = 'survey_post',
  time = 'time',
  date = 'date',
  create_date = 'create_date',
  request_date = 'request_date',
  editor = 'editor',
  editors = 'editors',
  display_name = 'display_name',
  url_title = 'url_title',
  priority = 'priority',
  published = 'published',
  draft = 'draft',
  image = 'image',
  image_list = 'image_list',
  tags = 'tags',
  category_articles = 'category_articles',
  notification = 'notification',
  analytics = 'analytics',
  total_views = 'total_views',
  icon_facebook = 'icon_facebook',
  icon_gallery = 'icon_gallery',
  icon_instagram = 'icon_instagram',
  icon_photo = 'icon_photo',
  icon_sofascore = 'icon_sofascore',
  icon_survey = 'icon_survey',
  icon_tiktok = 'icon_tiktok',
  icon_video = 'icon_video',
  icon_x = 'icon_x',
  icon_youtube = 'icon_youtube',
}
