import {ChangeDetectorRef, Component} from '@angular/core';
import {ActivatedRoute, Router, RouterLink, RouterOutlet} from "@angular/router";
import {NgClass} from "@angular/common";
import {ShowService} from "../../../../../services/show.services";

@Component({
    selector: 'app-episode',
    imports: [
        RouterOutlet,
        RouterLink,
        NgClass
    ],
    templateUrl: './episode.component.html',
    styleUrl: './episode.component.scss'
})
export class EpisodeComponent {

  isQA!: boolean | undefined;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private showService: ShowService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    const chat = this.router.url.split('/').pop();
    if (chat === 'q&a'){
      this.isQA = true
    }
  }

  selection(selection: string) {
    this.isQA = selection === 'qa';
    this.cdr.detectChanges()
  }

}
