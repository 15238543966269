<mat-drawer-container class="example-container" [hasBackdrop]="true">
  <mat-drawer #drawer [mode]="'over'">
    <app-drawer (closeMenu)="drawer.toggle()"></app-drawer>
  </mat-drawer>

  <mat-drawer-content class="example-content">
    <app-header (openMenu)="drawer.toggle()"></app-header>
    <router-outlet></router-outlet>
    <app-footer> </app-footer>
  </mat-drawer-content>
</mat-drawer-container>
