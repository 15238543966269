import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {formatDate, NgIf, NgStyle} from "@angular/common";
import {ArticlesProperty} from "../../../models/articles-property.enum";

@Component({
  selector: 'app-article-information',
  imports: [
    NgStyle,
    NgIf
  ],
  templateUrl: './article-information.component.html',
  standalone: true,
  styleUrl: './article-information.component.scss'
})
export class ArticleInformationComponent implements OnChanges{
  @Input() views!: number | undefined;
  @Input() date!: string;
  @Input() time!: string;
  @Input() color!: string;
  @Input() editor!: string;
  @Input() icons!: { [key: string]: boolean };
  @Input() permission!: string;
  @Input() colorScheme: 'light' | 'dark' = 'light';

  constructor() {
  }


  ngOnChanges(changes: SimpleChanges): void {
  }
}
