import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {delayWhen, Observable, retryWhen, Subject, switchMap, timer} from 'rxjs';
import {environment} from "../../environments/environment";
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {catchError, map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class QuizService {
  private readonly backSocket: string = `${environment.backSocket}`;
  private readonly backUrl: string = `${environment.backendUrl}`;

  private socketMap = new Map<string, WebSocketSubject<any>>();

  constructor(
    private http: HttpClient
  ) {
  }

  public postAnswerOption(id: string, answer: any): Observable<any> {
    return this.http.post<any>(`${this.backUrl}/quiz/team/${id}`, answer);
  }

  public postDisplayState(state: any): Observable<any> {
    return this.http.post<any>(`${this.backUrl}/quiz/display`, state);
  }

  teamOneUpdates(id: string): Observable<any> {
    if (!this.socketMap.has(id)) {
      this.socketMap.set(id, webSocket(`${this.backSocket}/quiz/team/1`));
    }

    this.socketMap.get(id)?.subscribe({
      next: msg => console.log('message received: ' + msg), // Called whenever there is a message from the server.
      error: err => console.log(err), // Called if at any point WebSocket API signals some kind of error.
      complete: () => console.log('complete') // Called when connection is closed (for whatever reason).
    })

    // @ts-ignore
    return this.socketMap.get(id).asObservable(); // No need to map, directly return the observable.

  }

  teamTwoUpdates(id: string): Observable<any> {
    if (!this.socketMap.has(id)) {
      this.socketMap.set(id, webSocket(`${this.backSocket}/quiz/team/2`));
    }

    this.socketMap.get(id)?.subscribe({
      next: msg => console.log('message received: ' + msg), // Called whenever there is a message from the server.
      error: err => console.log(err), // Called if at any point WebSocket API signals some kind of error.
      complete: () => console.log('complete') // Called when connection is closed (for whatever reason).
    })

    // @ts-ignore
    return this.socketMap.get(id).asObservable(); // No need to map, directly return the observable.

  }
  socketDisplayState(): Observable<any> {
    if (!this.socketMap.has('display')) {
      this.socketMap.set('display', webSocket(`${this.backSocket}/quiz/display`));
    }

    this.socketMap.get('display')?.subscribe({
      next: msg => console.log('message received: ' + msg), // Called whenever there is a message from the server.
      error: err => console.log(err), // Called if at any point WebSocket API signals some kind of error.
      complete: () => console.log('complete') // Called when connection is closed (for whatever reason).
    })

    // @ts-ignore
    return this.socketMap.get('display')?.asObservable().pipe(
      retryWhen(errors =>
        errors.pipe(
          // Log the error
          switchMap(error => {
            console.error('WebSocket error, retrying...', error);
            return timer(3000); // Retry after a 3-second delay
          })
        )
      )
    );
  }


  listenForEventUpdates(id: string): Observable<any> {
    if (!this.socketMap.has(id)) {
      this.socketMap.set(id, webSocket(`${this.backSocket}/quiz/team/${id}`));
    }

    this.socketMap.get(id)?.subscribe({
      next: msg => console.log('message received: ' + msg), // Called whenever there is a message from the server.
      error: err => console.log(err), // Called if at any point WebSocket API signals some kind of error.
      complete: () => console.log('complete') // Called when connection is closed (for whatever reason).
    })

    // @ts-ignore
    return this.socketMap.get(id)?.asObservable().pipe(
      retryWhen(errors =>
        errors.pipe(
          // Log the error
          switchMap(error => {
            console.error('WebSocket error, retrying...', error);
            return timer(3000); // Retry after a 3-second delay
          })
        )
      )
    );
  }
}
