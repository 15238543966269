import {Component} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {HayatBaHeaderComponent} from "../../common/hayat-ba-header/hayat-ba-header.component";

@Component({
  selector: 'app-hayat.ba',
  imports: [
    HayatBaHeaderComponent,
    RouterOutlet,
  ],
  templateUrl: './hayat.ba.component.html',
  standalone: true,
  styleUrl: './hayat.ba.component.scss'
})
export class HayatBaComponent {
}
