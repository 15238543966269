import {Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {CategoriesProperty} from "../../../models/categories-property.enum";
import {Categories} from "../../../models/categories.model";
import {CategoriesService} from "../../../services/categories.services";
import {AsyncPipe, DOCUMENT, isPlatformBrowser, NgForOf, NgIf, NgStyle} from "@angular/common";
import {forkJoin} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Articles} from "../../../models/articles.model";
import {HomeCategoryComponent} from "../home-category/home-category.component";
import {HomeTagsComponent} from "./home-tags/home-tags.component";
import {ArticlesService} from "../../../services/articles.service";
import {ArticleCardComponent} from "../article-card/article-card.component";
import {ThemedTagComponent} from "../themed-tag/themed-tag.component";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  imports: [
    HomeCategoryComponent,
    NgForOf,
    NgIf,
    HomeTagsComponent,
    AsyncPipe,
    ArticleCardComponent,
    ThemedTagComponent,
    NgStyle
  ],
  standalone: true,
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  categoryWidth: number = 400; // Default width
  @HostListener('window:resize', [])
  calculateCategoryWidth(screenWidth: number) {
    if (screenWidth > 400) {
      const columnWidth = 400;
      const gap = 10;

      // Calculate max columns that can fit
      const numColumns = Math.floor(screenWidth / (columnWidth + gap));

      // Calculate total width
      this.categoryWidth = (numColumns * columnWidth) + ((numColumns - 1) * gap) + 20;
    } else {
      this.categoryWidth = innerWidth
    }
  }

  numberOfColumns: number = 3;


  public categoriesProperty = CategoriesProperty;

  categories: Categories[] = [];
  articles: Articles[][] = [];
  highlight: Articles[] = [];
  hot: Articles[] = [];
  public dataLoaded = false; // Flag for when data is ready

  protected categoryHot: Categories = {
    [CategoriesProperty.name]: 'Najčitanije',
  }
  protected categoryHighlight: Categories = {
    [CategoriesProperty.name]: 'Izdvojeno',
  }

  articleSubscription: any;
  highlightSubscription: any;


  constructor(
    // @ts-ignore
    @Inject(DOCUMENT) private doc,
    @Inject(PLATFORM_ID) private platformID: Object,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private renderer2: Renderer2,
    private categoriesService: CategoriesService,
    private articlesService: ArticlesService,
  ) {
    if (isPlatformBrowser(this.platformID)) {
      const screenWidth = window.innerWidth;
      this.calculateCategoryWidth(screenWidth);

      if (screenWidth < 400) {
        this.numberOfColumns = 1;
      } else if (screenWidth >= 1800) {
        this.numberOfColumns = Math.floor(screenWidth / 400);
      } else {
        this.numberOfColumns = Math.min(Math.floor(screenWidth / 400), 3);
      }
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0); // Scroll to the top on route navigation
        }
      });


      forkJoin({
        // hot: this.articlesService.getArticlesHot(),
        highlight: this.articlesService.getArticleHighlight(),
        main: this.articlesService.getArticlesMainPage(),
        categories: this.categoriesService.getCategories()
      }).subscribe({
        next: (results) => {
          this.highlight = results.highlight
          // this.hot = results.hot
          this.articles = results.main
          this.categories = results.categories
          this.dataLoaded = true; // Now render HTML
        },
        error: (error) => {
          console.error('Error fetching data:', error);
          this.dataLoaded = false; // Now render HTML
        }
      });
    }
  }


  getNumberArray(length: number): number[] {
    return Array.from({length}, (_, i) => i);
  }
}
