<div class="options">
  <video autoplay muted loop>
    <source src="assets/loop_logo.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>

  <button class="option"
          [ngClass]="{'selected': answer === 1}"
          (click)="submitAnswer(1)">1</button>
  <button class="option"
          [ngClass]="{'selected': answer === 2}"
          (click)="submitAnswer(2)">2</button>
  <button class="option"
          [ngClass]="{'selected': answer === 3}"
          (click)="submitAnswer(3)">3</button>
  <button class="option"
          [ngClass]="{'selected': answer === 4}"
          (click)="submitAnswer(4)">4</button>
</div>
