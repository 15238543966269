import {
  ChangeDetectorRef,
  Component,
  ElementRef, Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import {CommonModule, formatDate, isPlatformBrowser, NgClass, NgOptimizedImage} from "@angular/common";
import {Articles} from "../../../models/articles.model";
import {ArticlesProperty} from "../../../models/articles-property.enum";
import {ArticleInformationComponent} from "../article-information/article-information.component";
import {RouterLink} from "@angular/router";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ArticlesService} from "../../../services/articles.service";

@Component({
  selector: 'app-article-card',
  imports: [
    NgOptimizedImage,
    ArticleInformationComponent,
    RouterLink,
    NgClass,
    CommonModule, // ✅ Use this instead of BrowserModule
  ],
  templateUrl: './article-card.component.html',
  standalone: true,
  styleUrl: './article-card.component.scss',
  animations: [
    trigger('fadeInUp', [
      state('hidden', style({opacity: 0, transform: 'translateY(60px)'})),
      state('visible', style({opacity: 1, transform: 'translateY(0px)'})),
      transition('hidden => visible', [
        animate('0.5s ease-out')
      ], {delay: '{{ delay }}s'}) // Default delay 0.2s
    ])
  ]
})
export class ArticleCardComponent implements OnChanges, OnInit {
  @Input() article: Articles | null = null;
  @Input() articleType: number | null = null;
  @Input() source: string | null = null;

  date!: string;
  time!: string;
  icons: {};

  isVisible: boolean = false; // 👈 Controls animation trigger
  animationDelay: string = '0.5'; // 👈 Controls delay for animation
  private observer!: IntersectionObserver;

  constructor(
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private articlesService: ArticlesService,
    @Inject(PLATFORM_ID) private platformID: Object
  ) {
    this.icons = {};
  }

  calculateAnimationDelay() {
    const rect = this.el.nativeElement.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    // Normalize delay based on position in viewport (0s to 1s max delay)
    this.animationDelay = (Math.min(rect.top / viewportHeight, 1)).toFixed(2); // Max 1s delay
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.article) {
      // Delay to make sure animation triggers correctly
      setTimeout(() => {
        this.calculateAnimationDelay(); // Calculate delay based on position
        // this.isVisible = true;
        this.cdr.detectChanges();
      }, 50);
      // Process the article data normally
      const offset = new Date().getTimezoneOffset();
      const timezone = offset / 60;

      if (this.article[ArticlesProperty.create_date] !== undefined) {
        this.date = formatDate(this.article[ArticlesProperty.create_date], 'dd/MM/yyyy', 'en-GB', 'UTC+' + timezone);
        this.time = formatDate(this.article[ArticlesProperty.create_date], 'HH:mm', 'en-GB', 'UTC+' + timezone);
      } else {
        this.date = '27/06/2000';
        this.time = '12:05';
      }

      this.icons = {
        videoIcon: this.article?.icon_video,
        photoIcon: this.article?.icon_photo,
        galleryIcon: this.article?.icon_gallery,
        instagramIcon: this.article?.icon_instagram,
        facebookIcon: this.article?.icon_facebook,
        xIcon: this.article?.icon_x,
        tiktokIcon: this.article?.icon_tiktok,
        sofascoreIcon: this.article?.icon_sofascore,
        youtubeIcon: this.article?.icon_youtube,
        surveyIcon: this.article?.icon_survey,
      };
      if (this.articleType === null) {
        if (this.article[ArticlesProperty.textPost]) {
          this.articleType = 1;
        } else if (this.article[ArticlesProperty.photoPost]) {
          this.articleType = 2;
        } else if (this.article[ArticlesProperty.videoPost]) {
          this.articleType = 3;
        }
      }

    }

    if (this.cdr) {
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      this.observeElement();
    }
  }

  observeElement() {
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !this.isVisible) {
          this.calculateAnimationDelay(); // Calculate delay when in view
          this.isVisible = true;
          this.cdr.detectChanges();
          this.observer.disconnect(); // Stop observing after animation starts
        }
      },
      {threshold: 0.5} // Trigger when at least 20% of the element is visible
    );

    this.observer.observe(this.el.nativeElement);
  }


  isClicked = false; // Flag to prevent multiple clicks

  updateStatistics(article_id: string | undefined) {
    if (this.isClicked || this.source == null) {
      return; // Prevent multiple clicks
    }

    this.isClicked = true; // Mark as clicked to prevent further clicks

    this.articlesService.articleSource(article_id, this.source).subscribe(response => {
      console.log('Statistics updated:', response);
    }, error => {
      console.error('Error updating statistics:', error);
    });
  }


  protected readonly articlesProperty = ArticlesProperty;
}
