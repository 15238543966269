import {Component} from '@angular/core';
import {HayatWelcomeComponent} from "./hayat-welcome/hayat-welcome.component";
import {HayatInfoComponent} from "./hayat-info/hayat-info.component";

@Component({
  selector: 'app-hayat',
  imports: [
    HayatWelcomeComponent,
    HayatInfoComponent,
  ],
  templateUrl: './hayat.component.html',
  standalone: true,
  styleUrl: './hayat.component.scss'
})
export class HayatComponent {
  public dataInfo!: {
    title: string;
    text: string;
    image: string,
    type: number,
    colorCode1: string,
    colorCode2: string,
  }[];

  constructor() {
    this.dataInfo = [
      {
        title: 'Najpoznatiji bh. brend!',
        text: 'Hayat TV osnovana je 1991. godine te je neprekidnim radom osvojila ne samo bosanskohercegovački medijski prostor, nego i širi, te je postala programski prepoznatljiva. Pripada grupi televizijskih kuća opšteg programskog opredjeljenja, koje su orijentisane ka širem auditoriju, što se odražava u programima informativnog, zabavnog, muzičkog, sportskog, dokumentarnog, dječijeg, vjerskog i kulturnog karaktera. Nakon 33 godine uspješnog rada, Hayat je najprepoznatljiviji bh. TV-brend. Danas emitujemo SEDAM TV-kanala, od kojih PET možete pratiti putem satelita Eutelsat 16A.',
        image: 'assets/hayat-logo.webp',
        type: 1,
        colorCode1: '#1a2f5a',
        colorCode2: '#1a2f5a'
      },
      {
        title: '7 TV kanala',
        text: 'U srcu vašeg doma, u trideset trećoj godini emitovanja, s ponosom vam predstavljamo SEDAM TV-kanala.\n' +
          'Dobro došli u novu eru zabave i stila s Hayatom!\n',
        image: 'assets/channels/7channels.png',
        type: 0,
        colorCode1: '#1a2f5a',
        colorCode2: '#1a2f5a'
      },
      {
        title: 'Hayat.ba',
        text: 'Najnovije vijesti o BiH, regiji i svijetu na jednom mjestu!',
        image: 'assets/hayat.ba - logo.png',
        type: 1,
        colorCode1: '#1a2f5a',
        colorCode2: '#1a2f5a'
      },
      {
        title: 'Hayat Play',
        text: 'Sve Hayatove kanale možete gledati UŽIVO, bilo kad, bilo gdje, na svom mobitelu, tabletu, računaru i smart TV-uređaju putem Hayat aplikacije ili na portalu Hayat.ba.',
        image: 'assets/hayatplay - logo.png',
        type: 0,
        colorCode1: '#1a2f5a',
        colorCode2: '#1a2f5a'
      },
      {
        title: 'Hayat 2',
        text: 'U procesu rebrandinga i usklađivanja vizuelnog identiteta dodatnih, tematskih kanala, kanal Hayat PLUS mijenja ime u Hayat 2. \n' +
          '\n' +
          'Ova promjena simbolično povezuje kanal i domovinu, ciljajući na dijasporu. Kanal Hayat 2 posebno je dizajniran za iseljenike, nudeći im aktuelne programe domaće produkcije uz odabrane najbolje sadržaje iz bogate arhive Hayat TV-a. \n',
        image: 'assets/channels/plus.png',
        type: 1,
        colorCode1: '#1a2f5a',
        colorCode2: '#0063a8'
      },
      {
        title: 'Stil i Život',
        text: 'Tematski televizijski kanal Stil i Život usmjeren je prvenstveno ka ženama, a potom i cijeloj porodici. Kanal nudi jedinstvenu kombinaciju programskog sadržaja koji nudi životne priče, kulinarske programe, zabavu i kulturu. Kanal je posvećen predstavljanju i slavljenju bh. žene u svim aspektima njenog života, nezavisno od dobi. S više od 80% sadržaja domaće produkcije, kanal istražuje i prikazuje bogatstvo bosanskohercegovačke kulture i identiteta kroz žensku perspektivu.\n' +
          '\n' +
          'Fokus kanala je na pričama koje inspirišu, od ličnih uspjeha do svakodnevnih izazova, ističući snagu i raznolikost bh. žena. Kulinarski segmenti predstavljaju bogatstvo bh. kuhinje, nudeći tradicionalne recepte i savremene kulinarske trendove, dok zabavni programi nude raznolikost sadržaja, od muzike do umjetnosti.\n',
        image: 'assets/channels/stilizivot.png',
        type: 1,
        colorCode1: '#03371e',
        colorCode2: '#80bf41'
      },
      {
        title: 'Hayatovci',
        text: 'U sklopu procesa rebrandinga dodatnih kanala Hayat TV-a, i dječiji kanal Hayatovci doživljava promjene unatoč tome što zadržava svoju originalnu programsku šemu. Ključna promjena za Hayatovce je uvođenje novog logotipa. Ova promjena logotipa predstavlja vizuelno osvježenje i usklađivanje s ukupnim brandingom dodatnih Hayatovih kanala. Iako se izgled kanala mijenja, Hayatovci ostaju posvećeni svojoj osnovnoj misiji - pružanju edukativnog i zabavnog sadržaja namijenjenog djeci, čime nastavljaju biti pouzdan izvor kvalitetne dječije zabave i edukacije.',
          image: 'assets/channels/hayatovci.png',
        type: 1,
        colorCode1: '#2e2c2c',
        colorCode2: '#bbbbbb'
      },
      {
        title: 'Music Box',
        text: 'U procesu rebrandinga muzički kanal Hayat MUSIC mijenja naziv u Hayat Music Box. Ovaj korak je dio strategije ujedinjavanja različitih muzičkih kanala pod jednim brendom. Ovim rebrandingom Hayat Music Box teži jačanju svog identiteta i pozicije na tržištu kao centralnog mjesta za ljubitelje bosanskohercegovačke pop-muzike. Nakon rebrandinga emitujemo isključivo muziku bosanskohercegovačkih pop-autora i to pod sloganom “Samo naše”.\\n\',',
          image: 'assets/channels/music.png',
        type: 1,
        colorCode1: '#a4077d',
        colorCode2: '#e47198'
      },
      {
        title: 'Folk Box',
        text: 'U procesu rebrandinga muzički kanal Hayat FOLK mijenja naziv u Hayat Folk Box. Cilj ove promjene je stvaranje jedinstvenog vizuelnog identiteta koji će ujediniti sve muzičke kanale pod imenom Box. Unatoč promjeni imena, kanal ostaje vjeran svojoj prvobitnoj misiji i nastavlja sa stopostotnom posvećenošću promovisanju autora iz Bosne i Hercegovine, čime potvrđuje svoju kontinuiranu podršku domaćoj muzičkoj sceni. Nakon rebrandinga emitujemo isključivo narodnu muziku bosanskohercegovačkih autora i to pod sloganom “Samo naše”.',
        image: 'assets/channels/folk.png',
        type: 1,
        colorCode1: '#862100',
        colorCode2: '#ed7b0c'
      },
      {
        title: 'Love Box',
        text: 'Hayat Love box je tematski muzički kanal namijenjen romantičarima, koji ujedinjuje domaću, regionalnu i stranu muziku u harmoničnom miksu od 85% regionalnih i 15% međunarodnih hitova. Fokusiran na laganije melodije, balade i ljubavne pjesme, kanal nudi pažljivo odabrane muzičke blokove koji odražavaju srž romantične muzike. Svojim jedinstvenim pristupom kanal pruža savršenu muzičku pozadinu za sve one koji cijene emotivne i melodične ritmove, nudeći muzičko iskustvo koje prelazi granice jezika i kulture. Muzički blokovi pažljivo su pripremljeni kako bi pružili nezaboravno iskustvo, nudeći gledaocima priliku da uživaju u omiljenim melodijama i otkrivaju nove muzičke bisere.',
        image: 'assets/channels/lovebox.png',
        type: 1,

        colorCode1: '#c40f41',
        colorCode2: '#e47198'
      },
    ];
  }
}
