@if (article) {
  <a class="article-card-container"
     [@fadeInUp]="{ value: isVisible ? 'visible' : 'hidden', params: { delay: animationDelay } }"
     [routerLink]="['/news/article', article?.url_title, article?._id]"
     (click)="updateStatistics(article?._id)"
     [attr.href]="'../news/article/' + article?.url_title + '/' + article?._id"
  >
    <div class="article-card"
         [ngClass]="{
    'type-text': articleType === 1,
    'type-photo': articleType === 2,
    'type-video': articleType === 3
  }"
    >
      @if (articleType !== 1) {
        <div class="media">
          <img
            [src]="article?.image_list?.[0]?.url"
            alt=""
            loading="lazy"
          >
        </div>
      }
      <div class="text">
        <h1>{{ article?.title }}</h1>
        @if (articleType !== 2) {
          <h2>{{ article?.subtitle }}</h2>
        }
        <app-article-information [views]="article?.total_views" [date]="date" [time]="time"
                                 [icons]="icons"></app-article-information>
      </div>
    </div>
  </a>
} @else {
  <div class="skeleton article-card"
       [ngClass]="{
    'type-text': articleType === 1,
    'type-photo': articleType === 2,
    'type-video': articleType === 3
  }"
  >
  </div>
}
