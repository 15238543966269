import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ArticleComponent} from "../../hayat.ba/article/article.component";
import {Articles} from "../../../models/articles.model";
import {ArticlesService} from "../../../services/articles.service";
import {ResolverResponse} from "../../../constants/resolver-response.constants";
import {UploadComponent} from "../upload.component";

@Component({
  selector: 'app-upload-container',
  templateUrl: './upload-container.component.html',
  standalone: true,
  imports: [
    ArticleComponent,
    UploadComponent
  ]
})
export class UploadContainerComponent {

  public options!: Articles;

  articleSubscription: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private articlesService: ArticlesService,
  ) {
  }


  ngOnInit(): void {
    this.articleSubscription = this.activatedRoute.data.subscribe((response: any) => {
      this.options = response[ResolverResponse.ARTICLE];
    });
  };

  ngOnDestroy():void {
    this.articleSubscription.unsubscribe();
  };
}
