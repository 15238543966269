import { Component } from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-about-hayat',
    imports: [
        RouterLink
    ],
    templateUrl: './about-hayat.component.html',
    styleUrl: './about-hayat.component.scss'
})
export class AboutHayatComponent {

}
