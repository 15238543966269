import {Component, OnInit} from '@angular/core';
import {ShowService} from "../../../../../../services/show.services";
import {Show} from "../../../../../../models/show.model";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Episode} from "../../../../../../models/episode.model";

@Component({
    selector: 'app-episode-about',
    imports: [],
    templateUrl: './episode-about.component.html',
    styleUrl: './episode-about.component.scss'
})
export class EpisodeAboutComponent implements OnInit{

  show: Show | null | undefined;
  episode: Episode | null | undefined;
  order: string | undefined
  constructor(
    private showService: ShowService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Handle route change here
        console.log('Route changed:', event.url);
        this.order = event.url.split('/').pop();
        //this.order = this.activatedRoute.snapshot.paramMap.get('order');
        this.episode = this.show?.episodes.find((episode: any) => episode.order.toString() === this.order);
      }
    });
  }

  ngOnInit(): void {
    this.show = this.showService.getStoredShow()
    this.order = this.router.url.split('/').pop();
    //this.order = this.activatedRoute.snapshot.paramMap.get('order');
    this.episode = this.show?.episodes.find((episode: any) => episode.order.toString() === this.order);

  }
}
