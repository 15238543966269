import { Component } from '@angular/core';

@Component({
    selector: 'app-terms-of-use',
    imports: [],
    templateUrl: './terms-of-use.component.html',
    styleUrl: './terms-of-use.component.scss'
})
export class TermsOfUseComponent {

}
