import {Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {ResolverResponse} from "../../constants/resolver-response.constants";
import {ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet} from "@angular/router";
import {Categories} from "../../models/categories.model";
import {isPlatformBrowser, NgIf} from "@angular/common";
import {HayatBaHeaderComponent} from "../../common/hayat-ba-header/hayat-ba-header.component";
import {CategoriesService} from "../../services/categories.services";
import {FooterComponent} from "../../common/footer/footer.component";
import {response} from "express";
import {HeaderComponent} from "../../common/header/header.component";
import {MatDrawer, MatDrawerContainer, MatDrawerContent} from "@angular/material/sidenav";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {DrawerComponent} from "../../common/drawer/drawer.component";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  imports: [
    RouterOutlet,
    FooterComponent,
    HeaderComponent,
    MatDrawer,
    MatDrawerContainer,
    MatDrawerContent,
    MatIcon,
    MatIconButton,
    NgIf,
    RouterLink,
    DrawerComponent,
  ],
  standalone: true,
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  public categories: Categories[] = [];
  currentRoute: string = '';

  categoriesSubscription: any;

  constructor(
    @Inject(PLATFORM_ID)private platformID: Object,
    private activatedRoute: ActivatedRoute,
    private categoriesService: CategoriesService,
    private router: Router,
    private renderer: Renderer2,
  ) {
    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
    });
  }


  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      const scriptElement1 = this.renderer.createElement('script');
      const scriptContent = `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1732793710895-0'); });`;
      scriptElement1.text = scriptContent;
      this.renderer.appendChild(document.body, scriptElement1);
    }
  }


}
