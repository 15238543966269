import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions, MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";

@Component({
    selector: 'app-survey-confirmation-dialog',
    templateUrl: './survey-confirmation-dialog.component.html',
    imports: [
        MatDialogContent,
        MatDialogTitle,
        MatDialogActions,
        MatDialogClose,
        MatButtonModule
    ],
    styleUrls: ['./survey-confirmation-dialog.component.scss']
})
export class SurveyConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SurveyConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string }
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
