<!-- Regular Header -->
@if (!showToolbar) {
  <div class="header"
       [ngClass]="{ 'red': currentRoute.startsWith('/play') }"
    >
    <button class="button icon-button" [routerLink]="'/search'"
            [ngClass]="{ 'faded': !currentRoute.startsWith('/search')}"
    >
      <i class="fa-solid fa-magnifying-glass"></i>
    </button>

    <button class="button hayat-ba" aria-label="hayat.ba" [routerLink]="'/news'"
            [ngClass]="{ 'faded': !currentRoute.startsWith('/news')}">
      <img src="assets/hayat-logo.webp" alt="Hayat Logo">
      <p>.ba</p>
    </button>

    <button class="button hayat" aria-label="hayat media" [routerLink]="'/media'"
            [ngClass]="{ 'faded': !currentRoute.startsWith('/media')}">
      <img src="assets/hayat-logo.webp" alt="Hayat Logo">
    </button>

    <button class="button hayat-play" aria-label="hayat play" [routerLink]="'/play'"
            [ngClass]="{ 'faded': !currentRoute.startsWith('/play')}">
      <img src="assets/hayat-logo.webp" alt="Hayat Logo">
      <p>Play</p>
    </button>

    <button class="button icon-button" [routerLink]="'/account'"
            [ngClass]="{ 'faded': !currentRoute.startsWith('/account')}">
      <i class="fa-solid fa-user"></i>
    </button>
  </div>
} @else {
  <div class="toolbar-header"
       [ngClass]="{'toolbar-header-show': showToolbar }"
  >
    <button class="menu-button" (click)="toggleMenu()">☰</button>
    <div class="logo">

      @if (currentRoute?.startsWith('/media')) {
        <button class="button hayat-ba" aria-label="hayat">
          <img src="../../../assets/hayat-logo.webp" alt="Hayat Logo">
        </button>
      } @else if (currentRoute?.startsWith('/play')) {
        <button class="button hayat-ba" aria-label="hayat-play">
          <p style="color: #1a2f5a">Play</p>
          <img src="../../../assets/hayat-logo.webp" alt="Hayat Logo">
          <p>Play</p>
        </button>
      } @else {
        <button class="button hayat-ba" aria-label="hayat.ba">
          <p style="color: #1a2f5a">.ba</p>
          <img src="../../../assets/hayat-logo.webp" alt="Hayat Logo">
          <p>.ba</p>
        </button>
      }
    </div>
    @if (currentRoute?.startsWith('/article')) {
      <button class="menu-button">
        <i class="fa-solid fa-share-nodes"></i>
      </button>
    } @else {
      <button class="menu-button">
        <i class="fa-solid fa-user"></i>
      </button>
    }
  </div>
}
