import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {NgClass} from "@angular/common";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatButton} from "@angular/material/button";
import {Subscription} from "rxjs";
import {MatNestedTreeNode, MatTree, MatTreeNode} from "@angular/material/tree";
import {ShowService} from "../../../../services/show.services";
import {Articles} from "../../../../models/articles.model";
import {Show} from "../../../../models/show.model";
import {ArticlesProperty} from "../../../../models/articles-property.enum";
import {Episode} from "../../../../models/episode.model";
import {ShowEpisodesComponent} from "./show-episodes/show-episodes.component";
import {ShowEpisodesInfoComponent} from "./show-episodes-info/show-episodes-info.component";

@Component({
    selector: 'app-one-show',
    imports: [
        RouterOutlet,
        NgClass,
        MatFormField,
        MatLabel,
        MatOption,
        MatSelect,
        RouterLink,
        MatMenu,
        MatMenuContent,
        MatMenuItem,
        RouterLinkActive,
        MatButton,
        MatMenuTrigger,
        MatTreeNode,
        MatTree,
        MatNestedTreeNode,
        ShowEpisodesComponent,
        ShowEpisodesInfoComponent
    ],
    templateUrl: './one-show.component.html',
    styleUrl: './one-show.component.scss'
})
export class OneShowComponent implements OnInit
{
  show!: Show;
  order: string | undefined

  private routeSubscription: Subscription | undefined;
  page: string = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private showService: ShowService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    /*this.order = this.router.url.split('/').pop();
    if (!this.containsOnlyNumbers(this.order)){
      this.order = undefined
    }
    this.activatedRoute.params.subscribe(params => {
      const id = params['id']; // Assuming 'id' is the parameter name in your URL
      this.showService.getShow(id).subscribe(
        result => {
          // Handle successful response
          this.show = result;
          this.cdr.detectChanges()
        },
        error => {
          // Handle error
          console.error('Error fetching event data:', error);
        }
      )
    });*/
  }

  selectedEpisode: Episode | null = null;

  onEpisodeSelected(episodeNumber: Episode) {
    this.selectedEpisode = episodeNumber;
  }

  /*setQueryParam(): void {
    const queryParams = { episode: '1' }; // Replace '1' with the desired episode value
    this.router.navigate([], { queryParams });
  }
  selectEpisode(episode: Episode): void {
    this.showService.setEpisode(episode);
    this.cdr.detectChanges()
  }*/

  isMobile(): boolean {
    const screenWidth = window.innerWidth;
    return screenWidth < 768; // Adjust this threshold as needed
  }


  onMobileSelectionChange(event: any): void {
    this.router.navigate(['./',event]);
  }


}
