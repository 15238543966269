import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {Episode} from "../models/episode.model";
import {EpisodeProperty} from "../models/episode-property.enum";


@Injectable({providedIn: 'root'})
export class EpisodeServices {

  private readonly baseUrl: string = `${environment.backendUrl}/episodes`;

  constructor(
    private http: HttpClient,
  ) {
  }

  public getEpisodes(): Observable<Episode[]> {
    return this.http.get<Episode[]>(`${this.baseUrl}`);
  }
  public getEpisode(id: string): Observable<Episode> {
    return this.http.get<Episode>(`${this.baseUrl}/${id}`);
  }
  public create(episode: Episode): Observable<Episode> {
    return this.http.post<Episode>(`${this.baseUrl}`, episode);
  }

  public update(episode: any ): Observable<Episode> {
    if(episode.showId !== undefined){
      return this.http.put<Episode>(`${this.baseUrl}`, episode);
    }
    return of()
  }

  public delete(showId: string | undefined, episodeOrder: number | undefined): Observable<any> {
    return this.http.delete<void>(`${this.baseUrl}`, {body: {showId, episodeOrder}});
  }

}
