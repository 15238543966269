<div class="tags-container">
  <div class="tags" [ngStyle]="{'width.px': categoryWidth - 20}">
    <!--<div *ngFor="let tag of tags | slice:0:10" class="tag">
      #{{ tag.tag }}
    </div>-->
    <div class="link" [routerLink]="'/search/' + 'PROMO'">
      <app-themed-tag [tag]="'#PROMO'" [fontSize]="16"
                      [fontFamily]="'Monrad Semibold'"></app-themed-tag>
    </div>
  </div>
</div>

