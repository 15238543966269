<div class="navigation">
  <div class="advertisement" [style.height.px]="top">
    <!-- /272140683/adxp_hayatba_inarticle -->
    <div id='div-gpt-ad-1732793560324-0' style='min-width: 160px; min-height: 50px;'>
    </div>
  </div>

<!--  <div class="categories" [style.top.px]="top">-->
  <div class="categories-container">
    <div class="categories" #categoriesContainer>
      <button
        (click)="customSetCategory(); setCurrentRoute('/news')"
        class="button"
        [ngClass]="{ 'selected': currentRoute === '/news' }"
        [routerLink]="'/'">
        <span>Početna</span>
      </button>

      <button
        (click)="customSetCategory(); setCurrentRoute('najnovije')"
        class="button"
        [ngClass]="{ 'selected': currentRoute === '/news/najnovije' }"
        [routerLink]="'najnovije'">
        <span>Najnovije</span>
      </button>

      @if (categories !== undefined) {
        @for (category of categories; track category) {
          <button
            (click)="setCategory(category)"
            class="button"
            [ngClass]="{ 'selected': '/news/'+ category[categoriesProperty.category_url] === currentRoute }"
            [routerLink]="category[categoriesProperty.category_url]">
            <span>{{ category[categoriesProperty.name] }}</span>
          </button>
        }
      } @else {
        <span class="loader"></span> <!-- Show loader while loading -->
      }
    </div>
  </div>
</div>
