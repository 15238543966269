import {Component, Input} from '@angular/core';
import {Articles} from "../../../models/articles.model";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-themed-tag',
  imports: [
    NgClass
  ],
  templateUrl: './themed-tag.component.html',
  standalone: true,
  styleUrl: './themed-tag.component.scss'
})
export class ThemedTagComponent {
  @Input() tag!: string;
  @Input() display!: string;
  @Input() fontSize!: number;
  @Input() fontFamily!: string;

}
