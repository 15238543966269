import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {finalize, from, Observable, of, Subject, tap, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {User} from '../models/user.model';
import {SignInForm} from "../models/sign-in-form.interface";
import {AuthService} from "./auth.service";
import {ReturnUrlService} from "./return-url.service";
import {Router} from "@angular/router";
import {SurveysService} from "./survey.service";
import {UserProperty} from "../models/user-property.enum";
import {catchError} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Guest} from "../models/guest.model";
import {Chair} from "../models/chair.model";

@Injectable({providedIn: 'root'})
export class UserService {
  private readonly baseUrl: string = `${environment.backendUrl}/user`;
  private readonly surveyUrl: string = `${environment.backendUrl}/surveys`;
  private readonly baseUrlC: string = `${environment.backendUrl}/chair`;
  private profile: User | undefined;
  private readonly localStorageKeyPrefix = 'surveyResponse_';
  private readonly cookieKeyPrefix = 'surveyResponse_';

  private user: User | Guest | Chair | undefined;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private surveysService: SurveysService,
    private snackBar: MatSnackBar,
    private returnUrlService: ReturnUrlService,
  ) {

  }

  /*public getUserById(id: number): Observable<User> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + `${this.authService.getToken()}`
    });

    return this.http.get<User>(`${this.baseUrl}/${id}`, {headers});
  }*/

  public updateUser(user: User): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + `${this.authService.getToken()}`
    });
    return this.http.put(`${this.baseUrl}/${user.id}`, user, {headers});
    /*return this.http.put(`${this.baseUrl}/user/${user.id}`, user, { headers });*/
  }

  public updateSurveyResponse(userId: string | undefined, survey: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + `${this.authService.getToken()}`
    });
    return this.http.put(`${this.baseUrl}/survey/${userId}`, survey, {headers});
    /*return this.http.put(`${this.baseUrl}/user/${user.id}`, user, { headers });*/
  }

  public login(signInForm: SignInForm): Observable<void> {
    const body = {
      email: signInForm.email,
      password: signInForm.password,
      role: signInForm.role,
    };

    return this.authService.logIn(body)
  }

  public create(user: User): Observable<User> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    //this.http.post<User>(`${this.baseUrl}/signup`, user, { headers });
    return this.http.post<User>(`${this.baseUrl}/signup`, user, {headers});
  }

  public fetchUser(): Promise<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + `${this.authService.getToken()}`
    });
    return this.http.get<User>(`${this.baseUrl}/whoami`, {headers}).toPromise();
  }
  private fetchingUser = false;

  async getUser(): Promise<any> {
    console.log("Getting user")
    if (this.profile) {
      return this.profile;
    }

    // Check if there is an ongoing user retrieval operation
    if (this.fetchingUser) {
      await this.waitForUser();
      return this.profile;
    }

    const token = this.authService.getToken();
    console.log(token)
    if (token == null) {
      return undefined;
    }

    try {
      this.fetchingUser = true;

      // Call the fetchUser function
      const user = await this.fetchUser();
      if(!user){
      }

      // Cache the user once fetched
      this.profile = user;

      // Resolve the waiting promises
      if (this.waitResolve) {
        const resolve = this.waitResolve;
        this.waitResolve = null;
        resolve();
      }

      return user;
    } catch (error) {
      console.error('Error fetching user:', error);
      await this.logoutProfile()
      return undefined;
    } finally {
      this.fetchingUser = false;
    }
  }

  private waitResolve: (() => void) | null = null;

  private async waitForUser(): Promise<void> {
    // If this.waitResolve is null, the promise will keep waiting until it's resolved.
    return new Promise<void>((resolve) => {
      this.waitResolve = resolve;
    });
  }




  public async logoutProfile(): Promise<void> {
    console.log('LOGOUT CALLED')
    const returnUrl = this.returnUrlService.getReturnUrl();
    this.authService.logout()
    this.profile = undefined
  }


  getLocalData(): any[] {
    const localData: any[] = [];
    // Iterate through cookies
    const allCookies = document.cookie.split(';');
    for (let i = 0; i < allCookies.length; i++) {
      const cookie = allCookies[i].trim();

      // Check if the cookie is a survey cookie
      if (cookie.startsWith(`${this.cookieKeyPrefix}`)) {
        const [cookieName, cookieValue] = cookie.split('=');
        const surveyId = cookieName.replace(`${this.cookieKeyPrefix}`, '');

        // Parse the stored value and add it to localData
        if (cookieValue) {
          const response = JSON.parse(cookieValue);
          localData.push({ surveyId, response });
        }
      }
    }

    return localData;
  }



  syncLocalDataWithBackend(localData: any, userData: any): Observable<void> {
    // Filter out local data that is already present in user data
    const newDataToSend = localData.filter((localItem: any) => {
      return (
        !userData.surveys.find(
          (userSurvey: any) =>
            userSurvey.id === localItem.surveyId
        )
      );
    });
    // Send newDataToSend to the backend
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + `${this.authService.getToken()}`
    });
    // Assuming you have an endpoint for updating user data
    // If there is new data to send, make the HTTP request
    if (newDataToSend.length > 0) {

      return this.http.post<void>(`${this.surveyUrl}/local-response`, { newSurveyData: newDataToSend, userId: userData[UserProperty.id] }, { headers })
        .pipe(
          catchError((error) => {
            console.error('Error in HTTP request', error);
            return throwError(error);
          }),
          tap(() => {
            // Show Snackbar on success
            this.snackBar.open('Sve ankete su uspješno upisane u vaš nalog', 'Close', {
              duration: 8000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              panelClass: ['hayat-snackbar'],
            });
          })
        );
    }
    // If there is no new data, return an observable of undefined
    return of(undefined);
  }


  public restartPassword(email: string): Observable<void> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get<void>(`${this.baseUrl}/restart/${email}`, {headers});
  }

  public passwordRestart(token: string, password: string): Observable<User> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post<any>(
      `${this.baseUrl}/reset-password`, {token, newPassword: password}, { headers }
    );  }


}
