import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ArticleRedirectGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const title = next.paramMap.get('title');
    const id = next.paramMap.get('id');

    if (title && id) {
      // Prepare the new path and pass data as query params or state
      const newUrl = `/news/article/${title}/${id}`;

      // Use `state` to pass data to the new URL
      this.router.navigate([newUrl], {
        state: { title, id }
      });

      // Return false to prevent default navigation
      return false;
    }

    // If no title or id, allow the navigation to proceed
    return true;
  }
}
