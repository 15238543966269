import { Component } from '@angular/core';

@Component({
  selector: 'app-archive',
  imports: [],
  templateUrl: './archive.component.html',
  styleUrl: './archive.component.scss'
})
export class ArchiveComponent {

}
