import { Component } from '@angular/core';

@Component({
    selector: 'app-smart-tv-guide',
    imports: [],
    templateUrl: './smart-tv-guide.component.html',
    styleUrl: './smart-tv-guide.component.scss'
})
export class SmartTvGuideComponent {

}
