@if (!isLoading) {
  <div class="category" [ngStyle]="{'width.px': categoryWidth - 20}">
    @if (category) {
      <app-themed-tag [tag]="category[categoriesProperty.name]" [fontSize]="20"
                      [fontFamily]="'Monrad Bold'"></app-themed-tag>
    }

    @if (articlesByColumn.length !== 0) {
      <div class="custom-masonry">
        @for (column of getNumberArray(numberOfColumns); track column; let id = $index) {
          <div class="custom-masonry-column">
            @for (article of articlesByColumn[id]; track article) {
              @if (article.type) {
                <app-themed-advertisement [size]="article.size"></app-themed-advertisement>
              } @else {
                <app-article-card [source]="category.name === 'Izdvojeno' ? 'web-highlight' : 'web-main'"  [article]="article"></app-article-card>
              }
            }
          </div>
        }
      </div>
    }
  </div>
}
