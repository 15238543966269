<div class="drawer">
  <div class="top">
    <button class="close-button" (click)="toggleMenu()">✕</button>
  </div>
  <div class="center">
    @if(currentRoute !== '/'){
      <button class="button hayat-ba" [routerLink]="'/'">
        <img src="assets/hayat-logo.webp" alt="Hayat Logo">
        <p>.ba</p>
      </button>
    }
    @if(currentRoute !== '/media'){
      <button class="button hayat" [routerLink]="'/media'">
        <img src="assets/hayat-logo.webp" alt="Hayat Logo">
      </button>
    }
    @if(currentRoute !== '/play'){
      <button class="button hayat" [routerLink]="'/play'">
        <img src="assets/hayat-logo.webp" alt="Hayat Logo">
        <p>Play</p>
      </button>
    }
  </div>
</div>
