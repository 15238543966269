import { Component } from '@angular/core';

@Component({
    selector: 'app-live-tv',
    imports: [],
    templateUrl: './live-tv.component.html',
    styleUrl: './live-tv.component.scss'
})
export class LiveTvComponent {

}
