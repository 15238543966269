<div class="profile">
  @if (profile.isAdmin) {
    <h1>Dobro došli!</h1>
    <h1>{{ profile.name }} </h1>
  } @else {
    <h1>Dobro došli!</h1>
    <h1>{{ profile.first_name }} {{ profile.last_name }} </h1>
  }

  <button
    class="button login"
    (click)="logoutUser()"
  >
    <p>Log out</p>
  </button>
</div>
