<!--Display last 200 articles-->
<div class="administrator-highlights">
  <div class="page-title">
    <h1>Highlight</h1>

  </div>

  <div class="naslovi">
    <h2>Pregled na stranici</h2>
    @if (areArticlesDifferent()) {
      <button (click)="refreshDiv()">Refresh Div</button>

    }
  </div>
  <div *ngIf="isVisible" class="home-category-container">
    <app-home-category [articles]="newArticles" [category]="categoryHighlight"></app-home-category>
  </div>

  <div class="naslovi">
    <h2>Lista izabranih clanaka</h2>
    @if (areArticlesDifferent()) {
      <button (click)="sendChangesToServer()">
        Save
      </button>
    }
  </div>

  <div class="list-container">
    <div cdkDropList class="article-list" cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
      <div *ngFor="let article of newArticles; let i = index"
           cdkDrag
           class="article-item"
           (click)="removeFromHighlight(article)"
      >
        <app-article-card [article]="article" [articleType]="2" class="disable-click"></app-article-card>
      </div>
    </div>
  </div>

  <h2>Pretraga</h2>

  <div class="input">
    <input
      type="text"
      [(ngModel)]="query"
      (input)="onSearch()"
      (focus)="showResults = true"
      placeholder="Pretraži naslove..."
      class="search-input"
    />
  </div>
  <div class="articles">

    @if (results.length > 0) {
      <div class="search-list">
        @for (article of results; track article) {
          <div class="article-container" (click)="addToHighlight(article)">
            <app-article-card [article]="article" [articleType]="2" class="disable-click"></app-article-card>
          </div>

        }
      </div>

    }
  </div>
</div>
