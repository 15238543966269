import {Component, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";

@Component({
  selector: 'app-artred',
  imports: [],
  templateUrl: './artred.component.html',
  standalone: true,
  styleUrl: './artred.component.scss'
})
export class ArtredComponent implements OnInit{

  constructor(private router: Router) {}


  articleData: any;

  ngOnInit(): void {
    // Access the data passed from the previous route
    this.articleData = history.state;
    console.log('Article Data:', this.articleData);
  }
}
