import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-dialog-media-player',
    imports: [],
    templateUrl: './dialog-media-player.component.html',
    styleUrl: './dialog-media-player.component.scss'
})
export class DialogMediaPlayerComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogMediaPlayerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
