<div class="info"
     [ngStyle]="{'background': 'linear-gradient(to right, ' + data.colorCode1 + ', ' + data.colorCode2 + ')'}"
     [ngClass]="{'reverse': data.type === 1}"
>
    <div class="left">
      <h1>
        {{ data.title }}
      </h1>
      <p>
        {{ data.text }}
      </p>
    </div>
    <div class="right">
      <img src="{{data.image}}">
    </div>
</div>
