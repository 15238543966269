import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Articles} from "../../../../models/articles.model";
import {ArticlesService} from "../../../../services/articles.service";
import {ResolverResponse} from "../../../../constants/resolver-response.constants";
import {Route} from "../../../../constants/route.constants";
import {ArticleComponent} from "../article.component";

@Component({
    selector: 'app-article-container',
    templateUrl: './article-container.component.html',
    imports: [
        ArticleComponent
    ]
})
export class ArticleContainerComponent implements OnInit, OnDestroy{

  public options!: Articles;

  articleSubscription: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private articlesService: ArticlesService,
  ) {
  }


  ngOnInit(): void {
    this.articleSubscription = this.activatedRoute.data.subscribe((response: any) => {
      this.options = response[ResolverResponse.ARTICLE];
    });
  };

  ngOnDestroy():void {
    this.articleSubscription.unsubscribe();
  };
}
