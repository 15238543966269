import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {vodEnvironment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class VodService {

  private readonly baseUrl: string = vodEnvironment.vodUrl;

  constructor(
    private http: HttpClient,
  ) {
  }
  public getArchiveInfo(id: string): Observable<any[]> {
    let url = `${this.baseUrl}/vod_cat_0`; // Default URL without id
    if (id) {
      url = `${this.baseUrl}/${id}`; // Modify URL if id is provided
    }
    return this.http.get<any[]>(url);
  }

  public getArchiveInfoID(id: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/` + id);
  }
  public getChannelsInfo(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/channels`);
  }

}
