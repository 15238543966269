<div class="about">
  <h1>
    KAKO DA GLEDATE SVE HAYATOVE SADRŽAJE I NA TV UREĐAJIMA ANDROID? OVO SU DETALJNE UPUTE  </h1>


  <p>Instalacija Android aplikacije HAYAT MEDIA može se vršiti na TV-uređajima Android verzije 8.0 i novijim.</p>
  <p>Pokrenite Google Play Store na svom Android TV-ekranu, zatim u pretraživač upišite: HAYAT MEDIA.</p>
  <p>Kliknite na opciju INSTALL te, nakon što se aplikacija instalira za nekoliko sekundi, kliknite opciju OPEN.</p>
  <p>Nakon što otvorite aplikaciju HAYAT MEDIA, na Vašem TV-ekranu ispisat će se KOD, koji ćete potvrditi putem svog mobilnog telefona, laptopa ili tableta na web-stranici: <a href="http://www.hayat.ba/tv/index.php">www.hayat.ba/tv/</a></p>
  <p>Nakon što upišete ispravan KOD sa svog TV-ekrana, aplikacija će se automatski aktivirati.</p>
  <p>Nakon toga možete uživati u gledanju našeg programa.</p>
  <p>Spisak TV-uređaja koji podržavaju našu aplikaciju možete naći <strong><a href="https://hayat.ba/wp-content/uploads/2023/05/podrzani_uredjaji.xlsx" data-type="URL" data-id="https://hayat.ba/wp-content/uploads/2023/05/podrzani_uredjaji.xlsx">ovdje</a></strong>.</p>
</div>
