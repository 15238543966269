import { Component } from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {Router, RouterLink, RouterOutlet} from "@angular/router";
import {Route} from "../../../constants/route.constants";
import {User} from "../../../models/user.model";
import {UserService} from "../../../services/user.service";
import {ReturnUrlService} from "../../../services/return-url.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HayatBaHeaderComponent} from "../../../common/hayat-ba-header/hayat-ba-header.component";
import {BasicHeaderComponent} from "../../../common/basic-header/basic-header.component";
import {AdministratorHeaderComponent} from "../../administrator/administrator-header/administrator-header.component";
import {AuthService} from "../../../services/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "../../../dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  imports: [
    ReactiveFormsModule,
    RouterLink,
    RouterOutlet,
    HayatBaHeaderComponent,
  ],
  standalone: true,
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  pathToSignup:string = '/account/signup';

  form!: FormGroup;

  public user: Observable<User> | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private returnUrlService: ReturnUrlService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    public dialog: MatDialog,

  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      role: ['user']
    });
  }

  // handleFormSubmit() {
  //   this.form!.markAllAsTouched();
  //
  //   if (this.form!.valid) {
  //     this.userService.login({ ...this.form!.value }).subscribe(async () => {
  //         const userProfile = await this.userService.getUser();
  //         if (userProfile) {
  //           this.userService.login({ ...this.form!.value }).subscribe(async () => {
  //             const returnUrl = this.returnUrlService.getReturnUrl();
  //             this.router.navigateByUrl(returnUrl || '/');
  //
  //             try {
  //               const localData = this.userService.getLocalData();
  //               await this.userService.syncLocalDataWithBackend(localData, userProfile).toPromise();
  //             } catch (error) {
  //               console.error('Error syncing local data with backend', error);
  //             }
  //           });
  //         } else {
  //           // Handle case where userProfile is undefined
  //           console.error('User profile not available.');
  //         }
  //       },
  //       (error) => {
  //         this.snackBar.open(error.error.message, 'Close', {
  //           duration: 4000, // Adjust the duration as needed
  //           horizontalPosition: 'center',
  //           verticalPosition: 'bottom',
  //           panelClass: ['error-snackbar'],
  //
  //         });
  //     })
  //   }
  //   else this.form!.setErrors({ unauthenticated: true });
  // }

  handleFormSubmit() {
    this.form!.markAllAsTouched();

    if (this.form!.valid) {
      const existingToken = this.authService.getToken();

      if (existingToken) {
        // Show confirmation dialog
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '350px',
          data: { message: 'You are already logged in as Admin. Do you want to log out first?' }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            this.authService.logout()
            this.performLogin();
          }
        });

      } else {
        this.performLogin();
      }
    } else {
      this.form!.setErrors({ unauthenticated: true });
    }
  }

  private performLogin() {
    this.userService.login({ ...this.form!.value }).subscribe(async () => {
        this.authService.fetchUserProfile().subscribe(async (profile) => {
          if (profile) {
            console.log(profile)
            const returnUrl = this.returnUrlService.getReturnUrl();
            await this.router.navigateByUrl(returnUrl || '/');
            try {
              const localData = this.userService.getLocalData();
              await this.userService.syncLocalDataWithBackend(localData, profile).toPromise();
            } catch (error) {
              console.error('Error syncing local data with backend', error);
            }
          } else {
            console.error('User profile not available.');
            // Redirect to login page if needed
          }
        });
      },
      (error) => {
        this.snackBar.open(error.error.message, 'Close', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar'],
        });
      });
  }


  isFieldMissing(fieldName: string) {
    const control = this.form.get(fieldName);
    return control?.invalid && control?.touched;
  }
}
