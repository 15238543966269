import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Categories} from "../models/categories.model";
import {CategoriesService} from "../services/categories.services";
import {Route} from "../constants/route.constants";

@Injectable({providedIn: 'root'})
export class CategoryResolver implements Resolve<Categories> {

  constructor(private categoriesService:CategoriesService) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Categories> | Promise<Categories> | Categories {
    const url = route.paramMap.get(Route.CATEGORY.substring(1));
    if (url == '/najnovije')
    {
      const category = this.categoriesService.getCategoryByUrl(url!);
    }
    const category = this.categoriesService.getCategoryByUrl(url!);
    if(!category) {
      throw 'Item not found!';
    }
    return category;
  }
}
