import { Component } from '@angular/core';
import {GledajHayatHeaderComponent} from "../../common/gledaj-hayat-header/gledaj-hayat-header.component";
import {RouterOutlet} from "@angular/router";

@Component({
    selector: 'app-gledaj-hayat',
    imports: [
        GledajHayatHeaderComponent,
        RouterOutlet
    ],
    templateUrl: './hayat-play.component.html',
    styleUrl: './hayat-play.component.scss'
})
export class HayatPlayComponent {

}
