<div class="control">

  <video autoplay muted loop>
    <source src="assets/loop_logo.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>

  <div class="blurred-box">
    <h1>Team 1</h1>
    <p>{{answer1}}</p>
  </div>
  <div class="blurred-box">
    <h1>Team 2</h1>
    <p>{{answer2}}</p>

  </div>
  <div class="blurred-box">
    <h1>Restart</h1>
  </div>
  <button class="blurred-box" (click)="displayAnswers(false)">
    <h1>Display</h1>
  </button> <button class="blurred-box" (click)="displayAnswers(true)">
    <h1>Display</h1>
  </button>

</div>
