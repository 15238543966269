<div class="episode">
  <h2>{{episode?.topic}}</h2>
  <h3>{{episode?.about}}</h3>
  <h4>Gosti:</h4>
  @for(guest of episode?.guests; track guest){
    <p>{{guest.name}}</p>
  }


</div>
