import { Component } from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-service-gledaj-hayat-guide',
    imports: [
        RouterLink
    ],
    templateUrl: './service-gledaj-hayat-guide.component.html',
    styleUrl: './service-gledaj-hayat-guide.component.scss'
})
export class ServiceGledajHayatGuideComponent {

}
