import {Component, OnInit} from '@angular/core';
import {VodService} from "../../../../services/vod.service";
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from "@angular/router";
import { Location } from '@angular/common';
import {subscribe} from "node:diagnostics_channel";
import {filter} from "rxjs";
import {resolve} from "node:path";
import {MatDialog} from "@angular/material/dialog";
import {DialogMediaPlayerComponent} from "./dialog-media-player/dialog-media-player.component";

@Component({
    selector: 'app-videoteka',
    imports: [
        RouterLink
    ],
    templateUrl: './videoteka.component.html',
    styleUrl: './videoteka.component.scss'
})
export class VideotekaComponent implements OnInit{
  public archiveInfo: any[] = []
  public displayData: any[] = []
  public displayInfo: any;
  public tempUrlEnd: string = ''

  savedCategory: any;

  constructor(
    private vodService: VodService,
    //private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void{
    this.vodService.getArchiveInfo('').subscribe( result => {
      // @ts-ignore
      this.archiveInfo = result.feed;
      // @ts-ignore
      this.displayData = this.archiveInfo
    })
    const urlParts = this.location.path().split('/');
    const url = urlParts[urlParts.length - 1]; // Assuming the cat_uid is the last part of the URL
    if(url.includes('vod_cat_')){
      const match = url.match(/\d+/);
      // @ts-ignore
      const index = this.archiveInfo.findIndex(item => item.cat_uid === match[0]);
      if (index !== -1) {
        this.vodService.getArchiveInfo(url).subscribe(result => {
          // @ts-ignore
          this.archiveInfo[index].feed = result.feed;
          this.displayData = this.archiveInfo[index].feed;
          this.displayInfo = this.archiveInfo[index];

        });
      } else {
        // Iterate through the archiveInfo array
        this.archiveInfo.forEach(item => {
          // Check if the item contains the `cat_vid` field
          if (item.cat_hassub !== '0') {
            const newUrl = 'vod_cat_' + item.cat_uid;
            this.vodService.getArchiveInfo(newUrl).subscribe(result => {
              // @ts-ignore
              item.feed = result.feed;
              // @ts-ignore
              const index = result.feed.findIndex(item => item.cat_uid === match[0]);
              if (index !== -1) {
                this.vodService.getArchiveInfo(url).subscribe(result => {
                  // @ts-ignore
                  item.feed[index].feed = result.feed
                  // @ts-ignore
                  this.displayData = result.feed;
                  // @ts-ignore
                  this.displayInfo = item.feed[index];
                });
              }

            });
          }
        });
      }

    }


    this.router.events.subscribe(data => {
      if( data instanceof NavigationEnd){
        const urlParts = this.location.path().split('/');
        const url = urlParts[urlParts.length - 1]; // Assuming the cat_uid is the last part of the URL
        if(url.includes('vod_cat_')){
          const match = url.match(/\d+/);
          // @ts-ignore
          const index = this.archiveInfo.findIndex(item => item.cat_uid === match[0]);
          if (index !== -1){
            if (this.archiveInfo[index].feed){
              this.displayData = this.archiveInfo[index].feed
              this.displayInfo = this.archiveInfo[index]
            }
            else{
              const newUrl = 'vod_cat_' + this.archiveInfo[index].cat_uid;
              this.vodService.getArchiveInfo(newUrl).subscribe(result => {
                // @ts-ignore
                this.archiveInfo[index].feed = result.feed;
                // @ts-ignore
                this.displayData = this.archiveInfo[index].feed
                // @ts-ignore
                this.displayInfo = this.archiveInfo[index]
              });
            }
          }
          else {
            this.archiveInfo.forEach(archiveItem => {
              // Check if the item contains the `cat_vid` field
              if (archiveItem.cat_hassub !== '0') {
                if(archiveItem.feed)
                {
                  // @ts-ignore
                  const index = archiveItem.feed.findIndex(item => item.cat_uid === match[0]);
                  if (index !== -1) {
                    // @ts-ignore
                    if (archiveItem.feed[index].feed)
                    {
                      // @ts-ignore
                      this.displayData = archiveItem.feed[index].feed
                      // @ts-ignore
                      this.displayInfo = archiveItem.feed[index]
                    }
                    else {
                      const newUrl = 'vod_cat_' + archiveItem.feed[index].cat_uid;
                       this.vodService.getArchiveInfo(newUrl).subscribe(result => {
                        // @ts-ignore
                        archiveItem.feed[index].feed = result.feed;
                         // @ts-ignore
                         this.displayData = archiveItem.feed[index].feed
                         // @ts-ignore
                         this.displayInfo = archiveItem.feed[index]
                        // @ts-ignore
                      });
                    }
                  }
                }
                else {
                  const newUrl = 'vod_cat_' + archiveItem.cat_uid;
                  this.vodService.getArchiveInfo(newUrl).subscribe(result => {
                    // @ts-ignore
                    archiveItem.feed = result.feed;
                    // @ts-ignore
                    const index = result.feed.findIndex(item => item.cat_uid === match[0]);
                    if (index !== -1) {
                      // @ts-ignore
                      if (result.feed[index].feed)
                      {
                        // @ts-ignore
                        this.displayData = result.feed[index].feed
                        // @ts-ignore
                        this.displayInfo = result.feed[index]
                      }
                    }
                  });
                }

              }
            });
          }

          /*this.vodService.getArchiveInfo(url).subscribe(result => {

          });*/
        }
        else {
          this.displayData = this.archiveInfo
          this.displayInfo = null
        }
      }
    })
  }

  isCategory(): boolean{
    return this.displayData.some(obj => obj.cat_uid !== undefined && obj.cat_uid !== null);
  }

  openDialog(media: any): void {
    const dialogRef = this.dialog.open(DialogMediaPlayerComponent, {
      width: '80%',
      data: { media: media }
    });


    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }
}
