import {Component} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {FormBuilder, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ArticlesService} from "../../../../services/articles.service";
import {Show} from "../../../../models/show.model";
import {ShowService} from "../../../../services/show.services";
import {ShowProperty} from "../../../../models/show-property.enum";

@Component({
    selector: 'app-all-shows',
    imports: [
        RouterLink
    ],
    templateUrl: './all-shows.component.html',
    styleUrl: './all-shows.component.scss'
})
export class AllShowsComponent {

  shows!: Show[];

  private loading = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private showService: ShowService,
    private articlesService: ArticlesService,
  ) {
    /*this.activatedRoute.params.subscribe(params => {
      const id = params['id']; // Assuming 'id' is the parameter name in your URL
      // Load initial event data (optional)
      this.showService.getShow(id).subscribe(
        error => {
          // Handle error
          console.error('Error fetching event data:', error);
        }
      );
    });*/
    /*this.surveyForm = this.formBuilder.group({
      surveyType: ['survey', Validators.required],
      question: ['', Validators.required],
      answerOptions: this.formBuilder.array([
        this.formBuilder.control(''), // Add your initial answer options here
        this.formBuilder.control(''),
      ]),
      correctAnswer: [-1] // Default to -1 (for voting)
    });*/
  }

  ngOnInit(): void {
    this.showService.getShows().subscribe(
      (shows: Show[]) => {
        this.shows = shows; // Assign the retrieved courses to the courses property
      },
      (error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error getting editors:', error);
      }
    );

  }
}
