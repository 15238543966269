import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import {ResolverResponse} from "../../../constants/resolver-response.constants";
import {Categories} from "../../../models/categories.model";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {ArticlesProperty} from "../../../models/articles-property.enum";
import {CategoriesProperty} from "../../../models/categories-property.enum";
import {Articles} from "../../../models/articles.model";
import {CategoryArticles} from "../../../models/category-articles.model";
import {CategoryArticlesProperty} from "../../../models/category-articles-property.enum";
import {isPlatformBrowser, NgForOf, NgIf, NgOptimizedImage, NgStyle} from "@angular/common";
import {ArticlesService} from "../../../services/articles.service";
import {ArticleCardComponent} from "../article-card/article-card.component";
import {ThemedTagComponent} from "../themed-tag/themed-tag.component";
import {ThemedAdvertisementComponent} from "../themed-advertisement/themed-advertisement.component";

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  imports: [
    NgIf,
    RouterLink,
    NgForOf,
    NgOptimizedImage,
    ArticleCardComponent,
    ThemedTagComponent,
    NgStyle,
    ThemedAdvertisementComponent
  ],
  standalone: true,
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  private isLoading: boolean = false;

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.isCloseToBottom() && !this.isLoading) {
      this.isLoading = true
      this.loadNewPage();
    }
  }

  private isCloseToBottom(): boolean {
    const threshold = 1000; // Distance in pixels from the bottom
    const position = window.innerHeight + window.scrollY;
    const height = document.body.scrollHeight;
    return position > height - threshold;
  }

  public category!: Categories;
  public categoryString: string = '';

  public articles!: any[];

  categoriesSubscription: any;

  page: number = 1;

  public articlesProperty = ArticlesProperty;
  public categoryArticlesProperty = CategoryArticlesProperty;
  public categoriesProperty = CategoriesProperty;

  categoryWidth: number = 400; // Default width

  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private activatedRoute: ActivatedRoute,
    private articlesService: ArticlesService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  @HostListener('window:resize', [])
  calculateCategoryWidth(screenWidth: number) {
    if(screenWidth > 400)
    {
      const columnWidth = 400;
      const gap = 10;

      // Calculate max columns that can fit
      const numColumns = Math.floor(screenWidth / (columnWidth + gap));

      // Calculate total width
      this.categoryWidth = (numColumns * columnWidth) + ((numColumns - 1) * gap) + 20;
    }
    else {
      this.categoryWidth = innerWidth
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {

      const innerWidth = window.innerWidth;
      this.calculateCategoryWidth(innerWidth - 20)

      if (innerWidth >= 1800) {
        this.numberOfColumns = Math.floor(innerWidth / 400);
      } else {
        this.numberOfColumns = Math.min(Math.floor(innerWidth / 400), 4);
      }

      this.activatedRoute.params.subscribe(async params => {
        this.articlesByColumn = []
        const category = params['category']; // Assuming 'id' is the parameter name in your URL
        this.categoryString = category;
        await this.loadArticles(category);
      })
    }
    if (this.category == undefined) {
      this.categoriesSubscription = this.activatedRoute.data.subscribe((response: any) => {
        this.category = response[ResolverResponse.CATEGORY];
      });
    }
  }

  async loadArticles(category: string) {
    this.page = 1
    this.cdr.detectChanges()

    this.articlesService.getArticlesByCategoryMobile(category, this.page).subscribe(async result => {
      this.articles = result;
      await this.sortArticles();
    })

  }


  loadNewPage(): void {
    this.page++
    if (this.page > 20) return

    this.articlesService.getArticlesByCategoryMobile(this.categoryString, this.page).subscribe(async result => {
      this.articles = result;
      await this.sortArticles();
      this.isLoading = false
    })
  }


  typesInColumns: number[][] = [];
  public articlesByColumn: any[][] = [];
  numberOfColumns: number = 3;
  videoIndex = 0
  photoIndex = 1
  textIndex = 2

  getNumberArray(length: number): number[] {
    return Array.from({length}, (_, i) => i);
  }

  columnsHeightControl: number[] = [];

  async sortArticles() {
    // Initialize column height tracking
    const innerWidth = window.innerWidth;
    this.calculateCategoryWidth(innerWidth)

    if (innerWidth < 400) {
      this.numberOfColumns = 1;
    } else if (innerWidth >= 1800) {
      this.numberOfColumns = Math.floor(innerWidth / 400);
    } else {
      this.numberOfColumns = Math.min(Math.floor(innerWidth / 400), 4);
    }

    for (let i = 0; i < this.numberOfColumns; i++) {
      this.columnsHeightControl[i] = 0;
    }

    for (let i = 0; i < this.numberOfColumns; i++) {
      this.columnsHeightControl[i] = 0;
    }

    let displayedArticles = 0;
    let maxArticlesToShow = Math.min(this.articles.length, 15); // Show 8-12 articles

    for (let i = 0; i < this.articles.length; i++) {
      let columnControl = 0;
      let minHeight = this.columnsHeightControl[0];

      // Find the column with the smallest height
      for (let j = 1; j < this.numberOfColumns; j++) {
        if (this.columnsHeightControl[j] < minHeight) {
          minHeight = this.columnsHeightControl[j];
          columnControl = j;
        }
      }

      // Determine article height
      let articleHeight = 0;
      if (this.articles[i][ArticlesProperty.videoPost]) {
        articleHeight = window.innerWidth > 400 ? 400 : 400;
      } else if (this.articles[i][ArticlesProperty.textPost]) {
        articleHeight = 185;
      } else if (this.articles[i][ArticlesProperty.photoPost]) {
        articleHeight = 130;
      }

      // Add article to the shortest column
      if (!this.articlesByColumn[columnControl]) {
        this.articlesByColumn[columnControl] = [];
      }
      this.articlesByColumn[columnControl].push(this.articles[i]);

      // Update column height
      this.columnsHeightControl[columnControl] += articleHeight;
      displayedArticles++;

      // Stop if we reach at least minArticlesToShow & height balance is good
      if (displayedArticles % 10 === 0) {
        let maxGapColumn = 0;
        let maxGap = 0;

        // Find the column with the **most extra space**
        for (let j = 0; j < this.numberOfColumns; j++) {
          let gap = Math.max(...this.columnsHeightControl) - this.columnsHeightControl[j];
          if (gap > maxGap) {
            maxGap = gap;
            maxGapColumn = j;
          }
        }

        let adSize: "400x400" | "400x200" | null = null;
        adSize = Math.random() > 0.5 ? "400x400" : "400x200";


        // Insert ad if it fits
        if (adSize) {
          this.articlesByColumn[maxGapColumn].push({type: "advertisement", size: adSize});
          this.columnsHeightControl[maxGapColumn] += adSize === "400x400" ? 400 : 200;
        }
      }

    }
  }


}
