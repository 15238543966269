import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {Tag} from "../../../models/tag.model";
import {TagsService} from "../../../services/tags.services";
import {TagProperty} from "../../../models/tag-property.enum";
import {RemoveTagDialogComponent} from "./remove-tag-dialog/remove-tag-dialog.component";
import {Surveys} from "../../../models/surveys.model";
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";

@Component({
    selector: 'app-administrator-tag',
    templateUrl: './administrator-tag.component.html',
    imports: [
        FormsModule,
        NgIf,
        NgForOf
    ],
    styleUrls: ['./administrator-tag.component.scss']
})
export class AdministratorTagComponent implements OnInit {
  active!: Tag[];
  inactive!: Tag[];

  userInput: string = '';
  selectedFilter: string = 'active';


  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private tagsService: TagsService,
  ) {
  }


  ngOnInit(): void {
    this.tagsService.getTags().subscribe(
      (tags: Tag[]) => {
        this.active = tags
          .filter(tag => tag[TagProperty.active]);
        this.inactive = tags
          .filter(tag => !tag[TagProperty.active]);
      },
      (error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error getting courses:', error);
      }
    );
  }

  filterTags(type: string): Tag[] {
    let filteredTags: Tag[] = [];
    if(type === "active"){
      filteredTags = this.active
        .filter(tag => tag[TagProperty.tag].includes(this.userInput.toUpperCase()));
    }
    else if (type === 'inactive')
    {
      filteredTags = this.inactive
        .filter(tag => tag[TagProperty.tag].includes(this.userInput.toUpperCase()));
    }
    return filteredTags;
  }

  onEnter(): void {
    if(this.selectedFilter === 'inactive'){
      return;
    }
    const trimmedInput = this.userInput.trim().toUpperCase();
    if (!trimmedInput) {
      return; // Do nothing if the input is empty or contains only whitespace
    }
    const existingTag = this.active.find(tag => tag.tag === trimmedInput);

    if (existingTag) {
      this.snackBar.open("Tag je vec dodan", 'Close', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['error-snackbar']
      });
    } else {
      // If the tag doesn't exist, add it to the database and then add it to the selected tags
      const newTag: Tag = {tag: trimmedInput, active: true, imagesURL: []};
      this.tagsService.create(newTag).subscribe(
        response  => {
          this.active.push(response);
          this.userInput = ''
        },
        error => {
          console.error('Failed to add new tag:', error);
          // Handle the error (e.g., show a message to the user)
        }
      );
    }
  }


  deactivateTag(tag: Tag) {
    const dialogRef = this.dialog.open(RemoveTagDialogComponent,{
      width: '300px',
      data: {
        title: 'Da li ste sigurni da želite deaktivirati tag?',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result === true){
        const newTag: Tag = {_id: tag[TagProperty.id], tag: tag[TagProperty.tag], active: false, imagesURL: []};
        // Update the tags in your service
        this.tagsService.update(tag[TagProperty.id], newTag).subscribe(result => {
          // Set the 'active' property of the tag to false
          tag.active = false;

          // Add the tag to the 'inactive' array
          this.inactive.push(tag);

          // Remove the tag from the original array (assuming it's stored there)
          const index = this.active.indexOf(tag);
          if (index !== -1) {
            this.active.splice(index, 1);
          }
        });
      }
    });
  }

  deleteTag(tag: Tag) {
    const dialogRef = this.dialog.open(RemoveTagDialogComponent,{
      width: '300px',
      data: {
        title: 'Da li sigurno zelite izbrisati tag?',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result === true){
        this.tagsService.delete(tag[TagProperty.id], tag).subscribe(result => {
          const index = this.inactive.indexOf(tag);
          if (index !== -1) {
            this.inactive.splice(index, 1);
          }
        });
      }
    });
  }

  setFilter(filter: string) {
    this.selectedFilter = filter
  }
}
