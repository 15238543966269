import {afterNextRender, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, mergeMap, Observable, of, tap} from 'rxjs';
import {environment} from '../../environments/environment';
import {AuthResponse} from '../interfaces/auth-response.interface';
import {Router} from '@angular/router';
import {Route} from '../constants/route.constants';
import {jwtDecode} from 'jwt-decode';
import {isPlatformBrowser} from "@angular/common";
import {catchError} from "rxjs/operators";
import {User} from "../models/user.model";


@Injectable({providedIn: 'root'})
export class AuthService {

  private readonly baseUrl: string = `${environment.backendUrl}/user/authenticate`;

  private jwt: string | null = null;

  private userProfile$ = new BehaviorSubject<any>(null); // Store profile in memory


  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    private router: Router,
  ) {
    afterNextRender(() => {
      this.loadToken();
    })
  }

  public loadToken(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.jwt = this.getToken() || null;
    }
  }

  public getToken(): string | undefined {
    const cookies = document.cookie.split('; ');
    // Try to find admin token first
    const adminToken = cookies.find(row => row.startsWith('jwt_ch='));
    if (adminToken) return adminToken.split('=')[1];
    // Try to find user token
    const userToken = cookies.find(row => row.startsWith('jwt_user='));
    if (userToken) return userToken.split('=')[1];
    return undefined;
  }



  public logIn(body: any): Observable<void> {
    return this.http.post<AuthResponse>(`${this.baseUrl}`, body).pipe(
      mergeMap(response => {
        this.jwt = response.token;

        // Determine expiration time based on role
        const tokenExpirationTime = body.role === 'ch' ? 1 : 7; // 1 day for 'ch' and 7 days for 'user'

        // Determine storage key based on role
        const tokenKey = body.role === 'ch' ? 'jwt_ch' : 'jwt_user';

        // Store token in sessionStorage under different keys
        sessionStorage.setItem(tokenKey, this.jwt);

        // Store in cookie with appropriate expiration time
        document.cookie = `${tokenKey}=${this.jwt}; expires=${new Date(Date.now() + tokenExpirationTime * 86400000).toUTCString()}; Secure; SameSite=None; path=/`;

        // Fetch user profile if needed
        this.fetchUserProfile()

        return of(undefined);
      })
    );
  }


  public fetchUserProfile(): Observable<any> {
    const token = this.getToken(); // Get token from AuthService

    if (!token) {
      console.error('No token found!');
      return of(null); // Return observable of null if no token exists
    }


    // Decode the token to get role and expiration info
    // const decodedToken = jwtDecode<any>(token); // Assuming you have jwt-decode installed
    const decodedToken: any = jwtDecode(token);
    const role = decodedToken.role;
    const expirationTime = decodedToken.exp * 1000; // Expiration time in milliseconds
    const now = Date.now();

    // If the token is expired, log out and return null
    if (now > expirationTime) {
      this.logout(); // Log out if expired
      console.error('Token expired!');
      return of(null); // Return observable of null (user is logged out)
    }

    // If user profile is available in the BehaviorSubject (local cache)
    if (this.userProfile$.value) {
      return of(this.userProfile$.value); // Return cached user profile if available
    }

    // Otherwise, fetch user profile from backend based on token role
    let route: string;
    if (role === 'ch') {
      route = 'chairs/whoami'; // Fetch profile for chair
    } else {
      route = 'user/whoami'; // Fetch profile for user
    }

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + `${this.getToken()}`
    });
    return this.http.get(`${environment.backendUrl}/${route}`, {headers}).pipe(
      tap((profile) => {
        this.userProfile$.next(profile); // Cache the profile in BehaviorSubject
      }),
      catchError((error) => {
        console.error('Error fetching profile:', error);
        this.logout(); // Log out if an error occurs
        this.router.navigate(['/login']); // Navigate to login page
        return of(null); // Return observable of null (error occurred)
      })
    );
  }

  getUserProfile(): Observable<any> {
    return this.userProfile$.asObservable();
  }

  public logout(): void {
    this.jwt = null;
    sessionStorage.removeItem('jwt_ch');
    sessionStorage.removeItem('jwt_user');
    document.cookie = 'jwt_ch=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=None';
    document.cookie = 'jwt_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=None';
  }


  public isLoggedIn(): boolean {
    const token = this.getToken();
    if (!token) return false;

    try {
      const decodedToken: any = jwtDecode(token);
      return decodedToken.exp * 1000 > Date.now();
    } catch (error) {
      return false;
    }
  }


}
