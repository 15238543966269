import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogRef} from "@angular/material/dialog";
import {TagsService} from "../../../services/tags.services";
import {Tag} from "../../../models/tag.model";
import {TagProperty} from "../../../models/tag-property.enum";
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {ImagesService} from "../../../services/images.service";

@Component({
    selector: 'app-tag-images-display-dialog',
    templateUrl: './tag-images-display-dialog.component.html',
    imports: [
        FormsModule,
        NgIf,
        NgForOf,
        MatDialogActions
    ],
    styleUrls: ['./tag-images-display-dialog.component.scss']
})
export class TagImagesDisplayDialogComponent {
  userInput = '';
  allImages: any[] = []; // Replace with actual data structure
  selectedImages: any[] = []; // Replace with actual data structure

  public allTags: Tag[] = [];
  public selectedTags: Tag[] = [];
  public receivedTagsControl: Tag[] = [];

  public more_images: boolean = true;

  page: number = 1;

  loadingImages: Set<string> = new Set();


  constructor(
    public dialogRef: MatDialogRef<TagImagesDisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public tagsService: TagsService,
    public imagesService: ImagesService,
  ) {}

  ngOnInit() {
    this.tagsService.getTags().subscribe((response) => {
      this.allTags = response
    })

    this.imagesService.getImages(this.selectedTags, this.page).subscribe((images) => {
      this.allImages = images;
      this.more_images = images.length >= 20;

    });
  }

  loadImages(): void {
    this.imagesService.getImages(this.selectedTags, this.page).subscribe((images) => {
      this.allImages.push(...images);
      this.more_images = images.length >= 20;
    });
  }

  onConfirm() {
    this.dialogRef.close(this.selectedImages);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onEnter(): void {
    const trimmedInput = this.userInput.trim().toUpperCase();
    if (!trimmedInput) {
      return; // Do nothing if the input is empty or contains only whitespace
    }
    const existingTag = this.receivedTagsControl.find(tag => tag.tag === trimmedInput);

    if (this.selectedTags.find(tag => tag.tag === trimmedInput)) {

    } else if(existingTag){
      this.addTag(existingTag);
    }
    this.userInput = ''
  }

  filterTags(): Tag[] {
    const filteredTags = this.allTags
      .filter(tag => tag[TagProperty.tag].toLowerCase().includes(this.userInput.toLowerCase()))
      .slice(0, 20);
    return filteredTags;
  }

  addTag(tag: Tag): void {
    this.selectedTags.push(tag);
    this.allTags = this.allTags.filter(t => t !== tag);
    this.allImages = []
    this.loadImages()
  }

  removeTag(selectedTag: Tag): void {
    this.selectedTags = this.selectedTags.filter(tag => tag !== selectedTag);
    this.allTags.push(selectedTag);
    this.allImages = []
    this.loadImages()
  }


  trackByFn(index: number, item: any): any {
    return item.url; // Use a unique identifier for each image, e.g., the URL
  }
  addImage(selectedImages: any): void {
    this.selectedImages.push(selectedImages);
    this.allImages = this.allImages.filter(image => image !== selectedImages);
  }
  removeImage(selectedImages: any): void {
    this.selectedImages = this.selectedImages.filter(image => image !== selectedImages);
    this.allImages.push(selectedImages);
  }


  isSelected(image: any): boolean {
    return this.selectedImages.some(selectedImage => selectedImage.url === image.url);
  }

  toggleImage(image: any): void {
    const index = this.selectedImages.findIndex(selectedImage => selectedImage.url === image.url);

    if (index !== -1) {
      this.selectedImages.splice(index, 1); // Remove if already selected
    } else {
      this.selectedImages = [...this.selectedImages, image]; // Add if not selected
    }
  }

  isLoading(image: any): boolean {
    return this.loadingImages.has(image.url);
  }

  onImageLoad(image: any): void {
    this.loadingImages.delete(image.url);
  }

  onScroll(): void {
    const container = document.querySelector('.image-selector');
    if (container) {
      const scrollPosition = container.scrollTop + container.clientHeight;
      const totalHeight = container.scrollHeight;

      if (scrollPosition >= totalHeight - 200) {
        // You can adjust the 200 value based on when you want to trigger the next page load
        this.loadNextPage();
      }
    }
  }

  loadNextPage(): void {
    this.page++;
    this.loadImages();
  }
}
