import {Component, EventEmitter, Output} from '@angular/core';
import {Router, RouterLink} from "@angular/router";

@Component({
  selector: 'app-drawer',
  imports: [
    RouterLink
  ],
  templateUrl: './drawer.component.html',
  standalone: true,
  styleUrl: './drawer.component.scss'
})
export class DrawerComponent {
  @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
  currentRoute: string = '';
  constructor(private router: Router) {
    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
    });
  }

  toggleMenu() {
    this.closeMenu.emit(); // 🔥 Send signal to parent (main component)
  }
}
