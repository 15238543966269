@if (articlesByColumn !== []) {
  <div class="category" [ngStyle]="{'width.px': categoryWidth}">
    @if (category) {
      <app-themed-tag [tag]="category[categoriesProperty.name]" [fontSize]="20"
                      [fontFamily]="'Monrad Bold'"></app-themed-tag>
    }

    @if (articlesByColumn.length !== 0) {
      <div class="custom-masonry">
        @for (column of getNumberArray(numberOfColumns); track column; let id = $index) {
          <div class="custom-masonry-column">
            @for (article of articlesByColumn[id]; track article) {
              @if (article.type === 'advertisement') {
                <app-themed-advertisement [size]="article.size"></app-themed-advertisement>
              } @else {
                <app-article-card [source]="'web-' + category.name" [article]="article"></app-article-card>

              }
            }
          </div>
        }
      </div>
    }
  </div>
} @else {
  <div class="highlight">
    <!-- Left Column -->
    <div class="column first">
      <div class="article priority-2">
        <div class="skeleton-2-title">
          <div class="skeleton-title ">
                <span class="skeleton-icon">
                    <i class="fa-solid fa-align-justify"></i>
                </span>
            <div class="title-lines shimmer-effect"></div>
          </div>
          <div class="skeleton-title">
            <div class="title-lines shimmer-effect"></div>
          </div>
          <div class="skeleton-title">
            <div class="title-lines shimmer-effect" style="width: 75% !important;"></div>
          </div>
        </div>
        <div class="skeleton-category">
          <div class="category-lines shimmer-effect"></div>
        </div>
        <div class="skeleton-media-container shimmer-effect">
        </div>
        <div class="skeleton-subtitle">
          <div class="subtitle-lines shimmer-effect"></div>
        </div>
        <div class="skeleton-subtitle">
          <div class="subtitle-lines shimmer-effect" style="width: 75% !important;"></div>
        </div>
      </div>

      <div
        class="skeleton"
        style="min-height: 125px; width: 100%"
      >
      </div>
    </div>

    <!-- Center Column -->
    <div class="right">
      <div class="top">
        <div class="article priority-6">
          <div class="skeleton-info">
            <div class="skeleton-category">
              <div class="category-lines shimmer-effect"></div>
            </div>
          </div>
          <!-- Article content for priority 6 -->
          <div class="skeleton-6-title">
            <span class="skeleton-icon">
              <i class="fa-solid fa-align-justify"></i>
            </span>
            <div class="title-6-lines shimmer-effect"></div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="column second">
          <div class="article priority-4">
            <div class="skeleton-category">
              <div class="category-lines shimmer-effect"></div>
            </div>
            <div class="skeleton-media-container shimmer-effect">

            </div>
            <div style="border-bottom: 5px">
              <div class="skeleton-4-title">
                <span class="skeleton-icon">
                    <i class="fa-solid fa-align-justify"></i>
                </span>
                <div class="title-4-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-4-title">
                <div class="title-4-lines shimmer-effect" style="width: 75% !important;"></div>
              </div>
            </div>
            <div class="skeleton-4-subtitle">
              <div class="subtitle-4-lines shimmer-effect"></div>
            </div>
            <div class="skeleton-4-subtitle">
              <div class="subtitle-4-lines" shimmer-effect style="width: 75% !important;"></div>
            </div>
          </div>
          <div class="article priority-4">
            <div class="skeleton-category">
              <div class="category-lines shimmer-effect"></div>
            </div>
            <div class="skeleton-media-container shimmer-effect">

            </div>
            <div style="border-bottom: 5px">
              <div class="skeleton-4-title">
                <span class="skeleton-icon">
                    <i class="fa-solid fa-align-justify"></i>
                </span>
                <div class="title-4-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-4-title">
                <div class="title-4-lines shimmer-effect" style="width: 75% !important;"></div>
              </div>
            </div>
            <div class="skeleton-4-subtitle">
              <div class="subtitle-4-lines shimmer-effect"></div>
            </div>
            <div class="skeleton-4-subtitle">
              <div class="subtitle-4-lines shimmer-effect" style="width: 75% !important;"></div>
            </div>
          </div>
        </div>
        <div class="column third">
          <div class="article priority-3">
            <div class="skeleton-category">
              <div class="category-lines shimmer-effect"></div>
            </div>
            <div class="skeleton-media-container shimmer-effect">

            </div>
            <div style="border-bottom: 5px">
              <div class="skeleton-3-title">
                <span class="skeleton-icon">
                    <i class="fa-solid fa-align-justify"></i>
                </span>
                <div class="title-3-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-3-title">
                <div class="title-3-lines shimmer-effect" style="width: 75% !important;"></div>
              </div>
            </div>
            <div class="skeleton-3-subtitle">
              <div class="subtitle-3-lines shimmer-effect"></div>
            </div>
            <div class="skeleton-3-subtitle">
              <div class="subtitle-3-lines shimmer-effect" style="width: 75% !important;"></div>
            </div>
          </div>

          <div class="advertisement-container">
            <div class="article priority-5">
              <div class="skeleton-category">
                <div class="category-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-media-container shimmer-effect">

              </div>
              <div class="skeleton-5-title">
                <span class="skeleton-icon">
                    <i class="fa-solid fa-align-justify"></i>
                </span>
                <div class="title-5-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-5-title">
                <div class="title-5-lines shimmer-effect" style="width: 75% !important;"></div>
              </div>
            </div>

            <div class="article priority-5">
              <div class="skeleton-category">
                <div class="category-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-media-container shimmer-effect">

              </div>
              <div class="skeleton-5-title">
                <span class="skeleton-icon">
                    <i class="fa-solid fa-align-justify"></i>
                </span>
                <div class="title-5-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-5-title">
                <div class="title-5-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-5-title">
                <div class="title-5-lines shimmer-effect" style="width: 75% !important;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
