import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {mergeMap, Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {Editor} from "../models/editor.model";
import {Chair} from "../models/chair.model";


@Injectable({providedIn: 'root'})
export class EditorsServices {

  private readonly baseUrl: string = `${environment.backendUrl}/chairs`;

  private profile!: Editor;

  private _editors: Editor[] = [];

  private _editorId: string | null = null;

  private _page: number = 1;


  constructor(
    private http: HttpClient
  ) {
  }

  public getEditors(): Observable<Chair[]> {
    return this.http.get<Chair[]>(`${this.baseUrl}/editors`);
  }

  public getEditorById(id: string): Observable<Editor> {
    this.editorId = id;
    return this.http.get<Editor>(`${this.baseUrl}/${id}`);
  }

  public getEditorSizeById(id: string): Observable<Editor>{
    return this.http.get<Editor>(`${this.baseUrl}/${id}/size`);
  }

  public create(editor: Editor): Observable<Editor> {
    return this.http.post<Editor>(`${this.baseUrl}`, editor);
  }

  public delete(editor: string | undefined): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${editor}`);
  }

  public update(id: string, editor: Editor): Observable<Editor> {
    return this.http.put<Editor>(`${this.baseUrl}/${id}`, editor);
  }

  get editorId(): string | null {
    return this._editorId;
  }

  set editorId(value: string | null) {
    this._editorId = value;
  }

  public getEditor(): Editor {
    return this.profile;
  }

  get editors(): Editor[] {
    return this._editors;
  }

  set editors(value: Editor[]) {
    this._editors = value;
  }

  public fetchEditor(): Observable<void> {
    return this.http.get<Editor>(`${this.baseUrl}/whoami`).pipe(
      mergeMap(response => {
        this.profile = response;
        return of(undefined);
      })
    );
  }

  get page(): number {
    return this._page;
  }

  set page(value: number) {
    this._page = value;
  }

}
