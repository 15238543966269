import { Component } from '@angular/core';

@Component({
    selector: 'app-contact',
    imports: [],
    templateUrl: './marketing.component.html',
    styleUrl: './marketing.component.scss'
})
export class MarketingComponent {

}
