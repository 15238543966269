import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Articles} from "../../../models/articles.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ArticlesService} from "../../../services/articles.service";
import {ArticlesProperty} from "../../../models/articles-property.enum";
import {ConfirmationDialogComponent} from "../../../dialogs/confirmation-dialog/confirmation-dialog.component";
import {RouterLink} from "@angular/router";
import {NgForOf, NgIf, NgStyle} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {response} from "express";
import {ArticleCardComponent} from "../../hayat.ba/article-card/article-card.component";
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";
import {HomeCategoryComponent} from "../../hayat.ba/home-category/home-category.component";
import {Categories} from "../../../models/categories.model";
import {CategoriesProperty} from "../../../models/categories-property.enum";
import {CanComponentDeactivate} from "../../../guards/can-deactivate.guard";

@Component({
  selector: 'app-administrator-articles',
  templateUrl: './administrator-highlights.component.html',
  imports: [
    RouterLink,
    NgForOf,
    NgIf,
    FormsModule,
    ArticleCardComponent,
    NgStyle,
    CdkDropList,
    CdkDrag,
    HomeCategoryComponent
  ],
  standalone: true,
  styleUrls: ['./administrator-highlights.component.scss']
})
export class AdministratorHighlightsComponent implements OnInit, CanComponentDeactivate{
  newArticles: Articles[] = [];
  highlightedArticles: Articles[] = [];
  query: string = '';
  results: any[] = [];
  showResults: boolean = false;

  // Method to compare two arrays of Articles
  areArticlesDifferent(): boolean {
    if (this.newArticles.length !== this.highlightedArticles.length) {
      return true; // If the lengths are different, they are definitely different
    }

    // If lengths are the same, compare each article
    for (let i = 0; i < this.newArticles.length; i++) {
      if (this.newArticles[i]._id !== this.highlightedArticles[i]._id) {
        return true; // If any article ID differs, return true
      }
    }
    return false; // If no differences were found, return false
  }

  onSearch(): void {
    if (this.query.trim()) {
      this.articlesService.searchTitle(this.query.trim()).subscribe((articles) => {
        this.results = articles.slice(0, 30); // Limit to 30 results
      });
    } else {
      this.results = [];
    }
  }

  isVisible: boolean = true;
  refreshDiv(): void {
    this.isVisible = false; // Hide the div
    setTimeout(() => {
      this.isVisible = true; // Show the div again after a short delay
    }, 0);
  }


  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.newArticles, event.previousIndex, event.currentIndex);
    this.cdr.detectChanges()

  }

  addToHighlight(article: Articles) {
    // Check if the article is already in the newArticles array
    const exists = this.newArticles.some(existingArticle => existingArticle._id === article._id);

    if (!exists) {
      // If the article doesn't exist in the array, add it
      this.newArticles.push(article);
      this.cdr.detectChanges();
    } else {
      // Optionally, you can display a message or handle the case where the article already exists
      console.log('Article already exists in the highlight list.');
    }
  }


  removeFromHighlight(article: Articles) {
    const index = this.newArticles.findIndex(a => a._id === article._id);
    if (index !== -1) {
      this.newArticles.splice(index, 1);
      this.cdr.detectChanges()
    }
  }

  sendChangesToServer() {
    const data = {
      articles: this.newArticles
    }
    this.articlesService.updateHighlights(data).subscribe((result) => {
      this.highlightedArticles = [...this.newArticles]
      this.refreshDiv()
    });

  }
  protected categoryHighlight: Categories = {
    [CategoriesProperty.name]: 'Izdvojeno',
  }

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private articlesService: ArticlesService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  counter(i: number) {
    return new Array(i);
  }
  ngOnInit(): void {
    this.articlesService.getArticleHighlight().subscribe(
      (articles: any) => {
        this.newArticles = articles; // Assign the retrieved courses to the courses property
        this.highlightedArticles = [...this.newArticles]; // Assign the retrieved courses to the courses property
      },
      (error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error getting articles:', error);
      }
    );
  }

  canDeactivate(): boolean {
    if (JSON.stringify(this.highlightedArticles) !== JSON.stringify(this.newArticles)) {
      return window.confirm('You have unsaved changes. Do you really want to leave?');
    }
    return true;  // No unsaved changes
  }


  protected readonly ArticlesService = ArticlesService;
  protected readonly ArticlesProperty = ArticlesProperty;
}
