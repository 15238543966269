import { Component } from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {User} from "../../../../models/user.model";
import {Router} from "@angular/router";
import {UserService} from "../../../../services/user.service";
import {Route} from "../../../../constants/route.constants";
import {ChairService} from "../../../../services/chair.service";
import {BasicHeaderComponent} from "../../../../common/basic-header/basic-header.component";
import {ConfirmationDialogComponent} from "../../../../dialogs/confirmation-dialog/confirmation-dialog.component";
import {AuthService} from "../../../../services/auth.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-administrator-login',
  templateUrl: './administrator-login.component.html',
  imports: [
    ReactiveFormsModule
  ],
  standalone: true,
  styleUrls: ['./administrator-login.component.scss']
})
export class AdministratorLoginComponent {

  form!: FormGroup;

  public user: Observable<User> | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private chairService: ChairService,
    private authService: AuthService,
    public dialog: MatDialog,

  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      role: ['ch']
    });
  }

  handleFormSubmit() {
    this.form!.markAllAsTouched();
    if (this.form!.valid) {
      const existingToken = this.authService.getToken();

      if (existingToken) {
        // Show confirmation dialog
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '350px',
          data: { message: 'You are already logged in as Admin. Do you want to log out first?' }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            this.authService.logout()
            this.performLogin();
          }
        });

      } else {
        this.performLogin();
      }
    } else {
      this.form!.setErrors({ unauthenticated: true });
    }
  }


  private performLogin() {
    this.userService.login({ ...this.form!.value }).subscribe(() => {
      this.chairService.fetchChair().subscribe(()=> {
        this.router.navigateByUrl( '/chair');
      })
    })
  }

  isFieldMissing(fieldName: string) {
    const control = this.form.get(fieldName);
    return control?.invalid && control?.touched;
  }
}
