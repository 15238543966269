import {Component, OnInit} from '@angular/core';
import {NavigationStart, Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-profile',
  imports: [
    NgIf
  ],
  templateUrl: './profile.component.html',
  standalone: true,
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit{

  profile: any;
  constructor(
    private router: Router,
    private authService: AuthService) {}

  ngOnInit() {
    this.authService.fetchUserProfile().subscribe((profile) => {
      if (profile) {
        this.profile = profile
        console.log(profile)
        // console.log('User is logged in:', profile);
        // Optionally store the profile data in local storage, etc.
      } else {
        // console.log('No valid user session, or user needs to log in');
        // Redirect to login page if needed
      }
    });
  }

  public logoutUser(): void{
    this.authService.logout();
    this.router.navigateByUrl( '/');

  }
}
