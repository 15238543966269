import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {Articles} from "../models/articles.model";
import {environment } from '../../environments/environment';
import {CategoryArticles} from "../models/category-articles.model";
import {catchError} from "rxjs/operators";
import {Tag} from "../models/tag.model";


@Injectable({providedIn: 'root'})
export class ArticlesService {

  private readonly baseUrl: string = `${environment.backendUrl}/articles`;
  private readonly articleUrl: string = `${environment.backendUrl}/article`;

  private _categoryName: string | null = null;
  private _tagName: string | null = null;

  private _pageNumber: string | null = null;

  private _search: string = "";

  private _searchType: string = "";

  private _articles: Articles[] = [];


  constructor(
    private http: HttpClient,
  ) {
  }

  public getArticleHighlight(): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/highlight`);
  }
  public getArticlesHot(limit: number = 50): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/hot/${limit}`);
  }
  public getArticlesMainPage(): Observable<Articles[][]> {
    return this.http.get<Articles[][]>(`${this.baseUrl}/home`);
  }

  /*Get one article array for category*/
  public getArticlesByCategoryMobile(category: string | null, page: string | number | null): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/mob/${category}/${page}`);
  }


  /*Get one article array for najnovije*/
  public getNewestArticles(page: number): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/mob/najnovije/${page}`);
  }

  /*GET ARTICLES BY TAG*/
  public getArticlesByTag(): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/search/${this._tagName}/1`);
  }
  public getArticlesByTagByPage(page: number): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/search/${this._tagName}/${page}`);
  }



  public getAllArticlesByDate(page: number): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/all/${page}`);
  }


  public getArticlesAnalytics(page: number): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/analytics/${page}`);
  }

  public getAllRequests(page: number): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/request/${page}`);
  }

  public getAllArticlesByPriority(page: number): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/priority`);
  }

  public getArticlesByEditor(editor: string | undefined, page: number): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/editor/${editor}/${page}`);
  }

  public getRequestedArticlesByEditor(editor: string | undefined, page: number): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/editor/requested/${editor}/${page}`);
  }

  public getArticle(id: string): Observable<Articles> {
    return this.http.get<Articles>(`${this.articleUrl}/${id}`);
  }

  public getArticleForReview(id: string): Observable<Articles> {
    return this.http.get<Articles>(`${this.articleUrl}/review/${id}`);
  }

  public getArchiveArticles(page: number): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/archive/${page}`);
  }

  public getDeletedArticles(page: number): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/deleted/${page}`);
  }

  public create(articles: Articles): Observable<Articles> {
    return this.http.post<Articles>(`${this.baseUrl}`, articles);
  }
  public request(articles: Articles): Observable<Articles> {
    return this.http.post<Articles>(`${this.baseUrl}/request`, articles);
  }
  public schedule(articles: Articles): Observable<Articles> {
    return this.http.post<Articles>(`${this.baseUrl}/schedule`, articles);
  }


  public approve(articles: Articles): Observable<Articles> {
    return this.http.post<Articles>(`${this.baseUrl}`, articles);
  }

  public update(id: string, articles: Articles): Observable<Articles> {
    return this.http.put<Articles>(`${this.baseUrl}/${id}`, articles);
  }

  public removePriority(id: string | undefined): Observable<Articles> {
    return this.http.delete<Articles>(`${this.baseUrl}/priority/${id}`);
  }

  public removeAllPriority(id: number): Observable<Articles> {
    return this.http.delete<Articles>(`${this.baseUrl}/allPriority/${id}`);
  }

  public archive(id: string | undefined): Observable<void> {
    if(id !== undefined)
    {
      return this.http.delete<void>(`${this.baseUrl}/archive/${id}`);
    }
    return of(undefined)
  }

  public delete(id: string | undefined): Observable<void> {
    if(id !== undefined)
    {
      return this.http.delete<void>(`${this.baseUrl}/${id}`);
    }
    return of(undefined)
  }


  /*DRAFT*/

  public getAllArticlesSchedule(): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/schedule`);
  }
  public getArticlesScheduleByEditor(id: string): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/schedule/${id}`);
  }
  public getAllArticlesDraft(): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/draft`);
  }
  public getArticlesDraftByEditor(id: string): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/draft/${id}`);
  }
  public getAllArticlesDelete(): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/delete`);
  }



  set categoryName(value: string | null) {
    this._categoryName = value;
  }
  set tagName(value: string | null) {
    this._tagName = value;
  }

  get articles(): Articles[] {
    return this._articles;
  }

  set articles(value: Articles[]) {
    this._articles = value;
  }

  get search(): string {
    return this._search;
  }

  set search(value: string) {
    this._search = value;
  }

  get searchType(): string {
    return this._searchType;
  }

  set searchType(value: string) {
    this._searchType = value;
  }

  get pageNumber(): string | null {
    return this._pageNumber;
  }

  set pageNumber(value: string | null) {
    this._pageNumber = value;
  }







  // Search
  public searchTitle(query: string): Observable<Articles[]> {
    return this.http.get<Articles[]>(`${this.baseUrl}/search/${query}`);
  }

  public updateHighlights(articles: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/highlights`, articles);
  }
  public articleSource(articleId: any, source: string | null): Observable<any>  {
    return this.http.post<any>(`${this.baseUrl}/track-click`, { articleId, source })
  }

}
