import {User} from "../../../models/user.model";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {UserProperty} from "../../../models/user-property.enum";
import {Router, RouterLink} from "@angular/router";
import {Route} from "../../../constants/route.constants";
import {UserService} from "../../../services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {PrivacyPolicyDialogComponent} from "./privacy-policy-dialog/privacy-policy-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {NgIf} from "@angular/common";
import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  imports: [
    ReactiveFormsModule,
    NgIf,
    RouterLink
  ],
  standalone: true,
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  pathToLogin: string = '/login';


  @Output()
  saveUser: EventEmitter<User> = new EventEmitter<User>();

  @Input()
  user: User | undefined;

  public form!: FormGroup;
  public userProperty = UserProperty;
  public loading = false;
  public warning = false
  public acceptTerms = false;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      [UserProperty.id]: [this.user?.[UserProperty.id] || ''],
      [UserProperty.firstname]: [this.user?.[UserProperty.firstname] || '', Validators.required],
      [UserProperty.lastname]: [this.user?.[UserProperty.lastname] || '', Validators.required],
      [UserProperty.email]: [this.user?.[UserProperty.email] || '', [Validators.email, Validators.required]],
      [UserProperty.password]: [this.user?.[UserProperty.password] || '', [Validators.required, this.minLengthValidator(8)]],
      acceptTerms: [false, Validators.requiredTrue] // This adds the acceptTerms control
    });
  }

  minLengthValidator(minLength: number) {
    return (control: { value: string }): { [key: string]: any } | null => {
      const value = control.value;

      if (value && value.length < minLength) {
        return { minlength: true };
      }

      return null;
    };
  }

  clearMissingField(fieldName: string): void {
    // @ts-ignore
    this.form.get(this.userProperty[fieldName])?.updateValueAndValidity();
  }

  openPrivacyDialog(): void {
    this.dialog.open(PrivacyPolicyDialogComponent, {
      width: '90vw', // 90% of the viewport width
      height: '90vh', // 90% of the viewport height
    });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
     /* this.snackBar.open('Sva polja nisu ispunjena!', 'Close', {
        duration: 3000, // Adjust the duration as needed
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['error-snackbar'],
      });*/
      this.warning = true;
      return;
    }
    this.warning = false;
    this.loading = true;
    this.userService.create(this.form.value).subscribe(
      value => {
        this.snackBar.open(`Provjerite vašu mail adresu i potvrdite kreiranje naloga u roku od 24 sata.`
          , 'Close', {
            duration: 1000000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['signup-snackbar', 'flashing'], // Add the 'flashing' class
          });
        this.resetForm();
        this.router.navigate([Route.LOGIN]);
      },
      error => {
        if (error.status === 409) {
          this.snackBar.open('Email already exists', 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: ['error-snackbar'],
          });
        } else {
          console.error('Error during user creation:', error);
          // Handle other errors as needed
        }
        // Reset loading flag in case of an error
        this.loading = false;
      },
      () => {
        // This block is executed on completion (whether successful or with an error)
        this.loading = false;
      }
    );
  }

  private resetForm(): void {
    this.form.reset();
  }

  isFieldMissing(fieldName: string) {
    const control = this.form.get(fieldName);
    return control?.invalid && control?.touched;
  }
}
