import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Show} from "../../../../../models/show.model";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {ShowService} from "../../../../../services/show.services";
import {
  CrateEpisodeDialogComponent
} from "../../../../administrator/administrator-show/crate-episode-dialog/crate-episode-dialog.component";
import {Episode} from "../../../../../models/episode.model";
import {Route} from "../../../../../constants/route.constants";
import {NgClass} from "@angular/common";

@Component({
    selector: 'app-show-episodes',
    imports: [
        RouterLink,
        NgClass
    ],
    templateUrl: './show-episodes.component.html',
    styleUrl: './show-episodes.component.scss'
})
export class ShowEpisodesComponent implements OnInit{
  show!: Show;

  id!: string;
  order!: string | undefined;

  private routeSubscription: Subscription | undefined;
  page: string = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private showService: ShowService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
      console.log('HERE')

      this.showService.getShow(id).subscribe(
        result => {
          // Handle successful response
          this.show = result;
          this.cdr.detectChanges()
        },
        error => {
          // Handle error
          console.error('Error fetching event data:', error);
        }
      )
    });

    this.order = this.router.url.split('/').pop();
    if (!this.containsOnlyNumbers(this.order)){
      this.order = undefined
    }

    /*this.order = this.router.url.split('/').pop();
    if (!this.containsOnlyNumbers(this.order)){
      this.order = undefined
    }
    this.activatedRoute.params.subscribe(params => {
      const id = params['id']; // Assuming 'id' is the parameter name in your URL
      this.showService.getShow(id).subscribe(
        result => {
          // Handle successful response
          this.show = result;
          this.cdr.detectChanges()
        },
        error => {
          // Handle error
          console.error('Error fetching event data:', error);
        }
      )
    });*/
  }

  @Output() episodeSelected = new EventEmitter<Episode>();

  selectEpisode(episode: Episode) {
    this.order = episode.order.toString()
    this.showService.setEpisode(episode)
    this.episodeSelected.emit(episode);
    this.cdr.detectChanges()
  }
  deselectEpisode() {
    this.order = ''
    this.cdr.detectChanges()
  }

  containsOnlyNumbers(input: string | undefined): boolean {
    return !isNaN(Number(input));
  }

}
