import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of, Subject, tap} from 'rxjs';
import {environment} from '../../environments/environment';
import {Show} from "../models/show.model";
import {Articles} from "../models/articles.model";
import {Episode} from "../models/episode.model";


@Injectable({providedIn: 'root'})
export class ShowService {

  private readonly baseUrl: string = `${environment.backendUrl}/shows`;
  private show: Show | null = null; // Variable to hold the show data
  private episode: Episode | null = null; // Variable to hold the show data
  private episodeDataUpdated = new Subject<void>();

  constructor(
    private http: HttpClient,
  ) {
  }

  public getShows(): Observable<Show[]> {
    return this.http.get<Show[]>(`${this.baseUrl}`);
  }
  public getShow(id: string): Observable<Show> {
    return this.http.get<Show>(`${this.baseUrl}/${id}`).pipe(
      tap(showData => {
        this.show = showData; // Update the show variable with fetched data
      })
    );
  }

  public getStoredShow(): Show | null {
    return this.show; // Getter method to access the stored show data
  }
  public getStoredEpisodeQuestions(): any | null {
    return this.episode?.questions; // Getter method to access the stored show data
  }
  public setEpisode(episode: Episode): void{
    this.episode = episode
    this.episodeDataUpdated.next();

  }
  public getEpisode(): Episode | null{
    return this.episode
  }
  onEpisodeDataUpdated() {
    return this.episodeDataUpdated.asObservable();
  }

  public create(show: Show): Observable<Show> {
    return this.http.post<Show>(`${this.baseUrl}`, show);
  }

  public update(id: string | undefined, show: Show): Observable<any> {
    if(id !== undefined){
      return this.http.put<any>(`${this.baseUrl}/${id}`, show);
    }
    return of()
  }

  public delete(id: string | undefined, show: Show): Observable<any> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`, {body: show});
  }

}
