import {
  AfterViewInit, ChangeDetectorRef,
  Component, ElementRef,
  HostListener,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2, ViewChild
} from '@angular/core';
import {EventType, NavigationEnd, Router, RouterLink} from "@angular/router";
import {fromEvent, Subscription, throttleTime} from "rxjs";
import {Categories} from "../../models/categories.model";
import {CategoriesProperty} from "../../models/categories-property.enum";
import {AuthService} from "../../services/auth.service";
import {CategoriesService} from "../../services/categories.services";
import {UserService} from "../../services/user.service";
import {ReturnUrlService} from "../../services/return-url.service";
import {User} from "../../models/user.model";
import {
  AsyncPipe,
  isPlatformBrowser,
  NgClass,
  NgForOf,
  NgIf,
  NgSwitch,
  ViewportScroller
} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {Route} from "../../constants/route.constants";

@Component({
  selector: 'app-hayat-ba-header',
  templateUrl: './hayat-ba-header.component.html',
  imports: [
    RouterLink,
    NgIf,
    NgSwitch,
    MatIconModule,
    MatButtonModule,
    NgForOf,
    AsyncPipe,
    MatMenuModule,
    NgClass
  ],
  standalone: true,
  styleUrls: ['./hayat-ba-header.component.scss']
})

export class HayatBaHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  sidebarOpen: boolean = false;

  doesTokenExist: boolean = false;
  profile: User | undefined


  public top: number = 300;

  public categoriesProperty = CategoriesProperty;

  public categories: Categories[] | undefined;

  public currentRoute: string = '';

  public threeSeconds = false;

  private scrollEvent!: EventType.Scroll;

  private scrollSubscription!: Subscription;



  public containerStyles = 'center'


  // private window: Window;

  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private router: Router,
    public authService: AuthService,
    private returnUrlService: ReturnUrlService,
    public categoriesService: CategoriesService,
    public userService: UserService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private viewportScroller: ViewportScroller,
  ) {
    // this.window = this.document.defaultView;

    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
    });
  }

  private routerSubscription: Subscription | undefined;
  private scrollEnabled: boolean = false; // New flag to control when scroll changes start

  async ngOnInit(): Promise<void> {
    if (isPlatformBrowser(this.platformID)) {

      this.routerSubscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.resetHeaderHeight();
        }
      });
      setTimeout(() => {
        this.scrollEnabled = true;
      }, 3000);

      this.categoriesService.getCategories().subscribe(
        (response: Categories[]) => {
          this.categories = response;
        }
      );

      this.scrollEvent = EventType.Scroll;
    }
  }




  @HostListener('window:scroll')
  onScroll() {
    if (this.scrollEnabled) {
      // @ts-ignore
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.top = Math.max(300 - scrollTop, 0);
    }
  }

  @HostListener('window:resize')
  onResize() {
    // this.adjustCategoryAlignment();
  }

  @ViewChild('categoriesContainer') categoriesContainer!: ElementRef;


  adjustCategoryAlignment() {
    console.log('checking')
    const container = this.categoriesContainer.nativeElement;
    const totalContentWidth: number = container.scrollWidth;

    console.log('Total content width: ', totalContentWidth)
    console.log('Total container width: ', container.offsetWidth)
    if (totalContentWidth <= container.offsetWidth) {
      container.style.justifyContent = 'center';
    } else {
      container.style.justifyContent = 'flex-start';
    }
    // this.cdr.detectChanges();
  }


  resetHeaderHeight() {
    this.top = 300; // Reset the height to 300px on route change
    this.scrollEnabled = false; // Disable scroll changes during the initial delay
    setTimeout(() => {
      this.scrollEnabled = true;
    }, 3000); // Re-enable scroll changes after 3 seconds
  }


  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      const scriptElement1 = this.renderer.createElement('script');
      const scriptContent = `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1732793560324-0'); });`;
      scriptElement1.text = scriptContent;
      this.renderer.setAttribute(scriptElement1, 'id', 'google-tag-script-header');
      this.renderer.appendChild(document.body, scriptElement1);
    }
  }

  private isScriptLoaded = false;

  public setCategory(category: Categories): void {
    this.categoriesService.category = category;
    this.categoriesService.page = 1;
  }


  public customSetCategory(): void {
    var model: Categories = {
      [CategoriesProperty.name]: "Najnovije",
      [CategoriesProperty.category_url]: "najnovije",
      [CategoriesProperty.order_number]: 0,
      [CategoriesProperty.size]: 0,
      [CategoriesProperty.color_code]: '#000',
    };

    this.categoriesService.category = model;
    this.categoriesService.page = 1;
  }


  public setCurrentRoute(category: string): void {
    this.currentRoute = category;
  }


  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  }

  public customSetCategorySidebar(): void {
    var model: Categories = {
      [CategoriesProperty.name]: "Najnovije",
      [CategoriesProperty.category_url]: "najnovije",
      [CategoriesProperty.order_number]: 0,
      [CategoriesProperty.size]: 0,
      [CategoriesProperty.color_code]: '#000',
    };

    this.categoriesService.category = model;
    this.categoriesService.page = 1;
    this.toggleSidebar()
  }

  public setCategorySidebar(category: Categories): void {
    this.categoriesService.category = category;
    this.categoriesService.page = 1;
    this.toggleSidebar()
  }

  ngOnDestroy() {
    // Unsubscribe when component destroys
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  protected readonly Router = Router;
  protected readonly Route = Route;
}
