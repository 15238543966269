import {ChangeDetectorRef, Component, ElementRef, Inject, input, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {CommonModule, isPlatformBrowser, NgClass, NgStyle} from "@angular/common";

@Component({
  selector: 'app-themed-advertisement',
  imports: [
    NgStyle,
    NgClass
  ],
  templateUrl: './themed-advertisement.component.html',
  standalone: true,
  styleUrl: './themed-advertisement.component.scss',
  animations: [
    trigger('fadeInUp', [
      state('hidden', style({opacity: 0, transform: 'translateY(60px)'})),
      state('visible', style({opacity: 1, transform: 'translateY(0px)'})),
      transition('hidden => visible', [
        animate('0.5s ease-out')
      ], {delay: '{{ delay }}s'}) // Default delay 0.2s
    ])
  ]
})
export class ThemedAdvertisementComponent implements OnInit {
  @Input()
  size: string | undefined;

  isVisible: boolean = false; // 👈 Controls animation trigger
  animationDelay: string = '0.5'; // 👈 Controls delay for animation
  private observer!: IntersectionObserver;


  advertisements: any[] =[
    {
      type: 'custom',
      name: 'stilzivotauislamuknjiga',
      link: 'https://hayatmediabih.myshopify.com/products/stil-zivota-u-islamu?fbclid=PAZXh0bgNhZW0CMTEAAabnWmYH0zX33Nrxwbn0o1ZXud-oQSVwMMWYu4ozfE7eQpqSSSPYgYpPW-w_aem_CZEabImXvRQY3kaMYF0l9Q',
      image: 'https://www.hayat.ba/images/banner/knjiga.jpg',
      button: 'Kupi Odmah',
      text: "Knjiga 'Stil života u islamu' od sada je dostupna i kao e-book",
      buttonColor: '#a33a65',
      textColor: '',
      priority: 4,
    },
    {
      type: 'show',
      name: 'zabavnojeznati',
      link: 'https://www.hayat.ba/play',
      image: '',
      button: '',
      text: '',
      buttonColor: '',
      textColor: '',
      priority: 2,

    },
    {
      type: 'show',
      name: 'ddbih',
      link: 'https://www.hayat.ba/play',
      image: '',
      button: '',
      text: '',
      backgroundColor: '',
      buttonColor: '',
      textColor: '',
      priority: 1,

    },
    {
      type: 'show',
      name: '7plus',
      link: 'https://www.hayat.ba/play',
      image: '',
      button: '',
      text: '',
      backgroundColor: '',
      buttonColor: '',
      textColor: '',
      priority: 1,

    },
    {
      type: 'show',
      name: 'ramazanskiprogram',
      link: 'https://www.hayat.ba/play',
      image: '',
      button: '',
      text: '',
      backgroundColor: '',
      buttonColor: '',
      textColor: '',
      priority: 2,

    },
    {
      type: 'show',
      name: 'ispunimizelju',
      link: 'https://www.hayat.ba/play',
      image: '',
      button: '',
      text: '',
      backgroundColor: '',
      buttonColor: '',
      textColor: '',
      priority: 1,

    },
    {
      type: 'show',
      name: 'mjestopovratka',
      link: 'https://www.hayat.ba/play',
      image: '',
      button: '',
      text: '',
      backgroundColor: '',
      buttonColor: '',
      textColor: '',
      priority: 1,

    }
  ]
  protected advertShow: any

  protected toDisplay = ''

  constructor(
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformID: Object
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {

      // this.toDisplay = this.getRandomShow();
      this.pickRandomAd()
      this.calculateAnimationDelay(); // Calculate delay based on position
      this.cdr.detectChanges();
      this.observeElement();
    }
  }

  pickRandomAd() {
    let weightedAds: any[] = [];

    // Expand di array based on priority
    this.advertisements.forEach(ad => {
      for (let i = 0; i < ad.priority; i++) {
        weightedAds.push(ad);
      }
    });

    // Pick random ad from di weighted list
    const randomIndex = Math.floor(Math.random() * weightedAds.length);
    this.advertShow = weightedAds[randomIndex];
  }




  calculateAnimationDelay() {
    const rect = this.el.nativeElement.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    // Normalize delay based on position in viewport (0s to 1s max delay)
    this.animationDelay = (Math.min(rect.top / viewportHeight, 1)).toFixed(2); // Max 1s delay
  }

  observeElement() {
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !this.isVisible) {
          this.calculateAnimationDelay(); // Calculate delay when in view
          this.isVisible = true;
          this.cdr.detectChanges();
          this.observer.disconnect(); // Stop observing after animation starts
        }
      },
      {threshold: 0.5} // Trigger when at least 20% of the element is visible
    );

    this.observer.observe(this.el.nativeElement);
  }

}
