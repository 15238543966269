<div class="login">
  <h1>Upišite vaš novi password ispod</h1>
  <form (ngSubmit)="resetPassword()">
    <label for="newPassword">New Password</label>
    <input
      type="password"
      id="newPassword"
      [(ngModel)]="newPassword"
      name="newPassword"
      required
    />
    <button type="submit">Reset Password</button>
  </form>
</div>
