import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {Show} from "../../../../models/show.model";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatIcon} from "@angular/material/icon";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Chair} from "../../../../models/chair.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ChairService} from "../../../../services/chair.service";
import {ChairProperty} from "../../../../models/chair-property.enum";
import {ShowsComponent} from "../../../hayat-play/shows/shows.component";
import {ShowProperty} from "../../../../models/show-property.enum";
import {ShowService} from "../../../../services/show.services";

@Component({
    selector: 'app-crate-show-dialog',
    imports: [
        MatCheckbox,
        MatIcon,
        ReactiveFormsModule
    ],
    templateUrl: './create-show-dialog.component.html',
    styleUrl: './create-show-dialog.component.scss'
})
export class CreateShowDialogComponent {
  @Output() showCreated = new EventEmitter<Show>();
  showForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateShowDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public show: Show,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private showService: ShowService,
  ) {
    this.showForm = this.formBuilder.group({
      _id: [this.show?.[ShowProperty.id] || ''],
      name: [this.show?.[ShowProperty.name] || '', Validators.required],
      about: [this.show?.[ShowProperty.about] || ''],
      logo: [this.show?.[ShowProperty.logo] || ''],
      chatEnabled: [this.show?.[ShowProperty.chatEnabled] || false],
    });
  }

  onSubmit() {
    if(this.show){
      //this.showService.update(this.showForm.value)
    }
    if (this.showForm.valid) {
      const formData = this.showForm.value;
      // Send the form data to the backend
      this.showService.create(formData).subscribe(
        (result:any) => {
          this.showCreated.emit(result.chair); // Emit the event with the created editor
          this.dialogRef.close();
        },
        (error) => {
          console.error('Error creating survey:', error);
          // Handle the error as needed
        }
      );
    }
  }
}
