<div
  class="tag"
  [ngClass]="{
    'skeleton': tag === '',
  }">
  <p
    [style.font-size.px]="fontSize"
    [style.font-family]="fontFamily"
  >{{tag}}</p>
</div>
