import { Component } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {User} from "../../../../models/user.model";
import {Router, RouterLink} from "@angular/router";
import {UserService} from "../../../../services/user.service";
import {ReturnUrlService} from "../../../../services/return-url.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-user-restart',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './user-restart.component.html',
  standalone: true,
  styleUrl: './user-restart.component.scss'
})
export class UserRestartComponent {
  pathToSignup:string = '/signup';

  form!: FormGroup;
  showSuccessMessage = false;

  public user: Observable<User> | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private returnUrlService: ReturnUrlService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  handleFormSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const email = this.form.get('email')?.value;

      this.userService.restartPassword(email).subscribe(
        (response: any) => {
          console.log(response)
          if (response.message === 'Password reset email sent.') {
            // Display the success message
            this.showSuccessMessage = true;

            // Redirect to the home page after 5 seconds
            setTimeout(() => {
              this.router.navigate(['/']);
            }, 5000);
          } else {
            // Handle unexpected responses
            alert('Unexpected response from the server.');
          }
        },
        (error) => {
          console.error('Error restarting password:', error);
          alert('Error restarting password. Please try again.');
        }
      );
    } else {
      this.form.setErrors({ unauthenticated: true });
    }
  }


  isFieldMissing(fieldName: string) {
    const control = this.form.get(fieldName);
    return control?.invalid && control?.touched;
  }
}

