import { Component } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../../../../services/user.service";

@Component({
    selector: 'app-restart-user-password',
    imports: [
        FormsModule,
        ReactiveFormsModule,
        RouterLink
    ],
    templateUrl: './restart-user-password.component.html',
    styleUrl: './restart-user-password.component.scss'
})
export class RestartUserPasswordComponent {
  token: string = '';
  newPassword: string = '';

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private userService: UserService
  ) {
    this.token = this.route.snapshot.params['token'];
  }

  resetPassword(): void {
    this.userService.passwordRestart(this.token, this.newPassword).subscribe(
      () => {
        alert('Password reset successfully.');
        this.router.navigate(['/login']); // Redirect to the login page
      },
      (error) => {
        console.error('Error resetting password:', error);
        alert('Error resetting password. Please try again.');
      }
    );
  }
}
