import { Component } from '@angular/core';

@Component({
    selector: 'app-channels-info',
    imports: [],
    templateUrl: './channels-info.component.html',
    styleUrl: './channels-info.component.scss'
})
export class ChannelsInfoComponent {

}
