import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Articles} from "../../../models/articles.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ArticlesService} from "../../../services/articles.service";
import {ArticlesProperty} from "../../../models/articles-property.enum";
import {
  SurveyConfirmationDialogComponent
} from "../../survey/survey-confirmation-dialog/survey-confirmation-dialog.component";
import {ConfirmationDialogComponent} from "../../../dialogs/confirmation-dialog/confirmation-dialog.component";
import {RouterLink} from "@angular/router";
import {KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import { KeyDescOrderPipe } from '../../../pipes/key-articles-order.pipe';
import {Chair} from "../../../models/chair.model";
import {EditorsServices} from "../../../services/editors.services";
import {ChairProperty} from "../../../models/chair-property.enum"; // import the custom pipe


@Component({
  selector: 'app-administrator-analytics',
  templateUrl: './administrator-analytics.component.html',
  imports: [
    RouterLink,
    NgForOf,
    NgIf,
    FormsModule,
    KeyValuePipe,
  ],
  standalone: true,
  styleUrls: ['./administrator-analytics.component.scss']
})
export class AdministratorAnalyticsComponent implements OnInit, AfterViewInit{


  articles!: Articles[];
  deletedArticles!: Articles[];

  displayArticles!: Articles[];
  // groupedArticles: { [key: string]: Articles[] } = {};
  groupedArticles: { date: string, articles: Articles[] }[] = [];


  editors!: Chair[];
  selectedEditor: string = '';  // For user filtering


  groupBy: string = 'day';    // For grouping by day or month


  private page = 1;
  private articlesPerPage = 15;
  private newArticlesLoading: boolean = false
  private noMoreArticles: boolean = false

  selectedFilter: string = 'active';

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private articlesService: ArticlesService,
    private editorsServices: EditorsServices,

  ) {
  }
  ngOnInit(): void {
    this.articlesService.getArticlesAnalytics(1).subscribe(
      (articles: Articles[]) => {
        this.articles = articles; // Assign the retrieved courses to the courses property
        this.displayArticles = articles; // Assign the retrieved courses to the courses property
        if (this.groupBy === 'day') {
          this.groupArticlesByDay(this.articles);
        } else if (this.groupBy === 'month') {
          this.groupArticlesByMonth(this.articles);
        }
        if(articles.length < this.articlesPerPage && this.selectedFilter === 'active')
        {
          this.noMoreArticles = true;
        }


      },(error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error getting editors:', error);
      });
  }

  ngAfterViewInit() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const stickyHeader = entry.target as HTMLElement;

        // If the element is leaving the viewport from the top, we fix it at the top
        if (entry.intersectionRatio < 1 && entry.boundingClientRect.top < 0) {
          stickyHeader.classList.add('fixed');
        } else {
          stickyHeader.classList.remove('fixed');
        }
      });
    }, { threshold: [0, 1], rootMargin: "-50px 0px 0px 0px" }); // Adjust rootMargin for precise control

  }

  groupByTime(): void {
    this.groupedArticles = [];
    if (this.groupBy === 'day') {
      this.groupArticlesByDay(this.articles);
    } else if (this.groupBy === 'month') {
      this.groupArticlesByMonth(this.articles);
    }
  }


  objectKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }


  groupArticlesByDay(newArticles: Articles[] = []): void {
    const tempGroupedArticles: { [key: string]: Articles[] } = {};

    // Group articles by 'YYYY Month DD' key
    newArticles.forEach((article: Articles) => {
      const date = new Date(article.create_date);
      const dayMonthYear = `${date.getFullYear()} ${date.toLocaleString('default', { month: 'long' })} ${String(date.getDate()).padStart(2, '0')}`;

      // Initialize group if it doesn't exist
      if (!tempGroupedArticles[dayMonthYear]) {
        tempGroupedArticles[dayMonthYear] = [];
      }

      // Add article to the corresponding day group
      tempGroupedArticles[dayMonthYear].push(article);
    });

    // Merge the new articles with the existing grouped articles
    this.groupedArticles.forEach(existingGroup => {
      if (tempGroupedArticles[existingGroup.date]) {
        // If the day already exists, append the new articles
        tempGroupedArticles[existingGroup.date] = [
          ...existingGroup.articles,
          ...tempGroupedArticles[existingGroup.date]
        ];
      } else {
        // If the day doesn't exist in the new articles, retain the old one
        tempGroupedArticles[existingGroup.date] = existingGroup.articles;
      }
    });

    // Now transform the object into an array to allow sorting
    this.groupedArticles = Object.keys(tempGroupedArticles).map(key => {
      return {
        date: key, // 'YYYY Month DD' format
        articles: tempGroupedArticles[key],
      };
    });

    // Sort the array by the actual date in descending order
    this.groupedArticles.sort((a, b) => {
      const dateA = new Date(a.articles[0].create_date).getTime(); // Sort based on the first article's date
      const dateB = new Date(b.articles[0].create_date).getTime();
      return dateB - dateA; // Newest to oldest
    });

    console.log(this.groupedArticles);
  }

  groupArticlesByMonth(newArticles: Articles[] = []): void {
    const tempGroupedArticles: { [key: string]: Articles[] } = {};

    // Group the new articles by 'YYYY Month'
    newArticles.forEach((article: Articles) => {
      const date = new Date(article.create_date);
      const monthYear = `${date.getFullYear()} ${date.toLocaleString('default', { month: 'long' })}`;

      // Initialize group if it doesn't exist
      if (!tempGroupedArticles[monthYear]) {
        tempGroupedArticles[monthYear] = [];
      }

      // Add the article to the corresponding month-year group
      tempGroupedArticles[monthYear].push(article);
    });

    // Merge the new articles with the existing grouped articles
    this.groupedArticles.forEach(existingGroup => {
      if (tempGroupedArticles[existingGroup.date]) {
        // If the month already exists, append the new articles
        tempGroupedArticles[existingGroup.date] = [
          ...existingGroup.articles,
          ...tempGroupedArticles[existingGroup.date]
        ];
      } else {
        // If the month doesn't exist in the new articles, retain the old one
        tempGroupedArticles[existingGroup.date] = existingGroup.articles;
      }
    });

    // Now transform the tempGroupedArticles back into an array and sort them
    this.groupedArticles = Object.keys(tempGroupedArticles).map(key => ({
      date: key,
      articles: tempGroupedArticles[key]
    }));

    // Sort the array by the actual date (using the first article's date in each group)
    this.groupedArticles.sort((a, b) => {
      const dateA = new Date(a.articles[0].create_date).getTime();
      const dateB = new Date(b.articles[0].create_date).getTime();
      return dateB - dateA; // Newest to oldest
    });

    console.log(this.groupedArticles);
  }


  onScroll(): void {
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.scrollHeight;
    const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

    const atBottom = windowHeight + scrollPosition >= documentHeight;

    if (atBottom && !this.newArticlesLoading && !this.noMoreArticles) {
      this.newArticlesLoading = true
      this.loadMoreArticles();
    }
  }

  getArticlesByEditor(): void{
    this.articlesService.getArticlesByEditor(this.selectedEditor, this.page).subscribe(
      (articles: any[]) => {
        console.log(articles)
        this.articles = [...this.articles, ...articles];
        this.displayArticles = [...this.articles, ...articles];

        if (this.groupBy === 'day') {
          this.groupArticlesByDay(articles);
        } else if (this.groupBy === 'month') {
          this.groupArticlesByMonth(articles);
        }

        if(articles.length < this.articlesPerPage)
        {
          this.noMoreArticles = true;
        }
        this.newArticlesLoading = false
      },
      (error) => {
        console.error('Error fetching expired surveys:', error);
      }
    );
  }

  getAllArticles(): void{
    this.articlesService.getAllArticlesByDate(this.page).subscribe(
      (articles: Articles[]) => {
        // Append the new articles to the existing list
        this.articles = [...this.articles, ...articles];
        this.displayArticles = [...this.articles, ...articles];

        if (this.groupBy === 'day') {
          this.groupArticlesByDay(articles);
        } else if (this.groupBy === 'month') {
          this.groupArticlesByMonth(articles);
        }

        if(articles.length < this.articlesPerPage)
        {
          this.noMoreArticles = true;
        }
        this.newArticlesLoading = false
      },
      (error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error getting more articles:', error);
      }
    );
  }

  loadMoreArticles(): void {
    // Increment the current page
    this.page++;
    console.log(this.selectedEditor)
    if(this.selectedEditor){
      console.log('EDITOR')
      this.getArticlesByEditor()
    }
    else if(this.selectedFilter == 'active')
    {
      this.getAllArticles()
    }

  }


  filterByUser(): void {
    this.page = 1
    this.groupedArticles = [];
    this.getArticlesByEditor()
    // console.log(this.selectedEditor)
    // if (this.selectedEditor) {
    //   // Filter the existing articles by matching the editor with the selected user
    //
    //   this.groupedArticles = {};
    //   if (this.groupBy === 'day') {
    //     this.groupArticlesByDay(filteredArticles);
    //   } else if (this.groupBy === 'month') {
    //     this.groupArticlesByMonth(filteredArticles);
    //   }
    // }
    // else {
    //   if (this.groupBy === 'day') {
    //     this.groupArticlesByDay(this.articles);
    //   } else if (this.groupBy === 'month') {
    //     this.groupArticlesByMonth(this.articles);
    //   }
    // }
  }

  deleteArticle(article: Articles): void {
    if(this.selectedFilter === 'inactive'){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '300px',
        data: {
          title: 'Da li ste sigurni da želite izbrisati članak?',
          message: 'Članak će biti izbrisan, i više se neće moci povratiti.',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.articlesService.delete(article[ArticlesProperty.id]).subscribe(
            (response) => {
              // Remove the deleted article from the articles array
              this.articles = this.articles.filter(a => a !== article);
            },
            (error) => {
              // Handle error, e.g., display an error message
              console.error('Error archiving article:', error);
            }
          );
        }
      });
    }
    else{
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '300px',
        data: {
          title: 'Da li ste sigurni da želite izbrisati članak?',
          message: 'Članak će privremeno biti izbrisan, a nakon 30 dana se više neće moci povratiti.',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.articlesService.archive(article[ArticlesProperty.id]).subscribe(
            (response) => {
              // Remove the deleted article from the articles array
              this.articles = this.articles.filter(a => a !== article);
            },
            (error) => {
              // Handle error, e.g., display an error message
              console.error('Error archiving article:', error);
            }
          );
        }
      });
    }

  }

  getActive(filter: string) {
    this.selectedFilter = filter
    this.displayArticles = this.articles
    if(this.articles.length < this.articlesPerPage)
    {

      this.noMoreArticles = true;
    }
  }
  getDelete(filter: string) {
    this.selectedFilter = filter
    this.displayArticles = this.deletedArticles
    if(this.deletedArticles.length < this.articlesPerPage)
    {

      this.noMoreArticles = true;
    }
  }




  protected readonly ArticlesService = ArticlesService;
  protected readonly ArticlesProperty = ArticlesProperty;
  protected readonly KeyDescOrderPipe = KeyDescOrderPipe;
}
