@if (advertShow.type === 'show') {
  <a class="advertisement"
     [ngClass]="'s' + size"
     href="{{advertShow.link}}"
     [@fadeInUp]="{ value: isVisible ? 'visible' : 'hidden', params: { delay: animationDelay } }"
  >
    <img class="show-image" src="https://www.hayat.ba/images/banner/{{advertShow.name}}-{{size}}.jpg">
  </a>
} @else if (advertShow.type === 'custom') {
  <div class="advertisement"
       [ngClass]="'s' + size"
       [@fadeInUp]="{ value: isVisible ? 'visible' : 'hidden', params: { delay: animationDelay } }"
  >
    <img class="custom-add" [src]="'https://www.hayat.ba/images/banner/' + advertShow.name + '-' + size + '.jpg'"
         alt="Ad Background">
    <div class="content-container">
      <img class="custom-image" src="{{advertShow.image}}">
      <div class="text">
        <p>{{ advertShow.text }}</p>
        <a class="button"
           style="background-color: {{advertShow.buttonColor}}"
           [href]="advertShow.link">{{ advertShow.button }}</a>
      </div>
    </div>
  </div>

}
