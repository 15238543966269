<div class="information">
  <div class="icons">
<!--    <ng-container >-->
<!--      <div class="icon-view">-->
<!--        <i class="fa fa-eye" [ngStyle]="{color: colorScheme === 'light' ? '#000' : '#fff'}"></i>-->
<!--        <span>{{ views }}</span>-->
<!--      </div>-->
<!--    </ng-container>-->

    <ng-container *ngIf="icons?.['youtubeIcon']">
      <i class="fa-brands fa-youtube" style="color: red;"></i>
    </ng-container>
    <ng-container *ngIf="icons?.['instagramIcon']">
      <i class="fa-brands fa-instagram" style="color: #E4405F;"></i>
    </ng-container>
    <ng-container *ngIf="icons?.['facebookIcon']">
      <i class="fa-brands fa-facebook" [ngStyle]="{color: color}"></i>
    </ng-container>
    <ng-container *ngIf="icons?.['sofascoreIcon']">
      <i class="fa fa-soccer-ball-o" [ngStyle]="{color: colorScheme === 'dark' ? '#FFF' : '#000'}"></i>
    </ng-container>
    <ng-container *ngIf="icons?.['xIcon']">
      <i class="fa-brands fa-x-twitter" [ngStyle]="{color: colorScheme === 'dark' ? '#FFF' : '#000'}"></i>
    </ng-container>
    <ng-container *ngIf="icons?.['tiktokIcon']">
      <i class="fa-brands fa-tiktok" [ngStyle]="{color: colorScheme === 'dark' ? '#FFF' : '#000'}"></i>
    </ng-container>
    <ng-container *ngIf="icons?.['videoIcon']">
      <i class="fa fa-video-camera" [ngStyle]="{color: color}"></i>
    </ng-container>
    <ng-container *ngIf="icons?.['galleryIcon']">
      <i class="fa-regular fa-images" [ngStyle]="{color: color}"></i>
    </ng-container>
    <ng-container *ngIf="icons?.['photoIcon'] && !icons?.['galleryIcon']">
      <i class="fa fa-camera" [ngStyle]="{color: color}"></i>
    </ng-container>
    <ng-container *ngIf="icons?.['surveyIcon']">
      <i class="fa fa-comments" [ngStyle]="{color: color}"></i>
    </ng-container>
  </div>
  <div class="date">
    <p>{{ date }}</p>
    <p>{{ time }}</p>
  </div>
</div>
