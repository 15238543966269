import {Component, OnInit} from '@angular/core';
import {CategoriesProperty} from "../../../models/categories-property.enum";
import {Categories} from "../../../models/categories.model";
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {CategoriesService} from "../../../services/categories.services";
import {filter} from "rxjs";
import {ChairService} from "../../../services/chair.service";
import {NgIf} from "@angular/common";
import {Chair} from "../../../models/chair.model";
import {ChairProperty} from "../../../models/chair-property.enum";

@Component({
    selector: 'app-administrator-header',
    templateUrl: './administrator-header.component.html',
    imports: [
        RouterLink,
        NgIf
    ],
    styleUrls: ['./administrator-header.component.scss']
})
export class AdministratorHeaderComponent implements OnInit{
  public top: number | undefined;

  public categoriesProperty = CategoriesProperty;

  public categories!: Categories[];

  public selectedRoute: string = '';

  chair!: Chair;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    public categoriesService: CategoriesService,
    public chairService: ChairService,
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.selectedRoute = ((event as NavigationEnd).url)?.substring(1);
    });
  }


  ngOnInit(): void {
    // setTimeout(() => {
    //   this.addScrollListener();
    // }, 5000);
    this.chairService.getChair().subscribe(
      (chair: Chair) =>{
        this.chair = chair
        console.log(chair)
      }
    )
  }

  // addScrollListener() {
  //   // Add the scroll event listener
  //   window.addEventListener('scroll', this.onWindowScroll.bind(this));
  // }
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition < 250) {
      this.top = 250 - scrollPosition;
    }
    else {
      // Reset the 'top' position when not scrolling
      this.top = 0;
    }
  }

  public tokenExists(): boolean {
    return this.authService.getToken() != null;
  }

  public chairIsAdmin(): boolean {
    return this.chairService.isAdmin();
  }

  public logoutUser(): void{
    this.authService.logout();
    this.chairService.logout();
    this.router.navigateByUrl( '/login/admin');

  }


  public setRoute(route: string){
    this.selectedRoute = route;
  }

  public setCategory(category: Categories): void {
    this.categoriesService.category = category;
    this.categoriesService.page = 1;
  }

  public checkUrlCategory(): void {
    var selected: string = this.selectedRoute
    /*if (selected.substring(0))*/
  }

  protected readonly ChairProperty = ChairProperty;
}
