import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {Surveys} from "../models/surveys.model";
import {cdkUpgradeData} from "@angular/cdk/schematics";
import {environment} from "../../environments/environment";


@Injectable({providedIn: 'root'})
export class SurveysService {

  private readonly baseUrl: string = `${environment.backendUrl}/surveys`;

  private readonly localStorageKeyPrefix = 'surveyResponse_';
  private readonly cookieKeyPrefix = 'surveyResponse_';


  constructor(
    private http: HttpClient,
  ) {
  }

  public getSurveys(): Observable<Surveys[]> {
    return this.http.get<Surveys[]>(`${this.baseUrl}`);
  }

  public getExpiredSurveys(page: number): Observable<Surveys[]> {
    return this.http.get<Surveys[]>(`${this.baseUrl}/expired/${page}`);
  }

  public getSurveysByType(type: string): Observable<Surveys[]> {
    /*return this.http.get<Articles[]>(`${this.baseUrl}/${this._categoryName}/${this._pageNumber}`);*/
    return this.http.get<Surveys[]>(`${this.baseUrl}/${type}`);
  }
  public getSurveysById(id: string): Observable<Surveys> {
    return this.http.get<Surveys>(`${this.baseUrl}/${id}`);
  }


  public create(survey: Surveys): Observable<Surveys> {
    return this.http.post<Surveys>(`${this.baseUrl}`, survey);
  }




  public update(id: string, survey: Surveys): Observable<Surveys> {
    return this.http.put<Surveys>(`${this.baseUrl}/${id}`, survey);
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }


  /*User data included*/
  public updateSurveyResponse(id: string | undefined, data: any): Observable<Surveys> {
    return this.http.post<Surveys>(`${this.baseUrl}/${id}/response`, data);
  }
  /*No user data*/
  public increaseResponse(id: string, response: number | null): Observable<Surveys> {
    if(response == null)
    {
      return throwError('Response is null. Cannot proceed with the request.');
    }
    const requestBody = { response }; // Wrap response in an object
    return this.http.post<Surveys>(`${this.baseUrl}/response/${id}`, requestBody);
  }

  public generateGiftWinner(id: string | undefined, numberOfWinners: number): Observable<any> {
    const data = {numberOfWinners}
    return this.http.post<any>(`${this.baseUrl}/${id}/generate`, data);
  }

  /*hasUserTakenSurvey(surveyId: string): number | null {
    const key = this.localStorageKeyPrefix + surveyId;
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : null;
  }
*/
  hasUserTakenSurvey(surveyId: string): number | null {
    const key = this.cookieKeyPrefix + surveyId;

    // Retrieve the stored value from cookies
    const allCookies = document.cookie.split(';');
    for (let i = 0; i < allCookies.length; i++) {
      const cookie = allCookies[i].trim();

      // Check if the cookie is the survey cookie
      if (cookie.startsWith(`${key}=`)) {
        const cookieValue = cookie.substring(key.length + 1);
        return JSON.parse(cookieValue);
      }
    }

    // If the survey cookie is not found, return null
    return null;
  }


  getSurveyResponse(surveyId: string): number | null {
    const key = this.localStorageKeyPrefix + surveyId;
    const response = localStorage.getItem(key);
    return response ? parseInt(response, 10) : null;
  }

  /*setSurveyResponse(surveyId: string, response: number | null): Observable<boolean> {
    const key = this.localStorageKeyPrefix + surveyId;
    if (response !== null) {
      let answer = response;
      localStorage.setItem(key, JSON.stringify(answer));
    } else {
      localStorage.removeItem(key);
    }
    return of(true);
  }*/

  setSurveyResponse(surveyId: string, response: number | null): Observable<boolean> {
    const key = this.cookieKeyPrefix + surveyId;

    if (response !== null) {
      let answer = response;

      // Set the survey response as a cookie with an expiration date (e.g., 7 days)
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);

      document.cookie = `${key}=${JSON.stringify(answer)}; expires=${expirationDate.toUTCString()}; Secure; SameSite=None; path=/`;

    } else {
      // Remove the cookie
      document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=None; path=/`;
    }

    return of(true);
  }

}
