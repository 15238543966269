<div class="survey-fs">
  <div class="survey-component">
    <div class="survey">
      <!--SURVEY-->
      <form *ngIf="survey?.[surveysProperty.surveyType] == 'survey'">
        <div class="top">
          <div class="transparent-parallelogram">
            <p>Anketa</p>
            <div class="countdown">
              <p *ngIf="timeRemaining.days > 0">
                {{ formatNumber(timeRemaining.days) }}:{{ formatNumber(timeRemaining.hours) }}
                :{{ formatNumber(timeRemaining.minutes) }}:{{ formatNumber(timeRemaining.seconds) }}
              </p>
              <p
                *ngIf="timeRemaining.days === 0 && (timeRemaining.hours > 0 || timeRemaining.minutes > 0 || timeRemaining.seconds > 0)">
                {{ formatNumber(timeRemaining.hours) }}:{{ formatNumber(timeRemaining.minutes) }}
                :{{ formatNumber(timeRemaining.seconds) }}
              </p>
              <p *ngIf="oldDeadline()">00:00:00</p>
            </div>
          </div>
          <div class="share" (click)="copySurveyLink()">
            <i class="fa fa-share-alt"></i>
          </div>
        </div>
        <div class="middle">
          <p class="question">{{ survey?.question }}</p>

        </div>
        <!--Has user taken the survey?-->
        <div *ngIf="this.hasUserTakenSurvey === null && !oldDeadline(); else statistics">
          <div *ngFor="let answerOpt of survey?.answerOptions; let i = index" class="radio-container">
            <input type="radio"
                   name="surveyAnswer"
                   [value]="i"
                   [(ngModel)]="selectedAnswer"
                   [disabled]="hasUserTakenSurvey !== null"
                   id="radio{{ i }}"
                   (change)="submitSurvey()"
                   [checked]="hasUserTakenSurvey === i"
            />
            <label for="radio{{ i }}" [ngClass]="{'selected-answer': hasUserTakenSurvey === i}">
              {{ answerOpt.answer }}
            </label>
          </div>
        </div>
        <ng-template #statistics>
          <div *ngFor="let answer of survey?.answerOptions; let i = index"
               class="answer-option"
               [ngClass]="{'selected-answer': hasUserTakenSurvey === i}">
            <div class="answer-container">
              <p>{{ answer.answer }}</p>
            </div>
            <div class="percentage-bar-container">
              <div class="percentage-bar" [style.width.%]="calculatePercentage(answer.count, survey?.totalCount)">
              </div>
            </div>
            <div class="count-container">
              <p class="percentage-text">{{ calculatePercentage(answer.count, survey?.totalCount) | number:'1.0-2' }}
                %</p>
            </div>
          </div>
        </ng-template>
      </form>


      <!--TRIVIA-->
      @if (survey?.surveyType === 'trivia') {
        <form (ngSubmit)="openConfirmationDialog()"
              [@fadeInOut]="blur"
              [style.pointerEvents]="blur ? 'none' : 'auto'">
          <div class="top">
            <div class="text">
            <p>U susret Bajramu</p>

            </div>
            <div class="countdown">
              @if(timeRemaining.days > 0){
                <p>
                  {{ formatNumber(timeRemaining.days) }} dana i  {{ formatNumber(timeRemaining.hours) }}:{{ formatNumber(timeRemaining.minutes) }}:{{ formatNumber(timeRemaining.seconds) }}
                </p>
              }
              <p
                *ngIf="timeRemaining.days === 0 && (timeRemaining.hours > 0 || timeRemaining.minutes > 0 || timeRemaining.seconds > 0)">
                {{ formatNumber(timeRemaining.hours) }}:{{ formatNumber(timeRemaining.minutes) }}
                :{{ formatNumber(timeRemaining.seconds) }}
              </p>
              <p *ngIf="oldDeadline()">00:00:00</p>
            </div>
          </div>
          <div class="middle">
            <p class="question">{{ survey?.question }}</p>
          </div>

          <div *ngIf="!oldDeadline(); else correctAnswer" class="answer-options">
            <div *ngFor="let answerOpt of survey?.answerOptions; let i = index" class="radio-container">
              <input type="radio"
                     name="surveyAnswer"
                     [value]="i"
                     [(ngModel)]="selectedAnswer"
                     [disabled]="hasUserTakenTrivia !== null"
                     [checked]="hasUserTakenTrivia === i"
                     id="radio{{ i }}"
                     (click)="openConfirmationDialog()"
              />
              <!--<label [ngClass]="{'correct-answer': hasUserTakenTrivia && i === survey.correctAnswer, 'wrong-answer': hasUserTakenTrivia && i !== survey.correctAnswer}" for="radio{{ i }}">{{ answer }}</label>-->
              <label [ngClass]="{'correct-answer': hasUserTakenTrivia !== null && i === survey?.correctAnswer,
                            'selected-answer': hasUserTakenTrivia === i,
                            'wrong-answer': hasUserTakenTrivia === i && i !== survey?.correctAnswer}"
                     for="radio{{ i }}">
                {{ answerOpt.answer }}
              </label>
            </div>
          </div>

          <ng-template #correctAnswer>
            <div *ngFor="let answerOpt of survey?.answerOptions; let i = index" class="answer">
              <!--<input type="radio"
                     name="surveyAnswer"
                     [value]="i"
                     [(ngModel)]="selectedAnswer"
                     [disabled]="hasUserTakenTrivia !== null"
                     [checked]="hasUserTakenTrivia === i"
                     id="radio{{ i }}"
              />-->
              <label [ngClass]="{'correct-answer': i === survey?.correctAnswer,
                            'selected-answer': hasUserTakenTrivia === i,
                            'wrong-answer': hasUserTakenTrivia === i && i !== survey?.correctAnswer}"
                     for="radio{{ i }}">
                {{ answerOpt.answer }}
              </label>
            </div>
          </ng-template>
        </form>

      }

      <div class="login-prompt" *ngIf="blur">
        <p>Kako bi sudjelovali, potrebno je da se prijavite na svoj nalog putem ovog istog web pretraživača.</p>
        <button (click)="redirectToLogin()">Login</button>
      </div>
    </div>
  </div>
</div>
