import {Component, Inject} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SurveysService} from "../../../../services/survey.service";
import {NgClass, NgForOf} from "@angular/common";

@Component({
    selector: 'app-create-survey-dialog',
    templateUrl: './create-survey-dialog.component.html',
    imports: [
        ReactiveFormsModule,
        NgClass,
        NgForOf
    ],
    styleUrls: ['./create-survey-dialog.component.scss']
})
export class CreateSurveyDialogComponent {
  surveyForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateSurveyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private surveysService: SurveysService,
  ) {
    this.surveyForm = this.formBuilder.group({
      surveyType: ['survey', Validators.required],
      question: ['', Validators.required],
      answerOptions: this.formBuilder.array([
        this.formBuilder.control(''), // Add your initial answer options here
        this.formBuilder.control(''),
      ]),
      correctAnswer: [-1], // Default to -1 (for voting)
      deadline: [null, Validators.required], // Add the deadline control
    });
  }

  get answerOptions() {
    return this.surveyForm.get('answerOptions') as FormArray;
  }

  addAnswerOption() {
    if (this.answerOptions.length < 20) {
      this.answerOptions.push(this.formBuilder.control(''));
    }
  }

  selectSurvey() {
    this.surveyForm.patchValue({surveyType: 'survey'});
  }

  selectTrivia() {
    this.surveyForm.patchValue({surveyType: 'trivia'});
  }

  currentDateTime(): string {
    const now = new Date();
    // Format the date and time as a string that can be used in the datetime-local input
    return now.toISOString().slice(0, 16);
  }


  onSubmit() {
    if (this.surveyForm.valid) {
      const formData = this.surveyForm.value;

      // Convert the deadline string to a Date object
      formData.deadline = new Date(formData.deadline);

      // Send the form data to the backend
      this.surveysService.create(formData).subscribe(
        (result) => {
          this.dialogRef.close();
        },
        (error) => {
          console.error('Error creating survey:', error);
          // Handle the error as needed
        }
      );
    }
  }


}
