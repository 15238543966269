<div class="upload">
  <form [formGroup]="form" (keydown.enter)="$event.preventDefault()" class="form">
    <div class="left">
      <div class="input">
        <label>Naslov</label>
        <textarea class="title" [formControlName]="articlesProperty.title"
                  [value]="form.controls['title'].value"
                  placeholder="Unesi naslov"
                  maxlength="100"
                  rows="2"
        >
        </textarea>
      </div>

      <!--IMAGE UPLOAD-->

      <div class="image-container">
        <label>Media</label>
        <div *ngIf="imageLinks.length">
          <div class="carousel">
            <button class="prev-button" (click)="prev()"><i class="fa fa-angle-left"></i></button>
            <div class="carousel-item" *ngFor="let item of imageLinks; let i = index" [hidden]="i !== currentIndex">
              <!--<img [src]="item" alt="Carousel Item">-->
              <img *ngIf="!isVideo(item.url); else video" [src]="item.url">
              <ng-template #video>
                <video
                  style="margin: 0 10%; pointer-events: none;"
                  width="80%"
                  height="400px"
                  controls="controls autoplay">
                  <source [src]="item.url" type="video/mp4"/>
                </video>
              </ng-template>
            </div>
            <button class="next-button" (click)="next()"><i class="fa fa-angle-right"></i></button>
          </div>


          <div cdkDropList class="example-list" cdkDropListOrientation="horizontal"
               (cdkDropListDropped)="drop($event)">
            <div class="example-box" *ngFor="let link of imageLinks" cdkDragLockAxis="x" cdkDrag>
              <img *ngIf="!isVideo(link.url); else video" class="image_preview" src="{{link.url}}">
              <ng-template #video>
                <video
                  style="margin: 0 10%; pointer-events: none;"
                  width="80%"
                  height="400px"
                  controls="controls autoplay">
                  <source src="{{link.url}}" type="video/mp4"/>
                </video>
              </ng-template>
              <div class="overlay">
                <button (click)="copyCode(link.url)" class="edit-button">
                  <i class="fa fa-copy"></i>
                </button>
                <button (click)="removeImage(link.url)" class="remove-button">
                  <i class="fa fa-remove"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="image-input">
          <div class="file">
            <label for="file-upload" class="custom-file-upload">
              Upload Image
            </label>
            <input id="file-upload" type="file" multiple (change)="selectFiles($event)" accept=".png, .jpg, .jpeg, .webp"/>
          </div>
          <div class="link">
            <input type="text" [formControl]="newLinkControl" placeholder="Insert image or video link">
            <button (click)="insertLink(newLinkControl.value)">Insert</button>
          </div>
          <div class="server">
            <button (click)="openImageSelector()">Izaberi fotografiju</button>
          </div>
        </div>
      </div>

      <div class="input">
        <label>Podnaslov</label>
        <textarea class="subtitle"
                  [formControlName]="articlesProperty.subtitle"
                  [value]="articlesProperty.subtitle"
                  placeholder="Unesi podnaslov"
                  maxlength="180"
                  minlength="60"
                  rows="2"></textarea>
      </div>

      <label>Content</label>

      <div cdkDropList class="array-forms" formArrayName="textEditors" (cdkDropListDropped)="dropContent($event)">
        <ng-container *ngFor="let textEditor of getText.controls; let i = index">
          <div [formGroupName]="i" class="input-container" cdkDragLockAxis="y" cdkDrag>
            <div class="left-side">
              <div class="example-handle" cdkDragHandle>
                <i class="fa-solid fa-grip-lines"></i>
              </div>
              <!-- Display Angular Editor for textEditorForm -->

              <div *ngIf="isControlType(textEditor, 'regular')" class="text-editor">
              <textarea class="regular"
                        formControlName="regular"
                        placeholder="Regularni text"
                        cdkDragDisabled
              >
              </textarea>
              </div>

              <div *ngIf="isControlType(textEditor, 'bold')" class="text-editor">
              <textarea class="bold"
                        formControlName="bold"
                        placeholder="Boldirani text"
              >
              </textarea>
              </div>

              <div *ngIf="isControlType(textEditor, 'italic')" class="text-editor">
              <textarea class="italic"
                        formControlName="italic"
                        placeholder="Italic text"
              >
              </textarea>
              </div>

              <div *ngIf="isControlType(textEditor, 'link')" class="link-editor">
                <input class="regular"
                       formControlName="link"
                       placeholder="Insert URL"
                >
                <input class="regular"
                       formControlName="linkText"
                       placeholder="Insert text"
                >

                <div class="hyperlink">
                  <a class="link-style" href="{{textEditor.get('link')?.value}}" target="_blank">
                    <i class="fa-solid fa-link"></i><p>{{textEditor.get('linkText')?.value}}</p>
                  </a>
                </div>

              </div>

              @if(isControlType(textEditor, 'youtube')){
                <div class="youtube">
                  <input type="text" formControlName="youtube" placeholder="Youtube Embed">
                  <button type="button" class="button" (click)="fetchEmbed(textEditor, i, 'youtube')">Generate</button>
                  @if(embedSafe[i]){
                    <div [innerHtml]="embedSafe[i]"></div>
                  }
                </div>
              }

              @if(isControlType(textEditor, 'rumble')){
                <div class="youtube">
                  <input type="text" formControlName="rumble" placeholder="Rumble Embed">
                  <button type="button" class="button" (click)="fetchEmbed(textEditor, i, 'rumble')">Generate</button>
                  @if(embedSafe[i]){
                    <div class="rumble-container" [innerHtml]="embedSafe[i]"></div>
                  }
                </div>
              }

              @if(isControlType(textEditor, 'instagram')){
                <div class="instagram">
                  <input type="text" formControlName="instagram" placeholder="Instagram Embed">
                  <button type="button" class="button" (click)="fetchEmbed(textEditor, i, 'instagram')">Generate</button>
                  @if(embedSafe[i]){
                    <div [innerHtml]="embedSafe[i]"></div>
                  }
                </div>
              }

              @if(isControlType(textEditor, 'facebook')){
                <div class="facebook">
                  <input type="text" formControlName="facebook" placeholder="Facebook Embed">
                  <button type="button" class="button" (click)="fetchEmbed(textEditor, i, 'facebook')">Generate</button>
                  @if(embedSafe[i]){
                    <div [innerHtml]="embedSafe[i]"></div>
                  }
                </div>
              }

              @if(isControlType(textEditor, 'x')){
                <div class="x">
                  <input type="text" formControlName="x" placeholder="Twitter Embed">
                  <button type="button" class="button" (click)="fetchEmbed(textEditor, i, 'x')">Generate</button>
                  @if(embedSafe[i]){
                    <div [innerHtml]="embedSafe[i]"></div>
                  }
                </div>
              }

              @if(isControlType(textEditor, 'tiktok')){
                <div class="tiktok">
                  <input type="text" formControlName="tiktok" placeholder="Enter tiktok URL">
                  <button type="button" class="button" (click)="fetchEmbed(textEditor, i, 'tiktok')">Generate</button>
                  @if(embedSafe[i]){
                    <div [innerHtml]="embedSafe[i]"></div>
                  }
                </div>
              }

              @if(isControlType(textEditor, 'sofascore')){
                <div class="tiktok">
                  <input type="text" formControlName="sofascore" placeholder="SofaScore Embed">
                  <button type="button" class="button" (click)="fetchEmbed(textEditor, i, 'sofascore')">Generate</button>
                  @if(embedSafe[i]){
                    <div [innerHtml]="embedSafe[i]"></div>
                  }
                </div>
              }

              <div class="survey" *ngIf="isControlType(textEditor, 'survey')">
                <mat-form-field>
                  <mat-label>Survey</mat-label>
                  <mat-select [compareWith]="compareSFn" (selectionChange)="updateEmbedField($event.value, textEditor)">
                    <mat-option *ngFor="let survey of surveys" [value]="survey[surveysProperty.id]">
                      {{survey[surveysProperty.question]}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div *ngIf="textEditor.get('survey')?.value !== ''" class="survey">
                  <app-survey [surveyId]="textEditor.get('survey')?.value"></app-survey>
                </div>
              </div>

              <div class="image" *ngIf="isControlType(textEditor, 'image')">
                <input class="regular"
                       type="text"
                       formControlName="image"
                       placeholder="Enter image URL"
                >
                <img class="picture" src="{{textEditor.get('image')?.value}}">
              </div>
            </div>

            <button type="button" class="button remove" (click)="deleteForm(i)">Remove</button>
          </div>
        </ng-container>
        <div class="options">
          <button type="button" class="text-button input-button" (click)="addEditor('regular')"><span style="font-weight: 100">TEXT</span></button>
          <button type="button" class="text-button input-button" (click)="addEditor('bold')"><span style="font-weight: bold">TEXT</span></button>
          <button type="button" class="text-button input-button" (click)="addEditor('italic')"><span style="font-style: italic; font-weight: 100">TEXT</span></button>
          <button type="button" class="link-button input-button" (click)="addEditor('link', { linkText: ['', Validators.required] })"><i class="fa-solid fa-link"></i></button>
          <button type="button" class="image-button input-button" (click)="addEditor('image')"><i class="fas fa-image"></i></button>
          <button type="button" class="image-button input-button" (click)="addEditor('survey')"><i class="fas fa-poll-h"></i></button>
          <button type="button" class="instagram-button input-button" (click)="addEditor('instagram')"><i class="fab fa-instagram"></i></button>
          <button type="button" class="facebook-button input-button" (click)="addEditor('facebook')"><i class="fab fa-facebook-f"></i></button>
          <button type="button" class="twitter-button input-button" (click)="addEditor('x')"><i class="fa-brands fa-x-twitter"></i></button>
          <button type="button" class="tiktok-button input-button" (click)="addEditor('tiktok')"><i class="fab fa-tiktok" style="font-size: 40px; color: black"></i></button>
          <button type="button" class="youtube-button input-button" (click)="addEditor('youtube')"><i class="fab fa-youtube" style="font-size: 40px; color: red"></i></button>
          <button type="button" class="rumble-button input-button" (click)="addEditor('rumble')"><img src="assets/rumble-icon.png"></button>
          <button type="button" class="sofascore-button input-button" (click)="addEditor('sofascore')"><i class="fa-regular fa-futbol" style="font-size: 40px"></i></button>
          <!--<button type="button" (click)="addInstagram()">Add Youtube</button>-->
        </div>
      </div>
    </div>
    <div class="right">
      <mat-form-field>
        <mat-label>Category</mat-label>
        <mat-select [formControlName]="articlesProperty.category" [compareWith]="compareCategory">
          <mat-option *ngFor="let category of categories" [value]="category">
            {{category[categoriesProperty.name]}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      @if(chair && chair[ChairProperty.permissionIndependently]){
        <mat-form-field>
          <mat-label>Priority</mat-label>
          <mat-select [formControlName]="articlesProperty.priority">
            <mat-option [value]="null">No Priority</mat-option>
          </mat-select>
        </mat-form-field>
      }
      <mat-form-field>
        <mat-label>Signature</mat-label>
        <mat-select [formControlName]="'displayName'" [compareWith]="compareSignature">
          <mat-option *ngFor="let signature of displayNameOptions" [value]="signature">
            {{signature}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!--<label>Tip članka</label>
      <section class="article-type">
        <mat-checkbox [formControlName]="articlesProperty.videoPost"
                      (change)="disableCheckboxes('video_post')"
        >
          Video post
        </mat-checkbox>
        <mat-checkbox [formControlName]="articlesProperty.textPost"
                      (change)="disableCheckboxes('text_post')"
        >
          Text post
        </mat-checkbox>
        <mat-checkbox [formControlName]="articlesProperty.photoPost"
                      (change)="disableCheckboxes('photo_post')"
        >
          Photo post
        </mat-checkbox>
        <mat-checkbox [formControlName]="articlesProperty.surveyPost"
        >
          Survey post
        </mat-checkbox>
      </section>-->

      <ng-template #noSignature>
        <p>No signatures data</p>
      </ng-template>

      <div class="tags">
        <label>Tagovi</label>
        <input
          type="text"
          name="userInput"
          [(ngModel)]="userInput"
          [ngModelOptions]="{standalone: true}"
          placeholder="Type to search tags"
          (keyup.enter)="onEnter()"
        />
        <div *ngIf="allTags.length > 0" class="tag-container">
          <div
            *ngFor="let tag of filterTags()"
            class="tag"
            (click)="addTag(tag)"
          >
            {{ tag.tag }}
          </div>
        </div>
        <div *ngIf="selectedTags.length > 0" class="tag-container">
          <div
            *ngFor="let selectedTag of selectedTags"
            class="selected-tag"
            (click)="removeTag(selectedTag)"
          >
            {{ selectedTag.tag }}
          </div>
        </div>
      </div>

      @if(chair && chair[ChairProperty.permissionCategories]){
        <div class="notification">
          <label>NOTIFICATION</label>
          <section>
            <mat-checkbox [formControlName]="'notification'" class="notif-text">
              Notification&#160;
              <i class="fa-solid fa-mobile"></i>
            </mat-checkbox>
          </section>
        </div>
      }


      <div class="buttons">
        @if(chair && !chair[ChairProperty.permissionIndependently]){
          <button class="button-regular buttons-button" (click)="onSubmit()" type="submit">Request</button>
        } @else if(isEditingFunction()){
          <button class="button-regular buttons-button" (click)="onSubmit()" type="submit">Update</button>
        } @else if (selectedPriority){
          <button class="button-preview buttons-button" (click)="onSubmit()" type="submit">Preview</button>
          <button class="button-schedule buttons-button" (click)="onSchedule()" type="submit">Schedule</button>
        } @else {
          <button class="button-regular buttons-button" (click)="onSubmit()" type="submit">Upload</button>
          <button class="button-schedule buttons-button" (click)="onSchedule()" type="submit">Schedule</button>
        }
      </div>
    </div>
  </form>
</div>


<div *ngIf="isUploading" >
  <app-article-upload-loader></app-article-upload-loader>
</div>



<div *ngIf="isSuccess" class="success">
  <!--<div class="checkmark draw"></div>-->
  <svg width="400" height="400">
    <circle fill="none" stroke="#68E534" stroke-width="20" cx="200" cy="200" r="190" class="circle" stroke-linecap="round" transform="rotate(-90 200 200) "/>
    <polyline fill="none" stroke="#68E534" stroke-width="24" points="88,214 173,284 304,138" stroke-linecap="round" stroke-linejoin="round" class="tick" />
  </svg>
</div>
