import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";

@Component({
    selector: 'app-fun-dialog',
    imports: [
        MatDialogContent,
        MatDialogTitle
    ],
    templateUrl: './fun-dialog.component.html',
    styleUrl: './fun-dialog.component.scss'
})
export class FunDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<FunDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string; image: string; }
  ) {}
}
