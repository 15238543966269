import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SurveysService} from "../../../../services/survey.service";
import {UserService} from "../../../../services/user.service";
import {ChairService} from "../../../../services/chair.service";
import {ArticlesProperty} from "../../../../models/articles-property.enum";
import {Chair} from "../../../../models/chair.model";
import {ChairProperty} from "../../../../models/chair-property.enum";
import {MatIconModule} from "@angular/material/icon";

@Component({
    selector: 'app-create-editor-dialog',
    templateUrl: './create-editor-dialog.component.html',
    imports: [
        ReactiveFormsModule,
        MatIconModule
    ],
    styleUrls: ['./create-editor-dialog.component.scss']
})
export class CreateEditorDialogComponent {
  editorForm: FormGroup;
  @Output() editorCreated = new EventEmitter<Chair>();

  constructor(
    public dialogRef: MatDialogRef<CreateEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public editor: Chair,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private chairService: ChairService,
  ) {
    this.editorForm = this.formBuilder.group({
      email: [this.editor?.[ChairProperty.email] || '', Validators.required],
      password: ['', Validators.required],
      name: [this.editor?.[ChairProperty.email] || '', Validators.required],
      isEditor: [true],
    });
  }

  onSubmit() {
    if (this.editorForm.valid) {
      const formData = this.editorForm.value;
      // Send the form data to the backend
      this.chairService.create(formData).subscribe(
        (result:any) => {
          this.editorCreated.emit(result.chair); // Emit the event with the created editor
          this.dialogRef.close();
        },
        (error) => {
          console.error('Error creating survey:', error);
          // Handle the error as needed
        }
      );
    }
  }
  showPassword = false;

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
    const passwordInput = this.editorForm.get('password');

    if (passwordInput instanceof FormControl) {
      const inputElement = document.getElementById('password') as HTMLInputElement;

      if (inputElement) {
        inputElement.type = this.showPassword ? 'text' : 'password';
      }
    }
  }

}
