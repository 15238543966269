import { Component } from '@angular/core';

@Component({
  selector: 'app-hayat-welcome',
  imports: [],
  templateUrl: './hayat-welcome.component.html',
  standalone: true,
  styleUrl: './hayat-welcome.component.scss'
})
export class HayatWelcomeComponent {

}
