import {Injectable} from '@angular/core';
import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Images} from "../models/images.model";
import {Tag} from "../models/tag.model";


@Injectable({providedIn: 'root'})
export class ImagesService {

  private readonly baseUrl: string = `${environment.backendUrl}/images`;
  private readonly imageUploadUrl: string = `${environment.imageUploadUrl}/images`;

  postResponse: any;
  successResponse!: string;

  constructor(
    private http: HttpClient,
  ) {
  }

  getImages(tags: Tag[] = [], page: number = 1): Observable<any[]> {
    const tagsData = tags.map(tag => tag.tag);

    const params = new HttpParams()
      .set('tags', JSON.stringify(tagsData));

    return this.http.get<any[]>(`${this.baseUrl}/${page}`, { params });
  }

  public create(file: FormData){
    this.http.post<any>(`${this.imageUploadUrl}`, file).subscribe((response) => {
      if (response.status === 200) {
      this.postResponse = response;
      this.successResponse = this.postResponse.body.message;
      } else {
        this.successResponse = 'Image not uploaded due to some error!';
      }
    });
  }

  upload(file: File, folder: string): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('folder', folder); // Add folder information to the FormData

    //return this.http.post<any>(`${this.baseUrl}`, formData);
    return this.http.post<any>(`${this.imageUploadUrl}/api/upload`, formData);
  }


  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/files`);
  }

}
