import {Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, QueryList, ViewChildren} from '@angular/core';
import {Categories} from "../../models/categories.model";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {ArticlesProperty} from "../../models/articles-property.enum";
import {CategoriesProperty} from "../../models/categories-property.enum";
import {Articles} from "../../models/articles.model";
import {CategoryArticles} from "../../models/category-articles.model";
import {CategoryArticlesProperty} from "../../models/category-articles-property.enum";
import {isPlatformBrowser, NgForOf, NgIf, NgOptimizedImage, NgStyle} from "@angular/common";
import {ArticlesService} from "../../services/articles.service";
import {MasonryPipe} from "../../pipes/masonry-pipe.pipe";
import {TagsService} from "../../services/tags.services";
import {FormsModule} from "@angular/forms";
import {ArticleCardComponent} from "../hayat.ba/article-card/article-card.component";
import {ThemedAdvertisementComponent} from "../hayat.ba/themed-advertisement/themed-advertisement.component";
import {ThemedTagComponent} from "../hayat.ba/themed-tag/themed-tag.component";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  imports: [
    NgIf,
    RouterLink,
    FormsModule,
    NgForOf,
    ArticleCardComponent,
    ThemedAdvertisementComponent,
    ThemedTagComponent,
    NgStyle,
  ],
  standalone: true,
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit{
  query: string = '';
  results: any[] = [];
  showResults: boolean = false;
  typesInColumns: number[][] = [];
  numberOfColumns: number = 3;
  private intersectionObserver: IntersectionObserver | undefined;
  public category!: Categories;

  public tag: string | undefined = undefined

  public categoryArticles!: CategoryArticles;
  public articles!: Articles[];
  public articlesByColumn: any[][] = [];

  categoriesSubscription: any;

  page: number = 1;

  public articlesProperty = ArticlesProperty;
  public categoryArticlesProperty = CategoryArticlesProperty;
  public categoriesProperty = CategoriesProperty;

  categoryWidth: number = 400; // Default width

  onSearch(): void {
    if (this.query.trim()) {
      this.tagsService.searchTags(this.query.trim()).subscribe((tags) => {
        this.results = tags.slice(0, 30); // Limit to 30 results
      });
    } else {
      this.results = [];
    }
  }

  onSelect(result: any): void {
    this.tag = result.tag
    this.articlesService.tagName = result.tag;
    this.articles = []
    this.articlesByColumn = []
    this.loadArticles().then(r =>
    {
      console.log("Nice")
    })
    this.showResults = false; // Hide the overlay
  }




  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private activatedRoute: ActivatedRoute,
    private articlesService: ArticlesService,
    private tagsService: TagsService,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {

      const innerWidth = window.innerWidth;
      this.calculateCategoryWidth(innerWidth - 20)

      if (innerWidth >= 1800) {
        this.numberOfColumns = Math.floor(innerWidth / 400);
      } else {
        this.numberOfColumns = Math.min(Math.floor(innerWidth / 400), 4);
      }



      this.activatedRoute.params.subscribe(async params => {
        const tag = params['tag']; // Assuming 'id' is the parameter name in your URL
        this.tag = tag
        this.articlesService.tagName = tag;

        await this.loadArticles();

      })
    }
  }

  @HostListener('window:resize', [])
  calculateCategoryWidth(screenWidth: number) {
    if(screenWidth > 400)
    {
      const columnWidth = 400;
      const gap = 10;

      // Calculate max columns that can fit
      const numColumns = Math.floor(screenWidth / (columnWidth + gap));

      // Calculate total width
      this.categoryWidth = (numColumns * columnWidth) + ((numColumns - 1) * gap) + 20;
    }
    else {
      this.categoryWidth = innerWidth
    }
  }

  async loadArticles(){
    this.page = 1
    this.articlesService.getArticlesByTag().subscribe(async result => {
      // this.categoryArticles = result;
      this.articles = result;
      await this.sortArticles();
    })
  }
  // async sortArticles(){
  //   for(let i = 0; i < this.articles?.length; i++){
  //     let columnControl = 0;
  //     if(this.articles[i][ArticlesProperty.videoPost]){
  //       const columnToAdArticleTo = 0
  //       for(let i = 0; i < this.numberOfColumns - 1; i++){
  //         if(this.typesInColumns[this.videoIndex][i] > this.typesInColumns[this.videoIndex][i+1]){
  //           columnControl = i + 1;
  //         }
  //       }
  //       console.log('Article ' + i + ' goes in ' + columnControl + ' is the column the articles needs to go to')
  //       this.typesInColumns[this.videoIndex][columnControl]++;
  //       console.log(this.typesInColumns)
  //       if (!this.articlesByColumn[columnControl]) {
  //         this.articlesByColumn[columnControl] = [];
  //       }
  //       this.articlesByColumn[columnControl].push(this.articles[i])
  //       console.log("ARTICLES BY COLUMN")
  //       console.log(this.articlesByColumn)
  //
  //     } else if(this.articles[i][ArticlesProperty.photoPost]){
  //       const columnToAdArticleTo = 0
  //       for(let i = 0; i < this.numberOfColumns - 1; i++){
  //         if(this.typesInColumns[this.photoIndex][i] > this.typesInColumns[this.photoIndex][i+1]){
  //           columnControl = i + 1;
  //         }
  //       }
  //       console.log('Article ' + i + ' goes in ' + columnControl + ' is the column the articles needs to go to')
  //       this.typesInColumns[this.photoIndex][columnControl]++;
  //       console.log(this.typesInColumns)
  //       if (!this.articlesByColumn[columnControl]) {
  //         this.articlesByColumn[columnControl] = [];
  //       }
  //       this.articlesByColumn[columnControl].push(this.articles[i])
  //       console.log("ARTICLES BY COLUMN")
  //       console.log(this.articlesByColumn)
  //     } else if(this.articles[i][ArticlesProperty.textPost]){
  //       const columnToAdArticleTo = 0
  //       for(let i = 0; i < this.numberOfColumns - 1; i++){
  //         if(this.typesInColumns[this.textIndex][i] > this.typesInColumns[this.textIndex][i+1]){
  //           columnControl = i + 1;
  //         }
  //       }
  //       console.log('Article ' + i + ' goes in ' + columnControl + ' is the column the articles needs to go to')
  //       this.typesInColumns[this.textIndex][columnControl]++;
  //       console.log(this.typesInColumns)
  //       if (!this.articlesByColumn[columnControl]) {
  //         this.articlesByColumn[columnControl] = [];
  //       }
  //       this.articlesByColumn[columnControl].push(this.articles[i])
  //       console.log("ARTICLES BY COLUMN")
  //       console.log(this.articlesByColumn)
  //     }
  //   }
  // }

  columnsHeightControl: number[] = [];

  async sortArticles() {
    // Initialize column height tracking
    const innerWidth = window.innerWidth;
    this.calculateCategoryWidth(innerWidth)

    if (innerWidth < 400) {
      this.numberOfColumns = 1;
    } else if (innerWidth >= 1800) {
      this.numberOfColumns = Math.floor(innerWidth / 400);
    } else {
      this.numberOfColumns = Math.min(Math.floor(innerWidth / 400), 4);
    }

    for (let i = 0; i < this.numberOfColumns; i++) {
      this.columnsHeightControl[i] = 0;
    }

    for (let i = 0; i < this.numberOfColumns; i++) {
      this.columnsHeightControl[i] = 0;
    }

    let displayedArticles = 0;
    let maxArticlesToShow = Math.min(this.articles.length, 15); // Show 8-12 articles

    for (let i = 0; i < this.articles.length; i++) {
      let columnControl = 0;
      let minHeight = this.columnsHeightControl[0];

      // Find the column with the smallest height
      for (let j = 1; j < this.numberOfColumns; j++) {
        if (this.columnsHeightControl[j] < minHeight) {
          minHeight = this.columnsHeightControl[j];
          columnControl = j;
        }
      }

      // Determine article height
      let articleHeight = 0;
      if (this.articles[i][ArticlesProperty.videoPost]) {
        articleHeight = window.innerWidth > 400 ? 400 : 400;
      } else if (this.articles[i][ArticlesProperty.textPost]) {
        articleHeight = 185;
      } else if (this.articles[i][ArticlesProperty.photoPost]) {
        articleHeight = 130;
      }

      // Add article to the shortest column
      if (!this.articlesByColumn[columnControl]) {
        this.articlesByColumn[columnControl] = [];
      }
      this.articlesByColumn[columnControl].push(this.articles[i]);

      // Update column height
      this.columnsHeightControl[columnControl] += articleHeight;
      displayedArticles++;

      // Stop if we reach at least minArticlesToShow & height balance is good
      if (displayedArticles % 10 === 0) {
        let maxGapColumn = 0;
        let maxGap = 0;

        // Find the column with the **most extra space**
        for (let j = 0; j < this.numberOfColumns; j++) {
          let gap = Math.max(...this.columnsHeightControl) - this.columnsHeightControl[j];
          if (gap > maxGap) {
            maxGap = gap;
            maxGapColumn = j;
          }
        }

        let adSize: "400x400" | "400x200" | null = null;
        adSize = Math.random() > 0.5 ? "400x400" : "400x200";


        // Insert ad if it fits
        if (adSize) {
          this.articlesByColumn[maxGapColumn].push({type: "advertisement", size: adSize});
          this.columnsHeightControl[maxGapColumn] += adSize === "400x400" ? 400 : 200;
        }
      }

    }
  }







  getNumberArray(length: number): number[] {
    return Array.from({ length }, (_, i) => i);
  }


  loadNewPage(): void {
    this.articlesService.getArticlesByTagByPage(++this.page).subscribe((response) => {
      this.articles.push(...response)
    });
  }


  isVideo(end: string | undefined | null): boolean {
    // Check if 'end' is defined and ends with 'mp4'
    return typeof end === 'string' && end.endsWith('mp4');
  }

  loadNextPage(): void {
    this.loadNewPage();
  }
}
