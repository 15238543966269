import {Component, HostListener} from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavigationStart, Router, RouterModule, RouterOutlet} from "@angular/router";
import {AuthService} from "./services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    RouterOutlet,
  ],
  standalone: true,
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Hayat.ba';

  constructor(
    private router: Router,
              private authService: AuthService) {}

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    if (performance.navigation.type === 1) {
      window.scrollTo(0, 0); // Scroll to top on page reload
    }
  }

  ngOnInit() {
    // Optionally, you can also listen for NavigationStart events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
        window.scrollTo(0, 0); // Scroll to top when navigating using browser back/forward buttons
      }
    });

    this.authService.fetchUserProfile().subscribe((profile) => {
      if (profile) {
        // console.log('User is logged in:', profile);
        // Optionally store the profile data in local storage, etc.
      } else {
        // console.log('No valid user session, or user needs to log in');
        // Redirect to login page if needed
      }
    });
  }

}
