import {ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer, Meta, Title} from "@angular/platform-browser";
import {Show} from "../../../models/show.model";
import {ShowService} from "../../../services/show.services";
import {MatCheckbox} from "@angular/material/checkbox";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ShowProperty} from "../../../models/show-property.enum";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {CreateShowDialogComponent} from "../administrator-shows/create-show-dialog/create-show-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {error} from "@angular/compiler-cli/src/transformers/util";
import {Episode} from "../../../models/episode.model";
import {EpisodeComponent} from "../../hayat-play/shows/one-show/episode/episode.component";
import {EpisodeProperty} from "../../../models/episode-property.enum";
import {Question} from "../../../models/question.model";
import {NgForOf, NgIf} from "@angular/common";
import {Tag} from "../../../models/tag.model";
import {Categories} from "../../../models/categories.model";
import {Surveys} from "../../../models/surveys.model";
import {Guest} from "../../../models/guest.model";
import {GuestsService} from "../../../services/guests.services";
import {TagProperty} from "../../../models/tag-property.enum";
import {GuestProperty} from "../../../models/guest-property.enum";

@Component({
    selector: 'app-administrator-episode',
    imports: [
        MatCheckbox,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatLabel,
        NgForOf,
        NgIf,
        FormsModule
    ],
    templateUrl: './administrator-episode.component.html',
    styleUrl: './administrator-episode.component.scss'
})
export class AdministratorEpisodeComponent implements OnInit{
  show!: Show;
  episode!: Episode | null;

  showForm!: FormGroup;
  isEditing = false;

  userInput = '';

  public categories: Categories[] = [];
  public surveys: Surveys[] = [];
  public allGuests: Guest[] = [];
  public selectedGuests: Guest[] | undefined = [];
  public receivedTagsControl: Tag[] = [];
  public displayNameOptions: string[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private showService: ShowService,
    private guestsService: GuestsService,
    private snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private title: Title,
    private meta: Meta,
  ) {
    this.activatedRoute.params.subscribe(params => {
      const id = params['id']; // Assuming 'id' is the parameter name in your URL

      // Load initial event data (optional)
      this.showService.getShow(id).subscribe(
        result => {
          // Handle successful response
          this.show = result;

        },
        error => {
          // Handle error
          console.error('Error fetching event data:', error);
        }
      );
    });
  }

  ngOnInit(): void {
    this.episode = this.showService.getEpisode()
    this.selectedGuests = this.episode?.guests
    this.guestsService.getGuests().subscribe(result =>{
      this.allGuests = result
    })
    this.showForm = this.formBuilder.group({
      _id: [this.episode?.[EpisodeProperty.id] || ''],
      topic: [this.episode?.[EpisodeProperty.topic] || ''],
      order: [this.episode?.[EpisodeProperty.order] || ''],
      guests: [this.episode?.[EpisodeProperty.guests] || ''],
    });
  }

  onSubmit() {
    if (this.show) {
      //this.showService.update(this.showForm.value)
    }
    if (this.showForm?.valid) {
      const formData = this.showForm.value;
      // Send the form data to the backend
      this.showService.create(formData).subscribe(
        (result: any) => {
        },
        (error) => {
          console.error('Error creating survey:', error);
          // Handle the error as needed
        }
      );
    }
  }


  toggleEdit(): void {
    if(this.isEditing){
      //this.showService.update(this.showForm.value)
    }
    this.isEditing = !this.isEditing;
    this.cdr.detectChanges()
  }

  filterTags(): Guest[] {
    const filteredTags = this.allGuests
      .filter(guest => guest[GuestProperty.name].toLowerCase().includes(this.userInput.toLowerCase()))
      .slice(0, 20);
    return filteredTags;
  }

  approve(question: Question): void {
  }

  decline(question: Question): void {
  }


  onEnter(): void {
    const trimmedInput = this.userInput.trim().toUpperCase();
    if (!trimmedInput) {
      return; // Do nothing if the input is empty or contains only whitespace
    }
    const existingTag = this.receivedTagsControl.find(tag => tag.tag === trimmedInput);

    if (this.selectedGuests?.find(guest => guest._id === trimmedInput)) {
      this.snackBar.open("Tag je već odabran", 'Close', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['error-snackbar']
      });
    } else if(existingTag){
      //this.addTag(existingTag);
    }
    else {
      // If the tag doesn't exist, add it to the database and then add it to the selected tags
      const newTag: Tag = { tag: trimmedInput, active: true, imagesURL: [] };
      /*this.tagsService.create(newTag).subscribe(
        addedTag => {
          this.allTags.push(addedTag);
          this.addTag(addedTag);
        },
        error => {
          console.error('Failed to add new tag:', error);
          // Handle the error (e.g., show a message to the user)
        }
      );*/
    }
    this.userInput = ''

  }

  addTag(tag: Guest): void {
    this.selectedGuests?.push(tag);
    this.allGuests = this.allGuests.filter(t => t !== tag);
  }

  removeTag(selectedGuest: Guest): void {
    this.selectedGuests = this.selectedGuests?.filter(guest => guest !== selectedGuest);
    this.allGuests.push(selectedGuest);
  }

}
