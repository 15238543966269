<div class="login">
  @if(!showSuccessMessage){
    <h1>Restartujte svoju lozinku</h1>
    <h3>Unesite email od vašeg naloga</h3>
    <form (submit)="handleFormSubmit()" [formGroup]="form" class="container">
      <div class="form-field">
        <input type="text"
               placeholder="Email"
               formControlName="email"
               [class.missing-field]="isFieldMissing('email')">
      </div>

      <button class="button-regular"
              type="submit"
              aria-label="submit">
        Restart
      </button>
    </form>
  } @else {
    <div class="success-message">
      <h3>Slijedite instrukcije koje ste dobili na vaš email</h3>
    </div>
  }

</div>
