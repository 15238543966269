import {
  afterNextRender,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from "@angular/router";
import {ArticlesService} from "../../../services/articles.service";
import {Articles} from "../../../models/articles.model";
import {DomSanitizer, Meta, SafeHtml, Title} from "@angular/platform-browser";
import {ArticlesProperty} from "../../../models/articles-property.enum";
import {
  formatDate,
  isPlatformBrowser,
  isPlatformServer,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage
} from "@angular/common";
import {ImagesProperty} from "../../../models/images-property.enum";
import {SurveyComponent} from "../../survey/survey.component";
import {CdkAutofill} from "@angular/cdk/text-field";
import {ThemedTagComponent} from "../themed-tag/themed-tag.component";
import {ArticleCardComponent} from "../article-card/article-card.component";

declare global {
  interface Window {
    linkerReloadElements: (param: boolean) => void;
  }
}
declare interface Window {
  adsbygoogle: any[];
}
declare var adsbygoogle: any[];


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  host: {ngSkipHydration: 'true'},
  imports: [
    NgIf,
    NgForOf,
    RouterLink,
    SurveyComponent,
    NgClass,
    NgOptimizedImage,
    CdkAutofill,
    ThemedTagComponent,
    ArticleCardComponent,
  ],
  standalone: true,
  styleUrls: ['./article.component.scss']
})

export class ArticleComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input()
  article: Articles | undefined;

  @ViewChildren('videoElement') videoElements!: QueryList<ElementRef<HTMLVideoElement>>;

  @ViewChild('advertisementSidebarSmall', { static: true }) advertisementSidebarSmall!: ElementRef;
  @ViewChild('advertisementSidebar', { static: true }) advertisementSidebar!: ElementRef;

  public CURRENT_URL!: string | '';

  public categoryArticles: any;

  currentIndex = 0;

  carouselClass: string = '';


  //public article!: Articles;
  public html!: SafeHtml;
  date!: string;
  time!: string;

  styleGallery = "display: none";
  public adID: string = ''


  windowWidth: number = 0;

  embedSafe: any[] = [];

  private scriptElement: HTMLScriptElement | undefined;
  private luponScriptElement: HTMLScriptElement | undefined;

  public articlesProperty = ArticlesProperty;
  constructor(
    @Inject(PLATFORM_ID) public platformID: Object,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private articlesService: ArticlesService,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private title: Title,
    private meta: Meta,
    private renderer: Renderer2,
  ) {
    afterNextRender(() => {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0); // Scroll to the top on route navigation
        }
      });
    })
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformID)) {
      if (this.article) {
        this.CURRENT_URL = 'https://www.hayat.ba/article/' + this.article?.[ArticlesProperty.url_title] + '/' + this.article?.[ArticlesProperty.id];

        this.title.setTitle(this.article[ArticlesProperty.title]);
        this.meta.addTag({property: 'og:url', content: this.CURRENT_URL});
        this.meta.updateTag({property: 'og:title', content: this.article[ArticlesProperty.title]});
        this.meta.updateTag({property: 'og:description', content: this.article[ArticlesProperty.subtitle]});
        this.meta.updateTag({property: 'og:type', content: 'article'});
        this.meta.updateTag({name: 'twitter:title', content: this.article[ArticlesProperty.title]})
        this.meta.updateTag({name: 'twitter:description', content: this.article[ArticlesProperty.subtitle]});
        // @ts-ignore
        if (this.article[ArticlesProperty.image_list]?.length > 0) {
          this.meta.updateTag({
            property: 'og:image',
            // @ts-ignore
            content: this.article?.[ArticlesProperty.image_list][0]?.[ImagesProperty.url]
          });
          this.meta.updateTag({
            name: 'twitter:image',
            // @ts-ignore
            content: this.article?.[ArticlesProperty.image_list][0]?.[ImagesProperty.url]
          });
        } else {
          this.meta.updateTag({
            property: 'og:image',
            content: 'https://hayat.ba/assets/logo-color.webp'
          });
        }

        if(this.article[ArticlesProperty.tags]){
          const tags = this.article[ArticlesProperty.tags].map(tagObj => tagObj.tag.toUpperCase());

          // Join the transformed tags into a comma-separated string
          const tagContent = tags.join(', ');

          // Update the meta tag
          this.meta.updateTag({name: 'keywords', content: tagContent});
        }
      }
    }

    if (isPlatformBrowser(this.platformID)) {
      /*Load SetupAd advertisement*/
      setTimeout(() => {
        this.windowWidth = window.innerWidth;
        // @ts-ignore
        window.stpd = window.stpd || {};
        // @ts-ignore
        stpd.que = stpd.que || [];
        // @ts-ignore
        stpd.adUnits = stpd.adUnits || [];
        // @ts-ignore
        if (stpd && stpd.adUnits && Array.isArray(stpd.adUnits)) {
          // @ts-ignore
          if (!stpd.adUnits.some((adUnit: { code: string }) => adUnit.code === 'hayat_ba_300x300_in_article_1_mobile')) {
            // @ts-ignore
            const adID = document.querySelector('#hayat_ba_300x300_in_article_1_mobile').id = "hayat_ba_300x300_in_article_1_mobile";
            // @ts-ignore
            inView('#' + adID).once('enter', (function () {
              // @ts-ignore
              googletag.cmd.push(function () {
                if (window.innerWidth >= 1280) {
                  // @ts-ignore
                  googletag.defineSlot('/272140683/hayat.ba_300x300_in_article_1_mobile', [[300, 250], [300, 300], [250, 250]], 'hayat_ba_300x300_in_article_1_mobile').addService(googletag.pubads());
                } else if (window.innerWidth >= 1024) {
                  // @ts-ignore
                  googletag.defineSlot('/272140683/hayat.ba_300x300_in_article_1_mobile', [[300, 250], [300, 300], [250, 250]], 'hayat_ba_300x300_in_article_1_mobile').addService(googletag.pubads());
                } else {
                  // @ts-ignore
                  googletag.defineSlot('/272140683/hayat.ba_300x300_in_article_1_mobile', [[300, 250], [300, 300], [250, 250]], 'hayat_ba_300x300_in_article_1_mobile').addService(googletag.pubads());
                }
                // @ts-ignore
                googletag.display("hayat_ba_300x300_in_article_1_mobile");
                // @ts-ignore
                stpd.initializeAdUnit("hayat_ba_300x300_in_article_1_mobile")
              })
            }));
          } else {
            // @ts-ignore
            stpd.que.push((function () {
              // @ts-ignore
              stpd.refreshAdUnit('hayat_ba_300x300_in_article_1_mobile');
            }));
          }
        }
      }, 10);

      /*LINKER AD LOADING*/
      this.scriptElement = this.renderer.createElement('script');
      this.renderer.setAttribute(this.scriptElement, 'src', 'https://d.linker.ba/lw.js');
      this.renderer.setAttribute(this.scriptElement, 'async', '');
      this.renderer.appendChild(document.body, this.scriptElement);

      /*MIDAS AD LOADING*/
      this.scriptElement = this.renderer.createElement('script');
      this.renderer.setAttribute(this.scriptElement, 'src', 'https://cdn2.midas-network.com/Scripts/midasWidget-22-713-10687.js');
      this.renderer.setAttribute(this.scriptElement, 'async', '');
      this.renderer.appendChild(document.body, this.scriptElement);


      /*LUPON AD LOADING*/
      this.luponScriptElement = this.renderer.createElement('script');
      const scriptContent = `
      (function(w,q){w[q]=w[q]||[];w[q].push(["_mgc.load"])})(window,"_mgq");
      `;
      // @ts-ignore
      this.luponScriptElement.text = scriptContent;
      this.renderer.appendChild(document.body, this.luponScriptElement);

      this.scriptElement = this.renderer.createElement('script');
      this.renderer.setAttribute(this.scriptElement, 'src', 'https://static.nativegram.com/js/loader.js?ai=ngw20240723184738');
      this.renderer.setAttribute(this.scriptElement, 'async', '');
      this.renderer.appendChild(document.body, this.scriptElement);


      if (this.article) {
        this.categoryArticles = this.article[ArticlesProperty.category_articles]
        if (this.article[ArticlesProperty.text]?.length > 0) {
          this.sanitizeEmbed(this.article)
        }
      }

      const offset = new Date().getTimezoneOffset();
      const timezone = offset / 60;
      if (this.article && this.article[ArticlesProperty.create_date] !== undefined) {
        this.date = formatDate(this.article?.[ArticlesProperty.create_date], 'dd/MM/yyyy', 'en-GB', 'UTC+' + timezone)
        this.time = formatDate(this.article?.[ArticlesProperty.create_date], 'HH:mm', 'en-GB', 'UTC+' + timezone)
      } else {
        this.date = '27/06/2000'
        this.time = '12:05'
      }


      this.CURRENT_URL = 'https://www.hayat.ba/article/' + this.article?.[ArticlesProperty.url_title] + '/' + this.article?.[ArticlesProperty.id];

      if (this.videoElements) {
        this.videoElements.forEach(videoElementRef => {
          const videoElement = videoElementRef.nativeElement;
          videoElement.muted = true;
        });
      }
    }

  };

  ngOnChanges(changes: SimpleChanges): void {
    if(isPlatformBrowser(this.platformID))
    {
      if (changes['article']) {
        this.fetchArticle()

        if (this.scriptElement) {
          window.linkerReloadElements(true);
        }
        else {
          this.scriptElement = this.renderer.createElement('script');
          this.renderer.setAttribute(this.scriptElement, 'src', 'https://d.linker.ba/lw.js');
          this.renderer.setAttribute(this.scriptElement, 'async', '');
          this.renderer.appendChild(document.body, this.scriptElement);
        }
      }
    }
  }

  fetchArticle() {
    if(this.article){
      this.categoryArticles = this.article[ArticlesProperty.category_articles]
      this.sanitizeEmbed(this.article)

      this.CURRENT_URL = 'https://www.hayat.ba/article/' + this.article?.[ArticlesProperty.url_title] + '/' + this.article?.[ArticlesProperty.id];

      this.title.setTitle(this.article[ArticlesProperty.title]);
      this.meta.addTag({property: 'og:url', content: this.CURRENT_URL});
      this.meta.updateTag({property: 'og:title', content: this.article[ArticlesProperty.title]});
      this.meta.updateTag({property: 'og:description', content: this.article[ArticlesProperty.subtitle]});
      this.meta.updateTag({property: 'og:type', content: 'article'});
      this.meta.updateTag({name: 'twitter:title', content: this.article[ArticlesProperty.title]});
      this.meta.updateTag({name: 'twitter:description', content: this.article[ArticlesProperty.subtitle]});
      // @ts-ignore
      if (this.article[ArticlesProperty.image_list]?.length > 0) {
        this.meta.updateTag({
          property: 'og:image',
          // @ts-ignore
          content: this.article?.[ArticlesProperty.image_list][0]?.[ImagesProperty.url]
        });
        this.meta.updateTag({
          name: 'twitter:image',
          // @ts-ignore
          content: this.article?.[ArticlesProperty.image_list][0]?.[ImagesProperty.url]
        });
      } else {
        this.meta.updateTag({
          property: 'og:image',
          content: 'https://hayat.ba/assets/logo-color.webp'
        });
      }

      if (this.scriptElement) {
        window.linkerReloadElements(true);
      }
      else {
        this.scriptElement = this.renderer.createElement('script');
        this.renderer.setAttribute(this.scriptElement, 'src', 'https://d.linker.ba/lw.js');
        this.renderer.setAttribute(this.scriptElement, 'async', '');
        this.renderer.appendChild(document.body, this.scriptElement);
      }

      this.cdr.detectChanges();

      const offset = new Date().getTimezoneOffset();
      const timezone = offset / 60;
      if (this.article && this.article[ArticlesProperty.create_date] !== undefined) {
        this.date = formatDate(this.article?.[ArticlesProperty.create_date], 'dd/MM/yyyy', 'en-GB', 'UTC+' + timezone)
        this.time = formatDate(this.article?.[ArticlesProperty.create_date], 'HH:mm', 'en-GB', 'UTC+' + timezone)
      } else {
        this.date = '27/06/2000'
        this.time = '12:05'
      }
      if (this.videoElements) {
        this.videoElements.forEach(videoElementRef => {
          const videoElement = videoElementRef.nativeElement;
          videoElement.muted = true;
        });
      }
    }
  }

  shouldPlaceAdvertisement(index: number): boolean{
    if(
      this.article &&
      index + 1 < this.article.text.length &&
      (this.article.text[index].regular ||
      this.article.text[index].bold ||
      this.article.text[index].italic)
      &&
      (this.article.text[index + 1].regular ||
      this.article.text[index + 1].bold ||
      this.article.text[index + 1].italic)
    ){
      return true
    }
    return false
  }
  ngAfterViewInit() {
    if(this.windowWidth > 768){
      if (this.advertisementSidebar ) {
        const scriptElement = this.renderer.createElement('script');
        this.renderer.setAttribute(scriptElement, 'src', 'https://cnt.trvdp.com/js/2009/11376.js');
        this.renderer.appendChild(this.advertisementSidebar.nativeElement, scriptElement);
      } else {
        console.error('advertisementSidebar is undefined');
      }
    } else{
      if (this.advertisementSidebarSmall ) {
        const scriptElement = this.renderer.createElement('script');
        this.renderer.setAttribute(scriptElement, 'src', 'https://cnt.trvdp.com/js/2009/11376.js');
        this.renderer.appendChild(this.advertisementSidebarSmall.nativeElement, scriptElement);
      } else {
        console.error('advertisementSidebarSmall is undefined');
      }
    }

    if (isPlatformBrowser(this.platformID)) {
      const scriptElement1 = this.renderer.createElement('script');
      const scriptContent1 = `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1732793651947-0'); });`;
      scriptElement1.text = scriptContent1;
      this.renderer.setAttribute(scriptElement1, 'id', 'google-tag-script-subheading');
      this.renderer.appendChild(document.body, scriptElement1);
    }

    // setTimeout(() => {
    //   try {
    //     // @ts-ignore
    //     (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
    //   } catch (e) {
    //     console.error("ads", e);
    //   }
    // }, 900);
  }
  ngOnDestroy() {
    if (this.scriptElement) {
      this.renderer.removeChild(document.body, this.scriptElement);
    }
    if (this.luponScriptElement) {
      this.renderer.removeChild(document.body, this.luponScriptElement);
    }
  }

  public removeArticle() {
    this.article = undefined;
  }

  public sanitizeEmbed(article: Articles) {
    let index = 0; // Initialize index variable
    if (article) {
      for (const item of article[ArticlesProperty.text]) {
        if (item.facebook) {
          this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(item.facebook);
        }
        if (item.x) {
          this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(item.x);
        }
        if (item.sofascore) {
          this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(item.sofascore);
        }
        if (item.tiktok) {
          this.embedSafe[index] = this.sanitizer.bypassSecurityTrustResourceUrl(item.tiktok);
        }
        if (item.youtube) {
          this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(item.youtube);
        }
        if (item.rumble) {
          this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(item.rumble);
        }
        if (item.instagram) {
          this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(item.instagram);
        }
        index++
      }
      this.cdr.detectChanges();
    }
  }

  public replaceNewlines(text: string): string {
    return text.replace(/\n/g, '<br>');
  }


  public isVideo(end: string): boolean {
    if (end !== null) {
      return end.endsWith('.mp4');
    }
    return false
  }

  showGallery(): void {
    this.styleGallery = (this.styleGallery === 'display: none') ? 'display: flex' : 'display: none';
    this.cdr.detectChanges();
    this.cdr.reattach();
  }

  next() {
    // @ts-ignore
    this.currentIndex = (this.currentIndex + 1) % this.article.image_list.length;
    this.cdr.detectChanges();

  }

  prev() {
    // @ts-ignore
    this.currentIndex = (this.currentIndex - 1 + this.article.image_list.length) % this.article.image_list.length;
    this.cdr.detectChanges();

  }

  handleVideoClick(event: Event) {
    event.stopPropagation(); // Prevent click event from propagating to parent elements
  }

  protected readonly ArticlesProperty = ArticlesProperty;
  protected readonly isPlatformBrowser = isPlatformBrowser;
}
