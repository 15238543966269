<div class="surveys">
  <div class="page-title">
    <h1>Urednici</h1>
  </div>
  <div class="top">
    <button (click)="openCreateEditorDialog()">Create User</button>
  </div>
  <!-- List of Survey Questions -->
  <div class="survey-visual">
    <div class="left">
      <div class="editor"
           *ngFor="let editor of editors"
           (click)="selectEditor(editor)"
           [ngClass]="{ 'selected': selectedEditor === editor }"
      >
        <div class="name">
          {{ editor.name }}
        </div>

        <div class="options">
          <button (click)="openCreateEditorDialog(editor)"
                  class="edit-button"
                  color="primary">
            Edit
          </button>
          <button (click)="deleteEditor(editor._id!)"
                  class="edit-button"
                  color="warn">
            Delete
          </button>
        </div>
      </div>
    </div>
    <div class="right" *ngIf="this.selectedEditor !== undefined" (scroll)="onScroll()">
      <div class="editor-articles" *ngFor="let article of articles">
        <img src="{{ article.image_list?.[0]?.url }}">
        <p>{{article.title}}</p>
      </div>
    </div>
  </div>
</div>
