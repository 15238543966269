import {Routes} from '@angular/router';
import {Route} from "./constants/route.constants";
import {ChairGuard} from "./guards/admin.guard";
import {AdministratorMainComponent} from "./components/administrator/administrator-main/administrator-main.component";
import {
  AdministratorDashboardComponent
} from "./components/administrator/administrator-dashboard/administrator-dashboard.component";
import {UploadComponent} from "./components/upload/upload.component";
import {
  AdministratorArticlesComponent
} from "./components/administrator/administrator-articles/administrator-articles.component";
import {
  AdministratorEditorsComponent
} from "./components/administrator/administrator-editors/administrator-editors.component";
import {
  AdministratorChairsComponent
} from "./components/administrator/administrator-chairs/administrator-chairs.component";
import {
  AdministratorCategoriesComponent
} from "./components/administrator/administrator-categories/administrator-categories.component";
import {
  AdministratorSurveyComponent
} from "./components/administrator/administrator-survey/administrator-survey.component";
import {AdministratorTagComponent} from "./components/administrator/administrator-tag/administrator-tag.component";
import {
  AdministratorLoginComponent
} from "./components/account/login/administrator-login/administrator-login.component";
import {LoginComponent} from "./components/account/login/login.component";
import {SignupComponent} from "./components/account/signup/signup.component";
import {MainComponent} from "./components/main/main.component";
import {ResolverResponse} from "./constants/resolver-response.constants";
import {PrivacyPolicyComponent} from "./common/privacy-policy/privacy-policy.component";
import {HomeComponent} from "./components/hayat.ba/home/home.component";
import {SurveyComponent} from "./components/survey/survey.component";
import {ArticleResolver} from "./resolvers/article.resolver";
import {CategoryComponent} from "./components/hayat.ba/category/category.component";
import {CategoryResolver} from "./resolvers/category.resolver";
import {ArticleContainerComponent} from "./components/hayat.ba/article/containers/article-container.component";
import {UploadContainerComponent} from "./components/upload/containers/upload-container.component";
import {HayatPlayComponent} from "./components/hayat-play/hayat-play.component";
import {OptionsComponent} from "./components/hayat-play/gledaj-hayat/options/options.component";
import {VideotekaComponent} from "./components/hayat-play/gledaj-hayat/videoteka/videoteka.component";
import {LiveTvComponent} from "./components/hayat-play/gledaj-hayat/live-tv/live-tv.component";
import {AboutHayatComponent} from "./components/hayat-play/about-hayat/about-hayat.component";
import {
  ServiceGledajHayatComponent
} from "./components/hayat-play/about-hayat/service-gledaj-hayat/service-gledaj-hayat.component";
import {
  ServiceGledajHayatGuideComponent
} from "./components/hayat-play/about-hayat/service-gledaj-hayat-guide/service-gledaj-hayat-guide.component";
import {ChannelsInfoComponent} from "./components/hayat-play/about-hayat/channels-info/channels-info.component";
import {SmartTvGuideComponent} from "./components/hayat-play/about-hayat/smart-tv-guide/smart-tv-guide.component";
import {TermsOfUseComponent} from "./components/hayat.ba/formal/terms-of-use/terms-of-use.component";
import {ContactComponent} from "./components/hayat.ba/formal/contact/contact.component";
import {ImpressumComponent} from "./components/hayat.ba/formal/impressum/impressum.component";
import {ShowsComponent} from "./components/hayat-play/shows/shows.component";
import {AllShowsComponent} from "./components/hayat-play/shows/all-shows/all-shows.component";
import {OneShowComponent} from "./components/hayat-play/shows/one-show/one-show.component";
import {ShowAboutComponent} from "./components/hayat-play/shows/one-show/show/show-about/show-about.component";
import {ShowChatComponent} from "./components/hayat-play/shows/one-show/show/show-chat/show-chat.component";
import {
  AdministratorShowsComponent
} from "./components/administrator/administrator-shows/administrator-shows.component";
import {AdministratorShowComponent} from "./components/administrator/administrator-show/administrator-show.component";
import {
  AdministratorGuestsComponent
} from "./components/administrator/administrator-guests/administrator-guests.component";
import {EpisodeComponent} from "./components/hayat-play/shows/one-show/episode/episode.component";
import {
  EpisodeAboutComponent
} from "./components/hayat-play/shows/one-show/episode/episode-about/episode-about.component";
import {
  EpisodeQuestionsComponent
} from "./components/hayat-play/shows/one-show/episode/episode-questions/episode-questions.component";
import {ShowComponent} from "./components/hayat-play/shows/one-show/show/show.component";
import {
  AdministratorEpisodeComponent
} from "./components/administrator/administrator-episode/administrator-episode.component";
import {GuestLoginComponent} from "./components/account/login/guest-login/guest-login.component";
import {SearchComponent} from "./components/search/search.component";
import {MarketingComponent} from "./components/hayat.ba/formal/marketing/marketing.component";
import {
  AdministratorHighlightsComponent
} from "./components/administrator/administrator-highlights/administrator-highlights.component";
import {
  AdministratorRequestsComponent
} from "./components/administrator/administrator-requests/administrator-requests.component";
import {
  AdministratorRequestReviewComponent
} from "./components/administrator/administrator-request-review/administrator-request-review.component";
import {QuizComponent} from "./components/quiz/quiz.component";
import {QuizAnswersComponent} from "./components/quiz/quiz-answers/quiz-answers.component";
import {QuizOptionsComponent} from "./components/quiz/quiz-options/quiz-options.component";
import {QuizControlComponent} from "./components/quiz/quiz-control/quiz-control.component";
import {UserRestartComponent} from "./components/account/login/user-restart/user-restart.component";
import {
  RestartUserPasswordComponent
} from "./components/account/restart/restart-user-password/restart-user-password.component";
import {HayatBaComponent} from "./components/hayat.ba/hayat.ba.component";
import {HayatComponent} from "./components/hayat/hayat.component";
import {ArchiveComponent} from "./components/hayat-play/archive/archive.component";
import {AccountComponent} from "./components/account/account.component";
import {ProfileComponent} from "./components/account/profile/profile.component";
import {AuthGuard} from "./guards/auth.guard";
import {CanDeactivateGuard} from "./guards/can-deactivate.guard";
import {ArtredComponent} from "./components/hayat.ba/artred/artred.component";
import {ArticleRedirectGuard} from "./guards/articleredirect.guard";
import {
  AdministratorAnalyticsComponent
} from "./components/administrator/administrator-analytics/administrator-analytics.component";

export const routes: Routes = [
  // Quiz Page
  {
    path: Route.ARTICLE + Route.SEPARATOR + Route.TITLE + Route.SEPARATOR + Route.ID,
    component: ArtredComponent,
    canActivate: [ArticleRedirectGuard], // Use the guard to handle redirection

    // redirectTo: Route.EMPTY + Route.SEPARATOR + Route.NEWS + Route.SEPARATOR + Route.ARTICLE + Route.SEPARATOR + Route.TITLE + Route.SEPARATOR + Route.ID,
    // pathMatch: 'full',
  },
  {
    path: Route.QUIZ,
    component: QuizComponent,
    children: [
      {
        path: '',  // Empty path to load by default
        redirectTo: Route.CONTROL, // Redirect to a default child route
        pathMatch: 'full',
      },
      {
        path: Route.CONTROL,
        component: QuizControlComponent,
      },
      {
        path: Route.ANSWER + Route.SEPARATOR + Route.TEAM,
        component: QuizAnswersComponent,
      },
      {
        path: Route.OPTIONS + Route.SEPARATOR + Route.TEAM,
        component: QuizOptionsComponent,
      },
    ],
  },
  {
    path: Route.LOGIN, // Empty path for default route
    pathMatch: 'full',
    children: [
      {
        path: '',  // Empty path to load by default
        redirectTo: Route.EMPTY + Route.SEPARATOR + Route.ACCOUNT + Route.SEPARATOR + Route.LOGIN, // Redirect to a default child route
        pathMatch: 'full',
      },
      {
        path: 'admin',  // Empty path to load by default
        redirectTo:  Route.EMPTY + Route.SEPARATOR +Route.ACCOUNT + Route.SEPARATOR + Route.ADMIN, // Redirect to a default child route
        pathMatch: 'full',
      },
    ],
  },
  // Webportal page
  {
    path: Route.EMPTY,
    component: MainComponent,
    children: [
      {
        path: Route.EMPTY,
        redirectTo: Route.NEWS,
        pathMatch: 'full',
      },
      // Hayat.ba
      {
        path: Route.NEWS,
        component: HayatBaComponent,
        children: [
          {
            path: Route.EMPTY,
            component: HomeComponent,
          },
          {
            path: Route.ARTICLE,
            children: [
              {
                path: Route.TITLE,
                children: [
                  {
                    path: Route.ID,
                    component: ArticleContainerComponent,
                    resolve: {
                      [ResolverResponse.ARTICLE]: ArticleResolver,
                    },
                  },
                ]
              },
            ],
          },
          {
            path: Route.CATEGORY,
            component: CategoryComponent,
            resolve: {
              [ResolverResponse.CATEGORY]: CategoryResolver,
            },
          },
        ]
      },
      // Hayat Media
      {
        path: Route.MEDIA,
        component: HayatComponent,
        children: [
          {
            path: Route.EMPTY,
            component: HayatComponent,
          },
        ]
      },
      // Hayat Play
      {
        path: Route.PLAY,
        component: HayatPlayComponent,
        children: [
          {
            path: Route.EMPTY,
            component: OptionsComponent,
          },
          {
            path: Route.ARCHIVE,
            component: ArchiveComponent,
          },
          {
            path: Route.LIVE,
            component: LiveTvComponent,
          },








          {
            path: Route.GLEDAJ,
            component: OptionsComponent,
          },
          {
            path: Route.GLEDAJ + Route.SEPARATOR + Route.ARCHIVE,
            component: VideotekaComponent,
            children: [
              {
                path: ':id',
                component: VideotekaComponent,
              }
            ]
          },
          {
            path: Route.GLEDAJ + Route.SEPARATOR + Route.LIVE,
            component: LiveTvComponent,
          },
          {
            path: Route.SHOWS,
            component: ShowsComponent,
            children: [
              {
                path: Route.EMPTY,
                component: AllShowsComponent,
              },
              {
                path: Route.ID,
                component: OneShowComponent,
                children: [
                  {
                    path: Route.EMPTY,
                    component: ShowComponent,
                    children: [
                      {
                        path: Route.EMPTY,
                        component: ShowAboutComponent,
                      },
                      {
                        path: Route.CHAT,
                        component: ShowChatComponent,
                      },
                    ]
                  },
                  {
                    path: Route.ORDER,
                    component: EpisodeComponent,
                    children: [
                      {
                        path: Route.EMPTY,
                        component: EpisodeAboutComponent,
                      },
                      {
                        path: Route.QA,
                        component: EpisodeQuestionsComponent,
                      },
                    ]
                  },
                ]
              },
            ]
          },
          {
            path: Route.ABOUTUS,
            component: AboutHayatComponent
          },
          {
            path: Route.ABOUTUS + Route.SEPARATOR + 'servis-gledaj-hayat',
            component: ServiceGledajHayatComponent
          },
          {
            path: Route.ABOUTUS + Route.SEPARATOR + 'servis-gledaj-hayat-uputstvo',
            component: ServiceGledajHayatGuideComponent
          },
          {
            path: Route.ABOUTUS + Route.SEPARATOR + 'info-hayat-tv',
            component: ChannelsInfoComponent
          },
          {
            path: Route.ABOUTUS + Route.SEPARATOR + 'info-smart-tv',
            component: SmartTvGuideComponent
          }

        ]
      },
      // User account
      {
        path: Route.ACCOUNT,
        component: AccountComponent,
        children: [
          {
            path: Route.EMPTY,
            component: ProfileComponent,
            canActivate: [AuthGuard], // 🔥 Protect this route
          },
          {
            path: Route.LOGIN,
            component: LoginComponent,
          },
          {
            path: Route.SIGNUP,
            component: SignupComponent,
          },
          {
            path: Route.ADMIN,
            component: AdministratorLoginComponent,
          },



          {
            path: Route.GUEST,
            component: GuestLoginComponent,
          },
          {
            path: Route.USERRESTART,
            component: UserRestartComponent,
          },
          {
            path: 'reset-password/:token',
            component: RestartUserPasswordComponent,
          },
        ]
      },
      // Search
      {
        path: Route.SEARCH,
        children: [
          {
            path: Route.EMPTY,
            component: SearchComponent,
          },
          {
            path: Route.TAG,
            component: SearchComponent,
          },
        ],
      },

      {
        path: Route.USLOVI,
        component: PrivacyPolicyComponent,
      },
      {
        path: Route.CONTACT,
        component: ContactComponent,
      },
      {
        path: Route.TERMSOFUSE,
        component: TermsOfUseComponent
      },
      {
        path: Route.IMPRESSUM,
        component: ImpressumComponent
      },
      {
        path: Route.MARKETING,
        component: MarketingComponent
      },
      {
        path: Route.SURVEY + Route.SEPARATOR + Route.ID,
        component: SurveyComponent,
      },
    ],
  },
  // Admin side
  {
    path: Route.CHAIR,
    canActivate: [ChairGuard],
    component: AdministratorMainComponent,
    children: [
      {
        path: Route.EMPTY,
        component: AdministratorDashboardComponent,
      },
      {
        path: Route.UPLOAD,
        component: UploadComponent,
      },
      {
        path: Route.EDIT + Route.SEPARATOR + Route.ID,
        component: UploadContainerComponent,
        resolve: {
          [ResolverResponse.ARTICLE]: ArticleResolver,
        },
      },
      {
        path: Route.ARTICLES,
        component: AdministratorArticlesComponent,
      },
      {
        path: 'analytics',
        component: AdministratorAnalyticsComponent,
      },
      {
        path: Route.REQUESTS,
        component: AdministratorRequestsComponent,
      },
      {
        path: Route.REQUESTS + Route.SEPARATOR + Route.ID,
        component: AdministratorRequestReviewComponent,
      },
      {
        path: Route.HIGHLIGHTS,
        component: AdministratorHighlightsComponent,
        canDeactivate: [CanDeactivateGuard]  // Add the guard here

      },
      {
        path: Route.EDITORS,
        component: AdministratorEditorsComponent,
      },
      {
        path: Route.ADMINISTRATORS,
        component: AdministratorChairsComponent,
      },
      {
        path: Route.SHOWS,
        component: AdministratorShowsComponent,
      },
      {
        path: Route.GUESTS,
        component: AdministratorGuestsComponent,
      },
      {
        path: Route.SHOWS + Route.SEPARATOR + Route.ID,
        component: AdministratorShowComponent,
      },
      {
        path: Route.SHOWS + Route.SEPARATOR + Route.ID + Route.SEPARATOR + Route.ORDER,
        component: AdministratorEpisodeComponent,
      },
      {
        path: Route.CATEGORIES,
        component: AdministratorCategoriesComponent,
      },
      {
        path: Route.SURVEY,
        component: AdministratorSurveyComponent,
      },
      {
        path: Route.TAGS,
        component: AdministratorTagComponent,
      },

    ],
  },
  // RSS
  {
    path: 'rss',
    redirectTo: Route.EMPTY,
  },
  // Login
  {
    path: '**',
    redirectTo: Route.EMPTY + Route.SEPARATOR + Route.NEWS,
  },
];

// @NgModule({
//   declarations: [],
//   imports: [
//     RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking', scrollPositionRestoration: "enabled" }),
//     CommonModule
//   ],
//   exports: [RouterModule],
// })
// export class AppRoutingModule {
// }
