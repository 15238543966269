<div class="article">
  <div class="left">
    @if (article !== undefined) {
      <div class="tags">
        @for (tag of article.tags; track tag) {
          @if (tag) {
            <div class="tag" [routerLink]="'/search/' + tag.tag">
              <app-themed-tag [tag]="'#' + tag.tag" [fontSize]="16" [fontFamily]="'Monrad Semibold'"></app-themed-tag>
            </div>
          }
        }
      </div>

      @if (article.image_list) {
        <div class="carousel">
          @if ((article.image_list?.length ?? 0) > 1) {
            <button class="prev-button" (click)="prev()"><i class="fa fa-angle-left"></i></button>
          }
          <div class="carousel-item" [ngClass]="carouselClass" *ngFor="let item of article.image_list; let i = index"
               [hidden]="i !== currentIndex">
            @if (!isVideo(item.url)) {
              <img ngSrc="{{item.url}}" alt="" fill="" style="position: relative" priority="">
            } @else {
              <video
                [src]="item.url"
                controls
                preload="auto"
                loop
                autoplay
                (click)="handleVideoClick($event)"
              >
              </video>
            }
          </div>
          @if ((article.image_list?.length ?? 0) > 1) {
            <button class="next-button" (click)="next()"><i class="fa fa-angle-right"></i></button>
          }
        </div>
      }

      <div class="article-information">
        <p style="width: max-content;">{{ article[articlesProperty.display_name] }}</p>
        <div class="date">
          <p>{{ date }} {{ time }}</p>
        </div>
      </div>

      <div class="heading">
        <h1>{{ article[articlesProperty.title] }}</h1>
      </div>
      <div class="subheading">
        <h2>{{ article[articlesProperty.subtitle] }}</h2>
      </div>
    } @else {
      <div class="loader-container article">
        <span class="loader"></span>
      </div>
    }


    <div class="advertisement">
      <div #advertisementSidebarSmall class="advertisement-sidebar">
      </div>
      @if (windowWidth > 768) {
        <div id='div-gpt-ad-1732793651947-0' style='min-width: 160px; min-height: 50px;'>
        </div>
      } @else {
      }
    </div>

    @if (article !== undefined) {
      <div class="article-text">
        @for (textPart of article.text; track textPart; let i = $index) {
          @if (textPart.regular) {
            <div class="text">
              <p [innerHTML]="replaceNewlines(textPart.regular)"></p>
            </div>
          } @else if (textPart.bold) {
            <div class="text-bold">
              <p [innerHTML]="replaceNewlines(textPart.bold)"></p>
            </div>
          } @else if (textPart.italic) {
            <div class="text" style="font-style: italic">
              <p [innerHTML]="replaceNewlines(textPart.italic)"></p>
            </div>
          } @else if (textPart.link) {
            <div class="link">
              <a class="link-style" href="{{textPart.link}}" target="_blank">
                <i class="fa-solid fa-link"></i>
                <p>{{ textPart.linkText }}</p>
              </a>
            </div>
          } @else if (textPart?.youtube?.changingThisBreaksApplicationSecurity) {
            <div
              [innerHTML]="sanitizer.bypassSecurityTrustHtml(textPart.youtube.changingThisBreaksApplicationSecurity)"
              class="article-youtube">
            </div>
          } @else if (textPart.youtube) {
            <div [innerHTML]="embedSafe[i]" class="article-youtube"></div>
            <!--              <div class="article-youtube">-->
              <!--                <iframe [src]="embedSafe[i]" frameborder="0" webkitallowfullscreen mozallowfullscreen-->
              <!--                        allowfullscreen></iframe>-->
              <!--              </div>-->
          } @else if (textPart.rumble) {
            <div class="rumble-container" [innerHtml]="embedSafe[i]"></div>
          } @else if (textPart.image) {
            <div class="article-image">
              <img ngSrc="{{textPart.image}}" alt="{{article[ArticlesProperty.title]}}" fill=""
                   style="
                     box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);
                     position: relative; object-fit: cover; aspect-ratio: 16/9; border-radius: 10px">
            </div>
          } @else if (textPart.instagram) {
            <div [innerHTML]="embedSafe[i]" class="article-instagram"></div>
            <!--              <div class="article-instagram">-->
              <!--                <iframe loading="lazy" width="320" height="580" frameborder="0"-->
              <!--                        data-instgrm-payload-id="instagram-media-payload-0"-->
              <!--                        scrolling="no" allowtransparency="true" allowfullscreen="true"-->
              <!--                        style="padding: 2px; margin: 10px auto !important; display:flex; box-shadow: 0 0 9px 0;-->
              <!--                border: 1px solid #d8d8d8; border-radius: 10px;"-->
              <!--                        [src]="embedSafe[i]">-->
              <!--                </iframe>-->
              <!--              </div>-->
          } @else if (textPart.facebook) {
            <div [innerHTML]="embedSafe[i]" class="article-facebook"></div>
          } @else if (textPart.sofascore) {
            <div [innerHTML]="embedSafe[i]" class="article-sofascore"></div>
          } @else if (textPart.tiktok) {
            <div class="article-tiktok">
              <iframe loading="lazy" scrolling="no"
                      sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation allow-same-origin"
                      class="tiktok-iframe ng-star-inserted"
                      style="height: 739px; width: 324px; margin: 10px auto; border-radius: 9px; border: solid 1px #d7d7d7; display: block; visibility: unset; max-height: 739px;"
                      [src]="embedSafe[i]">
              </iframe>
            </div>
          } @else if (textPart.x) {
            <div [innerHTML]="embedSafe[i]" class="article-x">
            </div>
          }
          @if (shouldPlaceAdvertisement(i) && this.windowWidth < 768) {
            <div class="lwdgt" data-wid="139"></div>
          }

          <!--@if (textPart.survey) {
            <div class="article-survey">
              <app-survey [survey]="textPart.survey"></app-survey>
            </div>
          }-->
        }
      </div>
    }
    @if (windowWidth < 768) {
      <div id="hayat_ba_300x300_in_article_1_mobile" class="display-flex in-article-height">
      </div>
    }
    <div class="lwdgt" data-wid="6"></div>
    @if (windowWidth < 768) {
      <div id="midasWidget__10687"></div>
    }
    <div id="ng_UzLidnmgx2" class="ng_ws"></div>

    <div data-type="_mgwidget" data-widget-id="1744083"></div>

    <script>
      (function (w, q) {
        w[q] = w[q] || [];
        w[q].push(["_mgc.load"])
      })(window, "_mgq");
    </script>
  </div>

  <div class="right">
<!--    <div #advertisementSidebar class="advertisement-sidebar">-->
<!--    </div>-->
    <app-themed-tag [tag]="'Više iz rubrike ' + article?.category?.name " [fontSize]="20"
                    [fontFamily]="'Monrad Semibold'"></app-themed-tag>

    @if (this.categoryArticles) {
      <div class="category-articles">
        @for(article of this.categoryArticles; track article){
          <app-article-card [source]="'web-article'"  [article]="article"></app-article-card>

        }
      </div>
    }
  </div>
</div>
