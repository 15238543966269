@if (dataLoaded) {
  <div class="home">
    <app-home-tags></app-home-tags>
    <app-home-category [articles]="highlight" [category]="categoryHighlight"></app-home-category>
<!--    <app-home-category [articles]="hot" [category]="categoryHot"></app-home-category>-->
    @for (article of articles; let i = $index; track article) {
      <app-home-category [articles]="article" [category]="categories[i]"></app-home-category>
    }
  </div>
} @else {
  <div class="home">
    <div class="category" [ngStyle]="{'width.px': categoryWidth - 20}">
      <app-themed-tag [tag]="''" [fontSize]="20" [fontFamily]="'Monrad Bold'"></app-themed-tag>
      <div class="custom-masonry">
        @for (column of getNumberArray(numberOfColumns); track column; let id = $index) {
          <div class="custom-masonry-column">
            @for (index of getNumberArray(3); track index) {
              <app-article-card [article]="null"></app-article-card>
            }
          </div>
        }
      </div>
    </div>

    <div class="category" [ngStyle]="{'width.px': categoryWidth - 20}">
      <app-themed-tag [tag]="''" [fontSize]="20" [fontFamily]="'Monrad Bold'"></app-themed-tag>
      <div class="custom-masonry">
        @for (column of getNumberArray(numberOfColumns); track column; let id = $index) {
          <div class="custom-masonry-column">
            @for (index of getNumberArray(3); track index) {
              <app-article-card [article]="null"></app-article-card>
            }
          </div>
        }
      </div>
    </div>
  </div>
}
