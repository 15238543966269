import {ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {DomSanitizer, Meta, Title} from "@angular/platform-browser";
import {Show} from "../../../models/show.model";
import {ShowService} from "../../../services/show.services";
import {MatCheckbox} from "@angular/material/checkbox";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ShowProperty} from "../../../models/show-property.enum";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CrateEpisodeDialogComponent} from "./crate-episode-dialog/crate-episode-dialog.component";
import {EpisodeServices} from "../../../services/episode.services";
import {Episode} from "../../../models/episode.model";
import {EpisodeProperty} from "../../../models/episode-property.enum";
import {Chair} from "../../../models/chair.model";
import {UserService} from "../../../services/user.service";

@Component({
    selector: 'app-administrator-show',
    imports: [
        MatCheckbox,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatLabel,
        RouterLink
    ],
    templateUrl: './administrator-show.component.html',
    styleUrl: './administrator-show.component.scss'
})
export class AdministratorShowComponent implements OnInit{
  show!: Show;
  showForm!: FormGroup;
  isEditing = false;

  admin: Chair | undefined;


  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private showService: ShowService,
    private episodeServices: EpisodeServices,
    private userService: UserService,
    private snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private title: Title,
    private meta: Meta,
  ) {
    this.activatedRoute.params.subscribe(params => {
      const id = params['id']; // Assuming 'id' is the parameter name in your URL
      // Load initial event data (optional)
      this.showService.getShow(id).subscribe(
        result => {
          // Handle successful response
          this.show = result;
          this.showForm = this.formBuilder.group({
            _id: [this.show?.[ShowProperty.id] || ''],
            name: [this.show?.[ShowProperty.name] || '', Validators.required],
            about: [this.show?.[ShowProperty.about] || ''],
            logo: [this.show?.[ShowProperty.logo] || ''],
            chatEnabled: [this.show?.[ShowProperty.chatEnabled] || false],
          });
        },
        error => {
          // Handle error
          console.error('Error fetching event data:', error);
        }
      );
    });

  }

  ngOnInit(): void {
    /*this.userService.getUser()
        throw new Error('Method not implemented.');*/
  }

  onSubmit() {
    if (this.show) {
      //this.showService.update(this.showForm.value)
    }
    if (this.showForm?.valid) {
      const formData = this.showForm.value;
      // Send the form data to the backend
      this.showService.create(formData).subscribe(
        (result: any) => {
        },
        (error) => {
          console.error('Error creating survey:', error);
          // Handle the error as needed
        }
      );
    }
  }

  openCreateEpisodeDialog(episode?: Episode) {
    const dialogRef = this.dialog.open(CrateEpisodeDialogComponent, {
      width: '500px',
      data: {episode, show: this.show},
    });
    dialogRef.componentInstance.episodeCreated.subscribe((createdShow: Episode) => {
      if (createdShow) {
        this.show[ShowProperty.episodes].push(createdShow);

        // Show snackbar with successful message
        this.snackBar.open("Episode created successfully", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      }
    });

    dialogRef.componentInstance.episodeUpdated.subscribe((updatedEpisode: any) => {
      if (updatedEpisode) {
        // Handle the updated episode as needed
        // For example, update the episode in the show's episodes array
        const index = this.show[ShowProperty.episodes].findIndex(ep => ep[EpisodeProperty.order] === updatedEpisode.oldOrder);
        if (index !== -1) {
          this.show[ShowProperty.episodes][index] = updatedEpisode;
          this.cdr.detectChanges()
        }

        // Show snackbar with successful message
        this.snackBar.open("Episode updated successfully", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  deleteArticle(episode: Episode): void{
    this.episodeServices.delete(this.show[ShowProperty.id], episode[EpisodeProperty.order]).subscribe(
      (result:any) => {
        if (result && result.message === 'Episode deleted successfully') {
          this.show.episodes = this.show.episodes.filter(ep => ep[EpisodeProperty.order] !== episode[EpisodeProperty.order]);
        }
        else {
          console.error('Unexpected response from server:', result);
          // Handle unexpected server response
        }
        },
      (error) => {
        console.error('Error creating survey:', error);
        // Handle the error as needed
      }
    );
  }

  toggleEdit(): void {
    if(this.isEditing){
      if (this.showForm.dirty) {
        this.showService.update(this.show[ShowProperty.id], this.showForm.value).subscribe(result =>{
          this.show = result.show
          this.cdr.detectChanges()
        })
      }
    }
    this.isEditing = !this.isEditing;
  }

  selectEpisode(episode: Episode) {
    this.showService.setEpisode(episode)
    this.cdr.detectChanges()
  }
}
