<div class="search" [ngStyle]="{'width.px': categoryWidth}">
  <div class="search-container">
    <input
      type="text"
      [(ngModel)]="query"
      (input)="onSearch()"
      (focus)="showResults = true"
      placeholder="Search tags..."
      class="search-input"
    />

    <div class="search-results" *ngIf="showResults && results.length > 0">
      <div class="tags" *ngFor="let tag of results" (click)="onSelect(tag)">
        <p>#{{ tag.tag }}</p>
      </div>
    </div>
  </div>
  @if (tag) {
    <div class="tag">
      <p>#{{ tag }}</p>
    </div>
  }
  @if (category) {
    <app-themed-tag [tag]="category[categoriesProperty.name]" [fontSize]="20"
                    [fontFamily]="'Monrad Bold'"></app-themed-tag>
  }

  @if (articlesByColumn.length !== 0) {
    <div class="custom-masonry">
      @for (column of getNumberArray(numberOfColumns); track column; let id = $index) {
        <div class="custom-masonry-column">
          @for (article of articlesByColumn[id]; track article) {
            @if (article.type === 'advertisement') {
              <app-themed-advertisement [size]="article.size"></app-themed-advertisement>
            } @else {
              <app-article-card [source]="'web-search-' + tag" [article]="article"></app-article-card>
            }
          }
        </div>
      }
    </div>
  }
</div>
