import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Articles} from "../../../models/articles.model";
import {NgClass, NgStyle} from "@angular/common";

@Component({
  selector: 'app-hayat-info',
  imports: [
    NgStyle,
    NgClass
  ],
  templateUrl: './hayat-info.component.html',
  standalone: true,
  styleUrl: './hayat-info.component.scss'
})
export class HayatInfoComponent {

  @Input() data: any = {};

}
