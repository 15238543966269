import { Component } from '@angular/core';

@Component({
    selector: 'app-impressum',
    imports: [],
    templateUrl: './impressum.component.html',
    styleUrl: './impressum.component.scss'
})
export class ImpressumComponent {

}
