import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {CategoriesService} from "../../services/categories.services";
import {RouterLink} from "@angular/router";
import {Categories} from "../../models/categories.model";
import {CategoriesProperty} from "../../models/categories-property.enum";
import {isPlatformBrowser, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-footer',
  imports: [
    RouterLink,
    NgOptimizedImage
  ],
  templateUrl: './footer.component.html',
  standalone: true,
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  public categories: Categories[] = [];
  public categoriesProperty = CategoriesProperty;


  constructor(
    @Inject(PLATFORM_ID)private platformID: Object,
    private categoriesService: CategoriesService
  ) {
  }

  ngOnInit(): void{
    if (isPlatformBrowser(this.platformID)) {
      this.categories = this.categoriesService.gCategories;
    }
  }

}
