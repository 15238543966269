import { Component } from '@angular/core';

@Component({
    selector: 'app-show-chat',
    imports: [],
    templateUrl: './show-chat.component.html',
    styleUrl: './show-chat.component.scss'
})
export class ShowChatComponent {

}
