import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";

@Component({
    selector: 'app-administrator-dashboard',
    templateUrl: './administrator-dashboard.component.html',
    imports: [
        RouterOutlet
    ],
    styleUrls: ['./administrator-dashboard.component.scss']
})
export class AdministratorDashboardComponent {

}
