import {Component, HostListener, OnInit} from '@angular/core';
import {Tag} from "../../../../models/tag.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {TagsService} from "../../../../services/tags.services";
import {TagProperty} from "../../../../models/tag-property.enum";
import {NgForOf, NgStyle, SlicePipe} from "@angular/common";
import {RouterLink} from "@angular/router";
import {CategoriesProperty} from "../../../../models/categories-property.enum";
import {ThemedTagComponent} from "../../themed-tag/themed-tag.component";

@Component({
  selector: 'app-home-tags',
  imports: [
    NgForOf,
    SlicePipe,
    RouterLink,
    NgStyle,
    ThemedTagComponent
  ],
  templateUrl: './home-tags.component.html',
  standalone: true,
  styleUrl: './home-tags.component.scss'
})
export class HomeTagsComponent implements OnInit {

  tags!: Tag[];
  categoryWidth: number = 400; // Default width
  numberOfColumns: number = 3;

  constructor(
    private tagsService: TagsService,
  ) {
  }


  @HostListener('window:resize', ['$event'])
  onResize(event:Event)
  {
    const screenWidth = (event.target as Window).innerWidth;
    this.calculateCategoryWidth(screenWidth);
  }

  calculateCategoryWidth(screenWidth: number) {

    if (screenWidth > 400) {
      const columnWidth = 400;
      const gap = 10;

      if (innerWidth < 400) {
        this.numberOfColumns = 1;
      } else if (innerWidth >= 1800) {
        this.numberOfColumns = Math.floor(innerWidth / 400);
      } else {
        this.numberOfColumns = Math.min(Math.floor(innerWidth / 400), 3);
      }


      // Calculate total width
      this.categoryWidth = (this.numberOfColumns  * columnWidth) + ((this.numberOfColumns  - 1) * gap) + 20;
    } else {
      this.categoryWidth = innerWidth
    }
  }

  ngOnInit(): void {
    this.calculateCategoryWidth(innerWidth)

    this.tagsService.getPopularTags().subscribe(
      (tags: Tag[]) => {
        this.tags = tags
      },
      (error) => {
        /*this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });*/
        console.error('Error getting courses:', error);
      }
    );
  }

  protected readonly categoriesProperty = CategoriesProperty;
}
