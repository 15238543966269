import {Component, EventEmitter, HostListener, Inject, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem} from "@angular/material/menu";
import {Router, RouterLink} from "@angular/router";
import {isPlatformBrowser, NgClass, NgIf} from "@angular/common";
import {
  MatDrawer,
  MatDrawerContainer,
  MatDrawerContent,
  MatSidenav,
  MatSidenavContainer
} from "@angular/material/sidenav";
import {MatToolbar} from "@angular/material/toolbar";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-header',
  imports: [
    RouterLink,
    MatButton,
    MatIcon,
    MatMenu,
    MatMenuItem,
    NgClass,
    NgIf,
    MatSidenavContainer,
    MatSidenav,
    MatDrawerContent,
    MatToolbar,
    MatIconButton,
    MatDrawerContainer,
    MatFormField,
    MatSelect,
    MatOption,
    MatDrawer,
    MatLabel
  ],
  templateUrl: './header.component.html',
  standalone: true,
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() openMenu: EventEmitter<void> = new EventEmitter<void>();

  currentRoute: string = '';
  showToolbar: boolean = false;
  menuOpen: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router
  ) {
    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
      this.checkScreenSize();
    });

    this.checkScreenSize(); // Initial check
  }

  @HostListener('window:resize', [])
  onResize() {
    this.checkScreenSize();
  }

  checkScreenSize() {
    if (isPlatformBrowser(this.platformId)) {
      this.showToolbar = window.innerWidth < 550;
    }
  }


  // toggleMenu() {
  //   this.menuOpen = !this.menuOpen;
  // }

  toggleMenu() {
    this.openMenu.emit(); // 🔥 Send signal to parent (main component)
  }

  ngOnInit(): void {
  }
}
