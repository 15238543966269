import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input, OnChanges,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {Articles} from "../../../models/articles.model";
import {Categories} from "../../../models/categories.model";
import {ArticlesProperty} from "../../../models/articles-property.enum";
import {isPlatformBrowser, NgIf, NgOptimizedImage, NgStyle} from "@angular/common";
import {RouterLink} from "@angular/router";
import {CategoriesProperty} from "../../../models/categories-property.enum";
import {ArticleCardComponent} from "../article-card/article-card.component";
import {ThemedTagComponent} from "../themed-tag/themed-tag.component";
import {ThemedAdvertisementComponent} from "../themed-advertisement/themed-advertisement.component";
import {ArticlesService} from "../../../services/articles.service";

@Component({
  selector: 'app-home-category',
  templateUrl: './home-category.component.html',
  imports: [
    NgIf,
    RouterLink,
    NgOptimizedImage,
    ArticleCardComponent,
    ThemedTagComponent,
    NgStyle,
    ThemedAdvertisementComponent,
  ],
  standalone: true,
  styleUrls: ['./home-category.component.scss']
})

export class HomeCategoryComponent implements OnInit, OnChanges {

  @Input() category!: Categories;

  @Input()
  set articles(value: Articles[]) {
    this.sortArticles(value);
    this.isLoading = false; // Mark as loaded
  }

  public articleControl: Articles[] = []

  public articlesProperty = ArticlesProperty;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['articles']) {
      // Trigger any logic you need when `highlightedArticles` changes
      // You can manually trigger any logic like updating internal state if needed.
    }
  }

  categoryWidth: number = 400; // Default width
  columnsHeightControl: number[] = [];
  public articlesByColumn: any[][] = [];
  numberOfColumns: number = 3;
  videoIndex = 0
  photoIndex = 1
  textIndex = 2
  protected isLoading: boolean = true;


  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private articlesService: ArticlesService,
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const screenWidth = (event.target as Window).innerWidth;
    this.calculateCategoryWidth(screenWidth);
  }


  calculateCategoryWidth(screenWidth: number) {
    if (screenWidth > 400) {
      const columnWidth = 400;
      const gap = 10;

      if (innerWidth < 400) {
        this.numberOfColumns = 1;
      } else if (innerWidth >= 1800) {
        this.numberOfColumns = Math.floor(innerWidth / 400);
      } else {
        this.numberOfColumns = Math.min(Math.floor(innerWidth / 400), 3);
      }

      // Calculate total width
      this.categoryWidth = (this.numberOfColumns * columnWidth) + ((this.numberOfColumns - 1) * gap) + 20;
    } else {
      this.categoryWidth = innerWidth
    }
  }


  ngOnInit(): void {
  }


  async sortArticles(articles: Articles[]) {
    // Initialize column height tracking
    const innerWidth = window.innerWidth;
    this.calculateCategoryWidth(innerWidth)
    this.articleControl = articles

    if (innerWidth < 400) {
      this.numberOfColumns = 1;
    } else if (innerWidth >= 1800) {
      this.numberOfColumns = Math.floor(innerWidth / 400);
    } else {
      this.numberOfColumns = Math.min(Math.floor(innerWidth / 400), 3);
    }

    for (let i = 0; i < this.numberOfColumns; i++) {
      this.columnsHeightControl[i] = 0;
    }

    for (let i = 0; i < this.numberOfColumns; i++) {
      this.columnsHeightControl[i] = 0;
    }

    let displayedArticles = 0;
    let maxArticlesToShow = Math.min(articles.length, 15); // Show 8-12 articles
    let minArticlesToShow = 8;

    for (let i = 0; i < articles.length; i++) {
      let columnControl = 0;
      let minHeight = this.columnsHeightControl[0];

      // Find the column with the smallest height
      for (let j = 1; j < this.numberOfColumns; j++) {
        if (this.columnsHeightControl[j] < minHeight) {
          minHeight = this.columnsHeightControl[j];
          columnControl = j;
        }
      }

      // Determine article height
      let articleHeight = 0;
      if (articles[i][ArticlesProperty.videoPost]) {
        articleHeight = window.innerWidth > 400 ? 400 : 400;
      } else if (articles[i][ArticlesProperty.textPost]) {
        articleHeight = 185;
      } else if (articles[i][ArticlesProperty.photoPost]) {
        articleHeight = 130;
      }

      // Add article to the shortest column
      if (!this.articlesByColumn[columnControl]) {
        this.articlesByColumn[columnControl] = [];
      }
      this.articlesByColumn[columnControl].push(articles[i]);

      // Update column height
      this.columnsHeightControl[columnControl] += articleHeight;
      displayedArticles++;

      // If we have displayed enough articles and height is balanced
      // if (displayedArticles == minArticlesToShow || displayedArticles >= minArticlesToShow || displayedArticles === this.articleControl.length) {
      //   this.addAdvertisementsIfNeeded(displayedArticles, maxArticlesToShow);
      // }

      // Stop if we reach at least minArticlesToShow & height balance is good
      if (displayedArticles === minArticlesToShow || displayedArticles === this.articleControl.length) {
        let maxGapColumn = 0;
        let maxGap = 0;

        // Find the column with the **most extra space**
        for (let j = 0; j < this.numberOfColumns; j++) {
          let gap = Math.max(...this.columnsHeightControl) - this.columnsHeightControl[j];
          if (gap > maxGap) {
            maxGap = gap;
            maxGapColumn = j;
          }
        }

        let adSize: "400x400" | "400x200" | null = null;
        if (this.numberOfColumns > 1) {
          // Check which ad size fits best
          if (maxGap >= 325) {
            adSize = "400x400"; // Use 1:1
          } else {
            adSize = "400x200"; // Use 1:2
          }
        } else {
          adSize = Math.random() > 0.5 ? "400x400" : "400x200";
        }

        // Insert ad if it fits
        if (adSize) {
          this.articlesByColumn[maxGapColumn].push({type: "advertisement", size: adSize});
          this.columnsHeightControl[maxGapColumn] += adSize === "400x400" ? 400 : 200;
        }
      }

      if (displayedArticles >= minArticlesToShow || displayedArticles === this.articleControl.length) {
        let maxHeight = Math.max(...this.columnsHeightControl);
        let minHeight = Math.min(...this.columnsHeightControl);
        if (displayedArticles >= maxArticlesToShow && maxHeight - minHeight) {
          let maxGapColumn = 0;
          let maxGap = 0;

          // Find the column with the **most extra space**
          for (let j = 0; j < this.numberOfColumns; j++) {
            let gap = Math.max(...this.columnsHeightControl) - this.columnsHeightControl[j];
            if (gap > maxGap) {
              maxGap = gap;
              maxGapColumn = j;
            }
          }

          let adSize: "400x400" | "400x200" | null = null;
          if (this.numberOfColumns > 1) {
            // Check which ad size fits best
            if (maxGap >= 325) {
              adSize = "400x400"; // Use 1:1
            } else if (maxGap >= 125) {
              adSize = "400x200"; // Use 1:2
            }
          } else {
            adSize = Math.random() > 0.5 ? "400x400" : "400x200";
          }

          // Insert ad if it fits
          if (adSize) {
            this.articlesByColumn[maxGapColumn].push({type: "advertisement", size: adSize});
            this.columnsHeightControl[maxGapColumn] += adSize === "400x400" ? 400 : 200;
          }
        }

        if (maxHeight - minHeight < 130 || displayedArticles > maxArticlesToShow) {
          break; // Stop adding more articles if balance is good
        }
      }
    }
  }

  private addAdvertisementsIfNeeded(displayedArticles: number, maxArticlesToShow: number) {
    let maxHeight = Math.max(...this.columnsHeightControl);
    let minHeight = Math.min(...this.columnsHeightControl);

    // Check if the columns need more ads
    if (displayedArticles >= maxArticlesToShow && maxHeight - minHeight > 130) {
      let adSize: "400x400" | "400x200" | null = null;

      // Check each column and insert an advertisement if the column has space
      for (let i = 0; i < this.numberOfColumns; i++) {
        let gap = maxHeight - this.columnsHeightControl[i];

        if (gap >= 325) {
          adSize = "400x400"; // 1:1
        } else if (gap >= 125) {
          adSize = "400x200"; // 1:2
        }

        // Insert advertisement if adSize is determined
        if (adSize && (!this.articlesByColumn[i] || this.articlesByColumn[i].filter(ad => ad.type === "advertisement").length === 0)) {
          // Add advertisement if this column doesn't already have one
          this.articlesByColumn[i].push({ type: "advertisement", size: adSize });
          this.columnsHeightControl[i] += adSize === "400x400" ? 400 : 200; // Adjust column height based on ad size
          adSize = null; // Reset ad size for the next column
        }
      }
    }
  }

  getNumberArray(length: number): number[] {
    return Array.from({length}, (_, i) => i);
  }

  protected readonly categoriesProperty = CategoriesProperty;
}
